
import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Button, Card, CardContent, CardMedia, Divider, Grid, Link, List, ListItem, ListItemText, Paper, Stack, TextField, ThemeProvider, Typography } from "@mui/material";
import Service from './Service';
import RootState from '../types/RootState';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GppGoodIcon from '@mui/icons-material/GppGood';
import PeopleIcon from '@mui/icons-material/People';

import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ServiceType, ServiceListType } from "../types/DatabaseTypes";


const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    h2: {
      fontSize: 24,
      fontWeight: 800
    },
    h3: {
      fontSize: 16,
      fontWeight: 500
    },
    body1: {
      fontSize: 16,
      fontWeight: 500
    },
    body2: {
      fontSize: 16,
      fontWeight: 500
    },
  }
});


const css = `

MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk

.MuiLink-root {
  color: white
}
MuiFormControl-root {
  color: white  
}
.MuiTextField-root {
  color: white
}


.MuiInputBase-root  {
  color: red
}
.MuiInputBase-colorPrimary  {
  color: white
}
.MuiInputBase-formControl  {
  color: white

}
.MuiOutlinedInput-root {
  color: white
}


.image {
  height: 240px;
  width: 1075px;
  }
  .image .aaron-burden {
  height: 240px;
  left: 0;
  object-fit: cover;
  position: fixed;
  top: 0;
  width: 1075px;
  }

  .hajotulajdonosBox {
    height: 240px;
    background-nemkell: linear-gradient(90deg, #313EB0 3.17%, #5382E9 35.43%, #487AC5 68.66%, #131925 95.63%);
    background: linear-gradient(90deg, #313EB0, #010F0D );
    border-radius: 8px;
    color: white;
    }
    .hajotulajdonosBoxMobile {
      height: 450;
      background: linear-gradient(90deg, #313EB0, #010F0D );
      color: white;
    }
  
    .hirlevelBox {
      background: linear-gradient(90deg, rgb(36.84, 42.04, 166.81) 0%, rgb(83, 130, 233) 49.48%, rgb(255, 198.9, 0) 100%);
      height: 267px;

      color: white;
      }
    .hirlevelBoxMobile {
      background: linear-gradient(90deg, rgb(36.84, 42.04, 166.81) 0%, rgb(83, 130, 233) 49.48%, rgb(255, 198.9, 0) 100%);
      color: white;
    }

    .alsoBox {
      background: linear-gradient(90deg, #5382E9 3.17%, #5382E9 35.43%, #5382E9 68.66%, #5382E9 95.63%);
      height: 72px;
      color: white;
    }
    .alsoBoxMobile {
      background: linear-gradient(90deg, #5382E9 3.17%, #5382E9 35.43%, #5382E9 68.66%, #5382E9 95.63%);
      height: 72px;
      color: white;
    }
  .alulrolamasodikBox {
        background: linear-gradient(90deg, #011031 3.17%, #011031 35.43%, #011031 68.66%, #011031 95.63%);
        height: 225px;
        color: white;
      }
      .alulrolamasodikBoxMobile {
        background: linear-gradient(90deg, #011031 3.17%, #011031 35.43%, #011031 68.66%, #011031 95.63%);
        color: white;
      }
          
    .hirlevelTextField .MuiFormControl-root{
      color: white;
      }
    .hirlevelTextField .MuiInputBase-input{
      color: white;
      }
  

  `;
const isMobile = window.innerWidth <= 768;

function Group49() {
  const intl = useIntl();
  const serviceList = useSelector((state: RootState) => state.serviceList);
  const language = useSelector((state: RootState) => state.language);
  useEffect(() => {
    //console.log('Effect ran due to changes in  language or serviceList');
    //console.log(serviceList);
  }, [language, serviceList]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <style>
        {css}
      </style>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Typography variant="h1" sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
          <FormattedMessage id='frontpage.Why_choose_us' />
        </Typography>

        <Box sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignContent: 'center'
        }}>

          <Paper elevation={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, m: 1 }}>
            <SupportAgentIcon sx={{ fontSize: 'xxx-large', color: 'lightblue', boxShadow: 2, m: 2 }} />
            <Typography sx={{ mb: 2, textTransform: 'uppercase' }}  >
              <FormattedMessage id='frontpage.Customer_services' />
            </Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tortor lectus, commodo eu vehicula nec,
              molestie sed libero. Ut porta aliquet malesuada. Nulla at magna nec enim pharetra faucibus eget in ante.
              Ut tristique blandit massa ac consectetur. Sed pulvinar vel magna ac luctus.
            </Typography>
          </Paper>

          <Paper elevation={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, m: 1 }}>
            <GppGoodIcon sx={{ fontSize: 'xxx-large', color: 'lightblue', boxShadow: 2, m: 2 }} />
            <Typography sx={{ mb: 2, textTransform: 'uppercase' }}  >
              <FormattedMessage id='frontpage.Guarantee' />
            </Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tortor lectus, commodo eu vehicula nec,
              molestie sed libero. Ut porta aliquet malesuada. Nulla at magna nec enim pharetra faucibus eget in ante.
              Ut tristique blandit massa ac consectetur. Sed pulvinar vel magna ac luctus.
            </Typography>
          </Paper>

          <Paper elevation={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1, m: 1 }}>
            <PeopleIcon sx={{ fontSize: 'xxx-large', color: 'lightblue', boxShadow: 2, m: 2 }} />
            <Typography sx={{ mb: 2, textTransform: 'uppercase' }}  >
              <FormattedMessage id='frontpage.Localteam' />
            </Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tortor lectus, commodo eu vehicula nec,
              molestie sed libero. Ut porta aliquet malesuada. Nulla at magna nec enim pharetra faucibus eget in ante.
              Ut tristique blandit massa ac consectetur. Sed pulvinar vel magna ac luctus.
            </Typography>
          </Paper>
        </Box>

        {!isMobile &&
          <Grid sx={{ m: 1 }}>
            <Card className='hajotulajdonosBox' sx={{ display: 'flex', justifyContent: 'space-between' }} >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', m: 1 }}>
                <div>
                  <Typography variant="h2" sx={{ pb: 1 }}>
                    <FormattedMessage id='frontpage.Boat_owner' />
                  </Typography>
                  <Typography variant="h3" >
                    <FormattedMessage id='frontpage.Join_us' />
                  </Typography>
                </div>
                <div>
                  <Button
                    variant="contained"
                    sx={{ pl: 2, pr: 2, pt: 1, pb: 1, textAlign: 'left', textTransform: 'none', border: 0, backgroundColor: '#FFD600', color: 'black', fontSize: '16px', fontWeight: 800 }}
                  >
                    <FormattedMessage id='frontpage.Button_interested' />
                  </Button>
                </div>
              </CardContent>
              <div>
                <img
                  src='https://vizre.hu/media/boatlefttoright240.jpg'
                  height='240'
                />
              </div>
            </Card>
          </Grid>
        }
        {isMobile &&
          <Paper elevation={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1,
              m: 1
            }}
            className='hajotulajdonosBoxMobile'
          >
            <div>
              <Typography variant="h2" sx={{ pb: 1 }}>
                <FormattedMessage id='frontpage.Boat_owner' />
              </Typography>
              <Typography variant="h3" >
                <FormattedMessage id='frontpage.Join_us' />
              </Typography>
            </div>
            <div>
              <Button
                variant="contained"
                sx={{ pl: 2, pr: 2, pt: 1, pb: 1, textAlign: 'left', textTransform: 'none', border: 0, backgroundColor: '#FFD600', color: 'black', fontSize: '16px', fontWeight: 800 }}
              >
                <FormattedMessage id='frontpage.Button_interested' />
              </Button>
            </div>
          </Paper>
        }


        <Paper elevation={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
            m: 1
          }}
          className={isMobile ? 'hirlevelBoxMobile' : 'hirlevelBox'}
        >
          <Typography variant="h2" paragraph={true}>
            <FormattedMessage id='frontpage.Subscribe_mailing_list' />
          </Typography>
          <Typography variant="h3" paragraph={true}>
            <FormattedMessage id='frontpage.Subscribe_mailing_list_be_the_first' />
          </Typography>


          <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            p: 1,
            m: 1
          }}>
            <TextField className="hirlevelTextField"
              required
              label={intl.formatMessage({ id: 'input.label.lastname' })}
              name='lastname'
              sx={{ input: { fontSize: 16, fontWeight: 400, color: 'white' }, p: 1 }}
            />
            <TextField
              required
              label={intl.formatMessage({ id: 'input.label.firstname' })}
              name='firstname'
              sx={{ input: { fontSize: 16, fontWeight: 400, color: 'white' }, p: 1 }}
            />
            <TextField
              required
              label={intl.formatMessage({ id: 'input.label.email' })}
              name='email'
              sx={{ input: { fontSize: 16, fontWeight: 400, color: 'white' }, p: 1 }}
            />
            <Button
              variant="contained"
              sx={{ pl: 2, pr: 2, pt: 1, pb: 1, textAlign: 'left', textTransform: 'none', border: 0, backgroundColor: '#FFD600', color: 'black', fontSize: '16px', fontWeight: 800 }}
            >
              <FormattedMessage id='frontpage.Button_Subscribe' />
            </Button>
          </Box>
        </Paper>

        <Paper
          elevation={5}
          sx={{
            display: 'flex',
            flexDirection: isMobile?'column':'row',
            alignContent: 'flex-end',
            justifyContent: 'space-evenly',
            p: 1,
            m: 1
          }}
          className={isMobile ? 'alulrolamasodikBoxMobile' : 'alulrolamasodikBox'}
        >
          <Link href="/" variant="body1" underline="hover">
            Vízre.hu
          </Link>
          <Stack>

            <Link href="/services" variant="body1" underline="hover">
              <FormattedMessage id='navbar.Services' />
            </Link>
            {serviceList && serviceList.length &&
              <div>
                {
                  serviceList.map((item: ServiceType) => (
                    item.lng === (language ?? 'hu') && (
                      <Link id={item.id_hash} href={item.virtual_url} variant="body1" underline="hover">
                        <Typography variant="body1">
                          {item.title}
                        </Typography>
                      </Link>
                    )
                  )
                  )}
              </div>


            }
          </Stack>

          <Stack>
            <Link href="/aboutus" variant="body1" underline="hover">
              <FormattedMessage id='navbar.Aboutus' />
            </Link>
            <Link href="/getaquote" variant="body1" underline="hover">
              <FormattedMessage id='navbar.Getaquote' />
            </Link>
          </Stack>
          <Stack>
            <Link href="/termsandconditions" variant="body1" underline="hover">
              <FormattedMessage id='hamburger.Termsandconditions' />
            </Link>
            <Link href="/consent" variant="body1" underline="hover">
              <FormattedMessage id='navbar.Consent' />
            </Link>
            <Link href="/privacypolicy" variant="body1" underline="hover">
              <FormattedMessage id='navbar.Privacypolicy' />
            </Link>
            <Link href="/usageagreement" variant="body1" underline="hover">
              <FormattedMessage id='navbar.Usageagreement' />
            </Link>
          </Stack>
          <Stack>
            <Link href="/impressum" variant="body1" underline="hover">
              <FormattedMessage id='navbar.Impressum' />
            </Link>
            <Link href="/contact" variant="body1" underline="hover">
              <FormattedMessage id='navbar.Contact' />
            </Link>
          </Stack>
        </Paper>


        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
            m: 1
          }}
          className={isMobile ? 'alsoBoxMobile' : 'alsoBox'}
        >
          <Typography variant="body1" paragraph={true}>
            (c) 2023 Cég neve | Cég székhelye | Egyéb adatok | Adószám
          </Typography>
        </Paper>
      </ThemeProvider >

    </div >

  );
}

export default Group49;
