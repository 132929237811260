import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';

interface MyProps {
  dateFrom: Date,
  dateTo: Date,

}
function d2sDot(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + String(date.getMonth() + 1)).slice(-2);
  const day = ('0' + String(date.getDate())).slice(-2);
  const formattedDate = `${year}.${month}.${day}`;
  return formattedDate;
}

function SingleReservationDate(props: MyProps) {

  var isDateEmpty: boolean = false;
  var isDateOneDay: boolean = false;
  var isDateMoreDays: boolean = false;
  if (props.dateFrom.getTime() === 0 || props.dateTo.getTime() === 0) {
    isDateEmpty = true;
  }
  else if (props.dateFrom.getTime() === props.dateTo.getTime()) {
    isDateOneDay = true;
  }
  else {
    isDateMoreDays = true;
  }

  return (
    <div>
      {isDateEmpty &&
        <Typography gutterBottom={false} align='center' variant="body2" component="div">
          <FormattedMessage id='singlepage.please_select_date' />
        </Typography>
      }
      {isDateOneDay &&
        <Typography gutterBottom={false} align='center' variant="body2" component="div">
          <FormattedMessage id='singlepage.reservation' />:<Fragment>&nbsp;</Fragment>
          {d2sDot(props.dateFrom)}
        </Typography>
      }
      {isDateMoreDays &&
        <Typography gutterBottom={false} align='center' variant="body2" component="div">
          <FormattedMessage id='singlepage.reservation' />:<Fragment>&nbsp;</Fragment>
          {d2sDot(props.dateFrom)} - {d2sDot(props.dateTo)}
        </Typography>
      }
    </div>
  );
}

export default SingleReservationDate;
