import React from 'react';
import { createRoot } from 'react-dom/client';
//import { useSelector, useDispatch } from 'react-redux';
//import { BrowserRouter as Router, Routes, Route, Link, useSearchParams } from "react-router-dom";
import App from "./App/App.js";
import { Provider } from 'react-redux'
import { createStore } from "redux";
//import store from './store'
import { initialState, loginReducer } from './store'
import LoadKikotoList from './tools/LoadKikotoList';
import LoadKiadoList from './tools/LoadKiadoList';
import LoadSysinfo from './tools/LoadSysinfo';
import LoadFaq from './tools/LoadFaq';
import LoadServices from './tools/LoadServices';
import {load as loadDocumentTemplate} from './tools/DbDocumentTemplate';
import LoadCharterDefaults from './tools/LoadCharterDefaults'; 
import LoadFakeDoorServices from './tools/LoadFakeDoorServices';

//import ReactGA from 'react-ga4';
//import ga4 from 'react-ga4';


console.log('*** Itt a kezdet (index.js)  ***');
//ReactGA.initialize("G-HD9W2BCSEC");

const store = createStore(loginReducer, initialState);
console.error('Store created');
const container = document.getElementById('root');
//console.log('container created');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
//console.log('root created');

var clientOnLocalhost;
if (window.location.origin.startsWith('http://localhost:3000')) {
   clientOnLocalhost = true; 
}
else {
   clientOnLocalhost = false;
}
// store.dispatch({ type: 'SetClientOnLocalhost', payload: clientOnLocalhost }); // use setTestMode / clearTetMode instead
var backendUrl;
if (clientOnLocalhost) { // running on localhost
  backendUrl = window.location.origin.replace('3000', '3001');
  backendUrl = backendUrl + '/api-localhost/';
}
else {
  if (window.location.pathname.endsWith('-dev') || window.location.pathname.endsWith('-dev/')) {
    backendUrl = window.location.origin + '/api-dev/';
  }
  else {
    backendUrl = '/api-prod/';
  }
}
window.backendUrl = backendUrl;
//console.log('window.backendUrl is: '+window.backendUrl);

var frontendBaseUrl = window.location.origin;
//console.log('window.location.pathname:');

//console.log(window.location.pathname);
//console.log('initial frontendBaseUrl is: ' + frontendBaseUrl);
if (frontendBaseUrl.includes(':3000')) {
  window.frontendBaseUrl = '/';
}
else {
  window.frontendBaseUrl = window.location.pathname;
}
//console.log('frontendBaseUrl is: ' + window.frontendBaseUrl);

// put this after backend URL calculation!
//var response=await LoadKikotoList();
//store.dispatch({ type: 'kikotoList', kikotoList: response });
LoadServices(store);
loadDocumentTemplate(store);
LoadFakeDoorServices(store);
LoadKikotoList(store);
LoadKiadoList(store);
LoadSysinfo(store);
LoadFaq(store);


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
//console.log('urlParams: ', urlParams);
// Get a specific parameter value
const myParam = urlParams.get('myParam');

// Get all parameter names and values as an object
const paramsObj = Object.fromEntries(urlParams.entries());
//console.log('paramsObj: ', paramsObj);

const CHARTER_PATTERN = 'setcharter';
if (urlParams.get(CHARTER_PATTERN)) {
  const charter = urlParams.get(CHARTER_PATTERN);
  //console.log('charter is: ', charter);
  store.dispatch({ type: 'SetForcedCharterGuid', payload: charter });
  //LoadCharterDefaults(store, charter);    
}
else {
  store.dispatch({ type: 'SetForcedCharterGuid', payload: '' });
}

store.dispatch({ type: 'ClearTestMode', payload: '' });
const TESTMODE_PATTERN = 'settestmode';
if (urlParams.get(TESTMODE_PATTERN)) {
  const testmode = urlParams.get(TESTMODE_PATTERN);
  if(testmode == 1){ // itt szandekos a ket egyenlosegjel! a testmode string tipusu!
    store.dispatch({ type: 'SetTestMode', payload: '' });
  }
}

const BOAT_PATTERN = 'setboat';
if (urlParams.get(BOAT_PATTERN)) {
  const boat = urlParams.get(BOAT_PATTERN);
  //console.log('boat is: ', boat);
  store.dispatch({ type: 'SetForcedBoatGuid', payload: boat });
}
else {
  store.dispatch({ type: 'SetForcedBoatGuid', payload: '' });
}

// http://localhost:3000/?setcharter=3b40723b-4e8c-4e83-b1f4-4c7ab2ed6925&setboat=65d6d34e-2df1-494a-85ac-c1cccd185af3

var generatedUniqueId=1;




root.render(

  <Provider store={store}>
    <App />
  </Provider>

);
