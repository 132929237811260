import React from 'react'

function CookiePolicies() {
  return (
    <div>
      <h1>This is the "CookiePolicies" page</h1>

      <dl>
						   <dt>Definition list</dt>
						   <dd>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
						aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
						commodo consequat.</dd>
						   <dt>Lorem ipsum dolor sit amet</dt>
						   <dd>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
						aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
						commodo consequat.</dd>
						</dl>

    </div>
  )
}

export default CookiePolicies
