import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Axios from "axios";

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { isValidName, isValidPhone } from '../tools/Tools'
import FormType from '../types/FormType';
import RootState from '../types/RootState';

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: () => void;
}

export function DialogUpdateProfile(props: SimpleDialogProps) {

  const dispatch = useDispatch();
  const intl = useIntl();
  const form: FormType = useSelector((state: RootState) => state.form);
  const [validation, setValidation] = useState<FormType>({});
  //const [errors, setErrors] = useState<FormErrorType>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);


  const loggedIn = useSelector((state: RootState) => state.currentUser.loggedIn);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'lastname') {
      dispatch({ type: 'FormValues', form: { ...form, lastname: value } });
    }
    else if (name === 'firstname') {
      dispatch({ type: 'FormValues', form: { ...form, firstname: value } });
    }
    else if (name === 'phone') {
      dispatch({ type: 'FormValues', form: { ...form, phone: value } });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  }

  const checkValidation = () => {
    setSubmitVisible(true);
    let errors: FormType = JSON.parse(JSON.stringify(validation));
    if (!form?.lastname?.trim()) {
      errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
      setSubmitVisible(false);
    }
    else if (!isValidName(form.lastname)) {
      errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
      setSubmitVisible(false);
    }
    else {
      errors.lastname = ' ';
    }

    if (!form?.firstname?.trim()) {
      errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' })
      setSubmitVisible(false);
    }
    else if (!isValidName(form.firstname)) {
      errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' });
      setSubmitVisible(false);
    }
    else {
      errors.firstname = ' ';
    }
    if (!form?.phone?.trim()) {
      errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_required' })
      setSubmitVisible(false);
    }
    else if (!isValidPhone(form.phone)) {
      errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_format_invalid' })
      setSubmitVisible(false);
    }
    else {
      errors.phone = ' ';
    }
    setValidation(errors);
    //setErrors(errors);
  }

  useEffect(() => {
    checkValidation();
  }, [form]);

  useEffect(() => {
    checkValidation();
  }, [props.open]);


  const handleSave = () => {
    updateProfile();
  }

  const dispatchLogin = (data: string) => {
    dispatch({ type: 'LoginWithEmail', currentUser: data });
  };

  const resetDisplayFlags = () => {
    setFormSubmitted(false);
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  }

  const handleClose = () => {
    resetDisplayFlags();
    props.onClose();
  };

  async function updateProfile() {
    setSubmitVisible(false);
    try {
      const response = await Axios.post(window.backendUrl + 'update_profile', form);
      if (response.status === 200) {
        console.log("updateProfile success");
        setFormSubmitted(true);
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        console.log(response.data[0]);
        dispatchLogin(response.data[0]);
      }
      else {
        console.log('Update unsuccessful');
        setFormSubmitted(true);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
      }
    }
    catch (error) {
      console.log(error);
      console.log('Update unsuccessful');
      setFormSubmitted(true);
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
    };
  };


  return (

    <Dialog
      onClose={handleClose}
      open={props.open}
      fullWidth
    >
      <DialogTitle><FormattedMessage id='dialog.title.update_profile' />
        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <List dense={true} disablePadding={true} >
          <ListItem>
            <TextField
              value={form?.lastname}
              required
              label={intl.formatMessage({ id: 'input.label.lastname' })}
              fullWidth
              name='lastname'
              helperText={validation.lastname}
              error={validation.lastname?.trim() !== ''}             
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              value={form?.firstname}
              required
              label={intl.formatMessage({ id: 'input.label.firstname' })}
              fullWidth
              name='firstname'
              helperText={validation.firstname}
              error={validation.firstname?.trim() !== ''}             
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              value={form?.phone}
              required
              label={intl.formatMessage({ id: 'input.label.phone' })}
              fullWidth
              name='phone'
              helperText={validation.phone}
              error={validation.phone?.trim() !== ''}             
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              value={form?.email}
              label={intl.formatMessage({ id: 'input.label.email' })}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </ListItem>

          {!formSubmitted &&
            <ListItem>
              <Button
                onClick={handleSave}
                variant='contained'
                fullWidth
                sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
                disabled={!submitVisible}
              >
                <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
                  <FormattedMessage id='submit.save' />
                </ListItemText>
              </Button>
            </ListItem>
          }
          {formSubmitted && showSuccessAlert &&
            <ListItem>
              <Alert variant='standard' severity='success' style={{width:'100%'}}>
                <FormattedMessage id='alert.save_successful' />
              </Alert>
            </ListItem>
          }

          {formSubmitted && showErrorAlert &&
            <ListItem>
              <Alert variant='standard' severity='error' style={{width:'100%'}}>
                <FormattedMessage id='alert.save_unsuccessful' />
              </Alert>
            </ListItem>
          }


        </List>
      </DialogContent>

      <DialogActions>
        <DeveloperModeIcon onClick={handleSave} />

        <Button autoFocus onClick={handleClose}>
          <FormattedMessage id='button.close' />
        </Button>
      </DialogActions>

    </Dialog>
  );
}


