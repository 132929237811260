import React from 'react'

function Privacypolicy() {
	return (
		<div>
			<h1>Adatkezelési tájékoztató</h1>
		</div>
	)
}
export default Privacypolicy
