//import { useSelector, useDispatch } from 'react-redux';

import Axios from "axios";

export default async function LoadBoatData(boatId: number) {
  //console.log('LoadBoatData: ', boatId, 'started');
  var selectedHajo;
  try {
    const req = {
      boatId: boatId
    }
    const response = await Axios.post(window.backendUrl + 'query_hajo', req);
    if (response.status === 200) {
      //console.log(response.data);
      selectedHajo = response.data.hajo;
      //console.log(selectedHajo);
      //dispatch({ type: 'selectedHajo', payload: selectedHajo });
    }
    else {
      console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }
  }
  catch (err) {
    console.error(err);
  }
  finally {
    //console.log('LoadBoatData: ', boatId, 'ended')
  }
  return selectedHajo;
};




