import { useIntl, FormattedMessage } from 'react-intl';
import { createSemicolonClassElement } from "typescript";

export const isStrongPassword = (password: string) => {
  var isValid: boolean = false;
  try {
    password = password.trim();
    if (password.trim().length <= 6 || password.trim().length >= 25) {
      throw ('too long or too short');
    }
    var regEx = {
      'capital': /[A-Z]/,
      'digit': /[0-9]/,
      'small': /[a-z]/
    };
    var ok = regEx.capital.test(password) &&
      regEx.digit.test(password) &&
      regEx.small.test(password);
    if (ok) {
      isValid = true;
    }

  }
  catch (x) {
    //console.log(x);
  }
  return isValid;
}

export const isValidName = (name: string) => {
  var isValid: boolean = false;
  name = name.trim();
  try {
    if (name.length >= 2 && name.length <= 100) {
      isValid = true;
    }
  }
  catch (x) {
    //console.log(x);
  }
  return isValid;
}

export const isValidPhone = (phone: string) => {
  var isValid: boolean = false;
  try {
    const phoneCleaned = phone.replace(/\s/g, '');
    // console.log(phoneCleaned, '-> ', phoneCleaned.length);    
    if (phoneCleaned.length < 8 || phoneCleaned.length > 15) {
      throw ('Cleaned phone number length must be between 8 and 20');
    }
    //console.log('phoneCleaned:', phoneCleaned);
    phone = phone.trim();
    if (phone.length < 8 || phone.length > 15) {
      throw ('Phone number length must be between 8 and 20');
    }
    const regExPhone = /^\+?\d+(?:[ ]?\d+)*$/;
    isValid = regExPhone.test(phone);
  }
  catch (x) {
    //console.error(x);
  }
  return isValid;
}

export const isValidEmailAddress = (email: string) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(mailformat)) {
    return true;
  }
  else {
    return false;
  }
}

export const d2s = (date: Date) => {
  var formattedDate: string = '';
  if (date) {
    const year = date.getFullYear();
    const month: string = ('0' + String(date.getMonth() + 1)).slice(-2);
    const day: string = ('0' + String(date.getDate())).slice(-2);
    formattedDate = `${year}-${month}-${day}`;
  }
  return formattedDate;
}

export const d2sDot = (date: Date) => {
  var formattedDate: string = '';
  if (date) {
    const year = date.getFullYear();
    const month = ('0' + String(date.getMonth() + 1)).slice(-2);
    const day = ('0' + String(date.getDate())).slice(-2);
    formattedDate = `${year}.${month}.${day}`;
  }
  return formattedDate;
}

// converts Date object 
// or YYYY-MM-DD format string 
// to YYYY.MM.DD or DD.MM.YYYY format string
export const d2sDotIntl = (intl: any, d: any) => {
  var f: string = '';
  try {
    var dd: Date = new Date(0);
    if (d) {
      //console.log('typeof: ', typeof d);
      if (typeof d === 'string') {
        //console.log('This is string: ', d);
        dd = new Date(d);
      }
      else if (d instanceof Date) {
        //console.log('This is Date: ', d);
        dd = d;
      }
      else {
        //console.log('What is it? ', d.constructor.name);
      }
      const year = dd.getFullYear();
      const month = ('0' + String(dd.getMonth() + 1)).slice(-2);
      const day = ('0' + String(dd.getDate())).slice(-2);
      if (intl.locale === 'hu') {
        f = `${year}.${month}.${day}`;
      }
      else {
        f = `${day}.${month}.${year}`;
      }
    }
  }
  catch (ex) {
    console.error(ex);
  }
  return f;
}

export const displayReservationPeriod = (intl: any, arazasTipus: string, dateFrom: Date, dateTo: Date) => {
  var x;
  //const intl = useIntl(); // de azt itt igy nem lehet hasznalni, tehat parameterkent kell megkapni
  if (arazasTipus && dateFrom && dateTo) {
    const dFrom = new Date(dateFrom);
    const dTo = new Date(dateTo);
    if (dFrom.getUTCFullYear() < 2022) {
      return;
    }
    if (dFrom.getUTCFullYear() > 2030) {
      return;
    }
    if (dFrom.getUTCFullYear() === dTo.getUTCFullYear() && dFrom.getUTCMonth() === dTo.getUTCMonth() && dFrom.getUTCDate() === dTo.getUTCDate()) {
      // Specific case: Only a single day';
      if (intl.locale === 'hu') {
        x = dFrom.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + dFrom.getUTCMonth() + "" }) + ' ' + dFrom.getUTCDate() + '.';
      }
      else {
        x = dFrom.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + dFrom.getUTCMonth() + "" }) + ' ' + dFrom.getUTCFullYear();
      }
    }
    else if (dFrom.getUTCFullYear() === dTo.getUTCFullYear() && dFrom.getUTCMonth() === dTo.getUTCMonth()) {
      // Specific case: Period spans multiple days within same month';
      if (intl.locale === 'hu') {
        x = dFrom.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + dFrom.getUTCMonth() + "" });
        x = x + ' ' + dFrom.getUTCDate() + '-' + dTo.getUTCDate() + '.';
      }
      else {
        x = intl.formatMessage({ id: "date_month_" + dFrom.getUTCMonth() + "" }) + ' ' + dFrom.getUTCFullYear();
        x = dFrom.getUTCDate() + '-' + dTo.getUTCDate() + ' ' + x;

      }
    }
    else if (dFrom.getUTCFullYear() === dTo.getUTCFullYear()) {
      // Specific case: Period spans multiple months within same year';
      if (intl.locale === 'hu') {
        x = dFrom.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + dFrom.getUTCMonth() + "" }) + ' ' + dFrom.getUTCDate() + '. - ';
        x = x + intl.formatMessage({ id: "date_month_" + dTo.getUTCMonth() + "" }) + ' ' + dTo.getUTCDate() + '.';
      }
      else {
        x = dFrom.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + dFrom.getUTCMonth() + "" }) + ' - ';
        x = x + dTo.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + dTo.getUTCMonth() + "" }) + ' ' + dTo.getUTCFullYear();

      }
    }
    else {
      // Generic case
      if (intl.locale === 'hu') {
        x = dFrom.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + dFrom.getUTCMonth() + "" }) + ' ' + dFrom.getUTCDate() + '. - ';
        x = x + dFrom.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + dTo.getUTCMonth() + "" }) + ' ' + dTo.getUTCDate() + '.';
      }
      else {
        x = dFrom.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + dFrom.getUTCMonth() + "" }) + ' ' + dFrom.getUTCFullYear() + ' - ';
        x = x + dTo.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + dTo.getUTCMonth() + "" }) + ' ' + dTo.getUTCFullYear();
      }
    }
    //x=x+' ('+intl.locale+')';
  }
  return (x);
}





export const displayDateHHMM = (intl: any, date: Date) => {
  var x;
  //const intl = useIntl(); // de azt itt igy nem lehet hasznalni, tehat parameterkent kell megkapni
  const d = new Date(date);
  if (d.getUTCFullYear() < 2022) {
    return;
  }
  if (d.getUTCFullYear() > 2030) {
    return;
  }
  if (intl.locale === 'hu') {
    x = d.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + d.getUTCMonth() + "" }) + ' ' + d.getUTCDate() + '.';
  }
  else {
    x = d.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + d.getUTCMonth() + "" }) + ' ' + d.getUTCFullYear();
  }
  x = x + ' ' + ('0' + d.getUTCHours()).slice(-2) + ':' + ('0' + d.getUTCMinutes()).slice(-2);
  //x=x+' ('+intl.locale+')';
  return (x);
}

export const displayDateWeekday = (intl: any, date: Date): string => {
  var x = '';
  //const intl = useIntl(); // de azt itt igy nem lehet hasznalni, tehat parameterkent kell megkapni
  const d = new Date(date);
  if (d.getUTCFullYear() < 2022) {
    return (x);
  }
  if (d.getUTCFullYear() > 2030) {
    return (x);
  }
  if (intl.locale === 'hu') {
    x = d.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + d.getUTCMonth() + "" }) + ' ' + d.getUTCDate() + '.';
  }
  else {
    x = d.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + d.getUTCMonth() + "" }) + ' ' + d.getUTCFullYear();
  }
  //x=x+' '+('0'+d.getUTCHours()).slice(-2)+':'+('0'+d.getUTCMinutes()).slice(-2);
  x = x + ', ' + intl.formatMessage({ id: "date_weekday_" + d.getUTCDay() + "" })
  //x=x+' ('+intl.locale+')';
  return (x);
}


export const displayHandoverTime = (intl: any, timeHours: number) => {
  var x: string = '';
  if (timeHours) {
    var timeString: string = String(timeHours) + ':00';
    x = intl.formatMessage({ id: "date_after" }, { x: timeString });
  }
  return (x);
}

export const displayReturnTime = (intl: any, timeHours: number) => {
  var x: string = '';
  if (timeHours) {
    var timeString: string = String(timeHours) + ':00';
    x = intl.formatMessage({ id: "date_before" }, { x: timeString });
  }
  return (x);
}


export const displayDateBefore = (intl: any, date: Date) => {
  var x;
  //const intl = useIntl(); // de azt itt igy nem lehet hasznalni, tehat parameterkent kell megkapni
  const d = new Date(date);
  if (d.getUTCFullYear() < 2022) {
    return;
  }
  if (d.getUTCFullYear() > 2030) {
    return;
  }
  if (intl.locale === 'hu') {
    x = d.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + d.getUTCMonth() + "" }) + ' ' + d.getUTCDate() + '-ig '
  }
  else {
    x = d.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + d.getUTCMonth() + "" }) + ' ' + d.getUTCFullYear();
  }
  //x=x+' ('+intl.locale+')';
  return (x);
}

export const displayDateAfter = (intl: any, date: Date) => {
  var x;
  //const intl = useIntl(); // de azt itt igy nem lehet hasznalni, tehat parameterkent kell megkapni
  const d = new Date(date);
  if (d.getUTCFullYear() < 2022) {
    return;
  }
  if (d.getUTCFullYear() > 2030) {
    return;
  }
  if (intl.locale === 'hu') {
    x = d.getUTCFullYear() + '. ' + intl.formatMessage({ id: "date_month_" + d.getUTCMonth() + "" }) + ' ' + d.getUTCDate() + ' után';
  }
  else {
    x = d.getUTCDate() + ' ' + intl.formatMessage({ id: "date_month_" + d.getUTCMonth() + "" }) + ' ' + d.getUTCFullYear();
  }
  //x=x+' ('+intl.locale+')';
  return (x);
}

export const niceNumber = (intl: any, n: Number) => {
  var x;
  //const intl = useIntl(); // de azt itt igy nem lehet hasznalni, tehat parameterkent kell megkapni
  if (n === 0) {
    x = '';
    return x;
  }
  if (intl.locale === 'hu') {
    x = n.toLocaleString('hu-HU');
  }
  else {
    x = n.toLocaleString('en-GB');
  }
  //x=x+' ('+intl.locale+')';
  return (x);
}


