import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Button, Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import RootState from '../types/RootState';
import { useCallback, useEffect, useState } from 'react';
import LoadPricing from '../tools/LoadPricing';
import { QuoteType, emptyQuote } from '../types/DatabaseTypes';
import { niceNumber } from '../tools/Tools';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 400
    },
    h2: {
      fontSize: 24,
      fontWeight: 800
    },
    h3: {
      fontSize: 16,
      fontWeight: 500
    },
    h4: {
      fontSize: 24,
      fontWeight: 800,
      color: 'lightblue',
    },
    body1: {
      fontSize: 18,
      fontWeight: 500,
      background: 'black',
      color: 'white'

    },
    body2: {
      fontSize: 16,
      fontWeight: 500
    },
  }
});

const isMobile = window.innerWidth <= 768;
function BoatDetailsReservationdetails() {
  //console.log('BoatDetailsReservationdetails() called');
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  const intl = useIntl();
  const boatSearchBarForm = useSelector((state: RootState) => state.boatSearchBarForm);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const quote: QuoteType = useSelector((state: RootState) => state.quote);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5}
            sx={{ p: 1, m: 1 }}
          >
            <Typography
              variant="h1"
              paragraph={false}
              sx={{ p: 1 }}>
              <FormattedMessage id='boat.Reservationdetails.Title' />
            </Typography>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column'
              }}>

              <Box>
                <Typography variant="h2" component="div">
                  {selectedHajo.model} | {selectedHajo.nev}
                </Typography>
                <Typography gutterBottom={false} variant="body2">
                  <FormattedMessage id='reservation_details.charter_company_name' />:&nbsp;
                  {selectedHajo?.kiado_nev ?? ''}
                </Typography>
              </Box>

              <Box
                sx={{
                  p: 0,
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}>
                <Box>
                  <Typography className='rounded' variant='body1'>
                    <FormattedMessage id='reservation_card_details.subtitle.paymentdetails' />

                  </Typography>
                  {quote.calculation_valid &&
                    <>
                      <Typography variant='body2'>
                        {quote.downpayment100}%&nbsp;
                        < FormattedMessage id='reservation_card_details.downpayment' />
                      </Typography>
                      <Typography variant='body2'>
                        (Ingyenesen lemondható holnapig)
                      </Typography>
                      <Typography variant='body2'>
                        (Heti csomag ár)
                      </Typography>
                    </>
                  }
                </Box>
                <Box>
                  <Typography className='rounded' variant='body1'>
                    <FormattedMessage id='reservation_card_details.subtitle.pricecontains' />
                  </Typography>
                  <Typography variant='body2'>
                    (Letéti biztosítás)
                  </Typography>
                  <Typography variant='body2'>
                    (Hosztesz)
                  </Typography>
                  <Typography variant='body2'>
                    (Kapitány)
                  </Typography>
                  <Typography variant='body2'>
                    (Tranzit log)
                  </Typography>
                </Box>
                <Box>
                  <Typography className='rounded' variant='body1'>
                    <FormattedMessage id='reservation_card_details.subtitle.summary' />

                  </Typography>
                  {quote.calculation_valid && Boolean(quote.boat_price > 0) &&
                    <>
                      {Boolean(quote.total_days) &&
                        <Typography variant='body2'>
                          {quote.total_days}&nbsp;
                          < FormattedMessage id='rental.daysrentalprice' />
                        </Typography>
                      }
                      {Boolean(quote.total_hours) &&
                        <Typography variant='body2'>
                          {quote.total_hours}&nbsp;
                          < FormattedMessage id='rental.hoursrentalprice' />
                        </Typography>
                      }
                      <Typography variant='h4'>
                        {niceNumber(intl, quote.boat_price)} {quote.currency}
                      </Typography><Button variant='contained'>
                        <FormattedMessage id='reservation_card_details.button.reservation' />
                      </Button>
                    </>
                  }
                  {!quote.calculation_valid || Boolean(quote.boat_price <= 0) &&
                    <>
                      <Typography variant='body1'>
                        Összegzés
                      </Typography>
                      <Typography variant='h4'>
                        < FormattedMessage id='reservation_card_details.askforprice' />
                      </Typography>
                    </>
                  }
                </Box>
              </Box>

            </Box>

          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsReservationdetails;
