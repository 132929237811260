
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Paper, ThemeProvider, Typography } from "@mui/material";
import RootState from '../types/RootState';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import ReactCountryFlag from 'react-country-flag';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 500
    },
    body1: {
      fontSize: 18,
      fontWeight: 400
    },
    body2: {
      fontSize: 18,
      fontWeight: 800
    },
  }
});

function DoYouHaveQuestions() {
  const intl = useIntl();
  const language = useSelector((state: RootState) => state.language);
  useEffect(() => {
    //console.log('DoYouHaveQuestions() Effect ran due to changes in language');
  }, [language]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Paper elevation={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'wrap',
            p: 1,
            m: 1
          }}
        >
          <Box>
            <SupportAgentIcon sx={{ fontSize: 'xxx-large', color: 'lightblue', boxShadow: 2, m: 2 }} />
          </Box>
          <Typography paragraph={true} variant="h1">
            <FormattedMessage id='doyouhavequestions.line1' />
          </Typography>
          <Typography paragraph={true}>
            <FormattedMessage id='doyouhavequestions.line2' />
          </Typography>
          <Typography paragraph={true}>
            <FormattedMessage id='doyouhavequestions.line3' />
          </Typography>
          <Box sx={{ display: 'flex', direction: 'row', justifyContent: 'center' }}>
            <Box sx={{ m: 1 }}>
              <ReactCountryFlag countryCode="HU" svg style={{ width: '2em', height: '1em' }} />
            </Box>
            <Box sx={{ m: 1 }}>
              <ReactCountryFlag countryCode="GB" svg style={{ width: '2em', height: '1em' }} />
            </Box>
          </Box>
          <Typography variant="body2">
            <FormattedMessage id='doyouhavequestions.line4' />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id='doyouhavequestions.line5' />
          </Typography>
        </Paper>
      </ThemeProvider>
    </div >
  );
}
export default DoYouHaveQuestions;
