
import React, { useState, useEffect, useCallback, isValidElement } from "react";
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import Axios from "axios";



export default async function ResetPasswordByGuid(guid: string, pwd: string) {
  var isOk: boolean = false;
  console.log('ResetPasswordByGuid() called. guid: '+guid)
  const req={guid: guid, pwd: pwd}
  try{
    const response=await Axios.post(window.backendUrl+'reset_password_by_guid', req);
    if(response.status === 200){
      isOk=true;
    }
    else{
      console.log("Error! Response of Axio post is not 200, but "+response.status+" "+response.statusText);    
      throw new Error('Something went wrong');
    }  
  }
  catch(error){
    console.log(error);
  }
  finally{
    // nothing to do finally
  }
  console.log('isOk: '+isOk)

return isOk;
}

