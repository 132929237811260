import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Axios from "axios";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { isStrongPassword } from '../tools/Tools'
import RegisterFormType from '../types/RegisterFormType';
import RootState from '../types/RootState';

import ResetPasswordByGuid from "../tools/ResetPasswordByGuid";

export interface DialogUseResetPasswordLinkProps {
  open: boolean;
  setOpen: () => void;
  selectedValue: string;
  onClose: () => void;
  onMixedLoginClicked: () => void;
}

export function DialogUseResetPasswordLink(props: DialogUseResetPasswordLinkProps) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const form: RegisterFormType = useSelector((state: RootState) => state.registerForm);
  const [validation, setValidation] = useState<RegisterFormType>({});
  const [apiCallStarted, setApiCallStarted] = useState(false);
  const [apiCallEnded, setApiCallEnded] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [guidFound, setGuidFound] = useState(false);
  const [guid, setGuid] = useState('');


  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'password') {
      dispatch({ type: 'RegisterFormValues', registerForm: { ...form, password: value } });
    }
    else if (name === 'password2') {
      dispatch({ type: 'RegisterFormValues', registerForm: { ...form, password2: value } });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  }
  const checkValidation = () => {
    setSubmitVisible(true);
    let errors: RegisterFormType = JSON.parse(JSON.stringify(validation));

    if (!form?.password?.trim()) {
      errors.password = intl.formatMessage({ id: 'input.validation.password_required' })
      setSubmitVisible(false);
    }
    else if (!isStrongPassword(form.password)) {
      errors.password = intl.formatMessage({ id: 'input.validation.password_weak' })
      setSubmitVisible(false);
    }
    else {
      errors.password = ' ';
    }

    if (!form?.password2?.trim()) {
      errors.password2 = intl.formatMessage({ id: 'input.validation.password2_required' })
      setSubmitVisible(false);
    }
    else if (form.password !== form.password2) {
      errors.password2 = intl.formatMessage({ id: 'input.validation.passwords_do_not_match' })
      setSubmitVisible(false);
    }
    else {
      errors.password2 = ' ';
    }

    setValidation(errors);
  }

  useEffect(() => {
    checkValidation();
  }, [form]);

  const handleSave = () => {
    resetDisplayFlags();
    handleSubmit();
  }

  const resetDisplayFlags = () => {
    setFormSubmitted(false);
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    setApiCallStarted(false);
    setApiCallEnded(false);
  }
  const handleClose = () => {
    resetDisplayFlags();
    dispatch({ type: 'ClearRegisterFormValues', null: null });
    props.onClose();
  };


  const handleSubmit = () => {
    setFormSubmitted(true);
    //console.log('handleSubmit, here are the parameters (pwd and guid):');
    //console.log(form.password);
    //console.log(guid);
    let p1: string = form.password as string;
    ResetPasswordByGuid(guid, p1).then((result) => {
      //ResetPasswordByGuid(guid,formValues.pwd1).then((result) => {
      console.log('ResetPasswordByGuid end.');
      console.log('result:');
      console.log(result);
      if (result) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
      }
      else {
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
      }
    }).catch((error) => {
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
      console.log('ResetPasswordByGuid end with error');
      console.log(error);
    });
  }

  
// dependency: Empty Array ( [] ) 
// no dependencies that will trigger the callback within it. 
// Our code inside the callback will run once when the component gets registered, and that is it.
  useEffect(() => {
    //console.log('useEffect called in DialogUseResetPasswordLink()');
    //props.setOpen();
    //  const params = new URLSearchParams(window.location.pathname);
    const pathname = window.location.pathname;
    //console.log('pathname:');
    //console.log(pathname);
    const w_l_search = window.location.search;
    //console.log('window.location.search is:');
    //console.log(w_l_search);
    const GUID_PATTERN = '?guid=';
    //console.log('GUID_PATTERN is:');
    //console.log(GUID_PATTERN);
    setGuidFound(false);
    if (w_l_search.startsWith(GUID_PATTERN)) {
      console.log('Yes, we will perform an API call');
      props.setOpen();
      setApiCallStarted(true);
      const guid = w_l_search.substring(GUID_PATTERN.length);
      const req = { guid: guid }
      try {
        Axios.post(window.backendUrl + 'is_password_reset_guid_valid', req).then((result) => {
          if (result.status === 200) {
            setGuidFound(true);
            setGuid(guid);
          }
          else {
            setGuidFound(false);
          }
        }).catch((error) => {
          setGuidFound(false);
          setApiCallEnded(true);
          console.log('error:');
          console.log(error);
        });
      }
      catch (x) {
        //console.error(x);
      }
    }
    else {
      //console.log('This not an API call.');
    }
  }, [])

  return (
    <>
      <Dialog
        open={props.open && guidFound}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle><FormattedMessage id='dialog.title.enter_new_password' />
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>

          <List sx={{ pt: 0, m: 2 }}>
            <ListItem>
              <TextField
                value={form.password}
                required
                type='password'
                label={intl.formatMessage({ id: 'input.label.password' })}
                fullWidth
                name='password'
                helperText={validation.password}
                error={validation.password?.trim() !== ''}
                onChange={handleTextFieldChange} />
            </ListItem>

            <ListItem>
              <TextField
                value={form.password2}
                required
                type='password'
                label={intl.formatMessage({ id: 'input.label.password2' })}
                fullWidth
                name='password2'
                helperText={validation.password2}
                error={validation.password2?.trim() !== ''}
                onChange={handleTextFieldChange} />
            </ListItem>
            {!formSubmitted &&
              <ListItem>
                <Button
                  onClick={handleSave}
                  variant='contained'
                  fullWidth
                  sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
                  disabled={!submitVisible}
                >
                  <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
                    <FormattedMessage id='submit.save' />
                  </ListItemText>
                </Button>
              </ListItem>
            }
            {formSubmitted && showSuccessAlert &&
              <ListItem>
                <Alert variant='standard' severity='success' style={{ width: '100%' }}>
                  <FormattedMessage id='alert.password_reset_successful' />
                </Alert>
              </ListItem>
            }

            {formSubmitted && showErrorAlert &&
              <ListItem>
                <Alert variant='standard' severity='error' style={{ width: '100%' }}>
                  <FormattedMessage id='alert.password_reset_unsuccessful' />
                </Alert>
              </ListItem>
            }

          </List>
        </DialogContent>
        <DialogActions>
          <DeveloperModeIcon onClick={handleSave} />
          <Button autoFocus onClick={handleClose}>
            <FormattedMessage id='button.close' />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={props.open && formSubmitted && !guidFound}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle><FormattedMessage id='dialog.title.enter_new_password' />
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <List>
            <ListItem>
              <Alert variant='standard' severity='error' style={{ width: '100%' }}>
                <FormattedMessage id='alert.password_reset_link_invalid_or_expired' />
              </Alert>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            <FormattedMessage id='button.close' />
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={props.open && apiCallStarted && !apiCallEnded}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle><FormattedMessage id='dialog.title.enter_new_password' />
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <List>
            <ListItem>
              <Alert variant='standard' severity='info' style={{ width: '100%' }}>
                <FormattedMessage id='alert.data_fetch_pending' />
              </Alert>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <DeveloperModeIcon onClick={handleSave} />
          <Button autoFocus onClick={handleClose}>
            <FormattedMessage id='button.close' />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={props.open && !formSubmitted && apiCallEnded}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle><FormattedMessage id='dialog.title.enter_new_password' />
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <List>
            <ListItem>
              <Alert variant='standard' severity='error' style={{ width: '100%' }}>
                <FormattedMessage id='alert.password_reset_link_invalid_or_expired' />
              </Alert>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <DeveloperModeIcon onClick={handleSave} />
          <Button autoFocus onClick={handleClose}>
            <FormattedMessage id='button.close' />
          </Button>
        </DialogActions>
      </Dialog>



    </>

  )
}

