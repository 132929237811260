
import React, { useEffect, useCallback, useState } from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Axios from "axios";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {FormattedMessage} from 'react-intl'
import BoatCard from "../components/BoatCard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function Orderdetails() {
  const [selectedQuote, setSelectedQuote] = useState(null);   
  const [searchParams, setSearchParams] = useSearchParams();
  const quoteId = searchParams.get('quoteid');
  console.log('Quote Id:',quoteId);

const navigate = useNavigate();  
const dispatch = useDispatch();

const handleHome = (id) => {
  console.log('Continue pressed'); 
  navigate('/home');  
};

const fetchQuoteHandler = useCallback( async () => {

  console.log("fetchQuoteHandler");  
  console.log(quoteId);  
  var postBody = {
    quoteId: quoteId
  };   
  try{
    const response=await Axios.post(window.backendUrl+'query_quote', postBody);
    if(response.status === 200){
      console.log("query quote done");
      console.log(response.data);
      setSelectedQuote(response.data);
      dispatch({type: 'selectedQuote', selectedQuote});     // a változó nevének is  "selectedQuote"-nak  kell lennie, miért?!  

    }
    else{
      console.log("Error! Response of Axio post is not 200, but "+response.status+" "+response.statusText);    
      throw new Error('Something went wrong');
    }  
  }
  catch(error){
    console.log(error);
    //setError('X: '+error.message);

  };
},[dispatch]);


useEffect(() => {
  console.log('useEffect called in Orderdetails()');
  fetchQuoteHandler();
},[])

return (
  <div>

{selectedQuote &&
<Typography variant="h6" color="text.secondary"  sx={{ m: 1}}>
    <BoatCard product={selectedQuote.hajo}/>
    <br></br>
    Az Ön adatai:<br></br>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Grid item xs={4}>
      <Item>Név: {selectedQuote.quote.lastname} {selectedQuote.quote.firstname}</Item>
    </Grid>
    <Grid item xs={4}>
      <Item>Email: {selectedQuote.quote.email}</Item>
    </Grid>
    <Grid item xs={4}>
      <Item>Telefon: {selectedQuote.quote.phone}</Item>
    </Grid>
  </Grid><br></br><br></br>
  <br></br>
    Megjegyzés:<br></br>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Grid item xs={12}>
      <Item>{selectedQuote.quote.remark}</Item>
    </Grid>
  </Grid>        <br></br><br></br>
  A foglalás részletei:<br></br>
  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Grid item xs={4}>
      <Item>Hajó átvétel: {selectedQuote.quote.tol.substring(0,10)}</Item>
    </Grid>
    <Grid item xs={4}>
      <Item>Hajó leadás: {selectedQuote.quote.ig.substring(0,10)}</Item>
    </Grid>
    <Grid item xs={4}>
      <Item>A bérlés hossza: {selectedQuote.quote.total_days} nap</Item>
    </Grid>
    <Grid item xs={4}>
      <Item>A hajóátvétel helye: </Item>
    </Grid>
    <Grid item xs={4}>
      <Item>Charter cég:</Item>
    </Grid>
  </Grid>        
  <br></br>
  Ár:<br></br>
  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Grid item xs={4}>
      <Item>Ár: {selectedQuote.quote.total_price}</Item>
    </Grid>
  </Grid>        

  </Typography>
}
<Button variant="contained" onClick={() => {handleHome()}}>
    OK
  </Button>
  {false &&
  <Button variant="contained" onClick={() => {fetchQuoteHandler()}}>
    Refresh
  </Button>
}


  </div>  
)
}

export default Orderdetails;
