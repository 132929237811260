import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import RootState from '../types/RootState';

import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import SingleBedOutlinedIcon from '@mui/icons-material/SingleBedOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 500
    },
    body1: {
      fontSize: 14,
      fontWeight: 500
    },
    body2: {
      fontSize: 18,
      fontWeight: 500
    },
  }
});

function BoatDetailsCabins() {
  //console.log('BoatDetailsCabins() called');
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  return (
    <div>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5}
            sx={{ p: 1, m: 1 }}
          >
            <Typography
              variant="h1"
              paragraph={false}
              sx={{ p: 1 }}>
              <FormattedMessage id='boat.Cabins.Title' />
            </Typography>
            <Typography
              variant="body1"
              paragraph={false}
              sx={{ p: 1 }}
            >
            </Typography>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              p: 1
            }}>

              {Boolean(selectedHajo.kabin_2_db) &&
                <Typography variant="body1" paragraph={false} sx={{ pr: 3 }}>
                  <BedOutlinedIcon fontSize='large' />
                  <br />
                  {selectedHajo.kabin_2_db} x
                  <br />
                  <FormattedMessage id='boat.Cabin2' />
                </Typography>
              }

              {Boolean(selectedHajo.kabin_1_db) &&
                <Typography variant="body1" paragraph={false} sx={{ pr: 3 }}>
                  <SingleBedOutlinedIcon fontSize='large' />
                  <br />
                  {selectedHajo.kabin_1_db} x
                  <br />
                  <FormattedMessage id='boat.Cabin1' />
                </Typography>
              }
              {Boolean(selectedHajo.emeletes_kabin_db) &&
                <Typography variant="body1" paragraph={false} sx={{ pr: 3 }}>
                  <GroupsOutlinedIcon fontSize='large' />
                  <br />
                  {selectedHajo.emeletes_kabin_db} x
                  <br />
                  <FormattedMessage id='boat.Cabinx' />
                </Typography>
              }
              {Boolean(selectedHajo.aludhat_max) &&
                <Typography variant="body1" paragraph={false} sx={{ pr: 0 }}>
                  <HotelOutlinedIcon fontSize='large' />
                  <br />
                  {selectedHajo.aludhat_max} x
                  <br />
                  <FormattedMessage id='boat.Bedstotal' />
                </Typography>
              }
            </Box>

          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsCabins;
