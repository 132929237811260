import { useSelector, useDispatch } from 'react-redux';
import HajoType from '../types/HajoType'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CssBaseline, IconButton, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import WifiIcon from '@mui/icons-material/Wifi';
import PercentIcon from '@mui/icons-material/Percent';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ReactCountryFlag from 'react-country-flag';
//import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
//import yellowtail from 'typeface-yellowtail';


//import '../styles/figma.css';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3', // Change primary color
    },
    secondary: {
      main: '#FF5722', // Change secondary color
    },

  },

  typography: {
    // fontFamily: 'Sans-serif', // Change default font
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    h2: {
      fontSize: 12,
      fontWeight: 600
    },
    h3: {
      fontSize: 20,
      fontWeight: 800,
      color: '#6AB2BC',
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,
      color: '#6AB2BC',
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 500,
      backgroundColor: '#FFD600',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
      color: 'white',
      backgroundColor: 'black',
    },
    body1: {
      fontSize: 12,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 700
    },
  },
});

interface MyProps {
  hajo: HajoType,
  dateFrom?: Date,
  dateTo?: Date,

}
function d2s(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + String(date.getMonth() + 1)).slice(-2);
  const day = ('0' + String(date.getDate())).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}


const isMobile = window.innerWidth <= 768;

//function BoatCardSmall(key: string, hajo: HajoType, dateFrom: DataTransfer, dateTo: Date) {
function BoatCardSmall(props: MyProps) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const HandleDescriptionClick = (id: number) => {
    dispatch({ type: 'selectBoatId', selectedBoatId: id });
    //console.log('Itt kellene betölteni az árazás részleteket')

    navigate('/boatdetailspage');
  };

  const displayPrice = (price: number) => {
    var x;
    if (price > 0) {
      x = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'HUF',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(price);
      // x = price+' Ft';
    }
    else {
      x = 'Az árról érdeklődjön munkatársunknál';
    }
    return (x);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  var cabin: number = 0;
  if (props) {
    cabin = Number(props.hajo.kabin_1_db ?? 0) + Number(props.hajo.kabin_2_db ?? 0) + Number(props.hajo.emeletes_kabin_db ?? 0);
  }

  const css = `

  .bovebben  {
    width: 100%;
    padding: 0px;
    margin: 0px;
    text-align: right;
    background-color: white;
    border-collapse: collapse;
    border: 0;
  },
  .bovebben button {
    background-color: white;

  }
  .tableClass  {
    width: 100%;
    padding: 0px;
    margin: 0px;
    text-align: center;
    background-color: white;
    border-collapse: collapse;
    border: 0;
  },

  .customBlue {
    color: white;
    background:'#5382E9';
  }
  `;


  //console.log('BoatCardSmall hajo:', props);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style>
        {css}
      </style>
      <div>
        {!isMobile &&
          <Card sx={{
            height: 194,
            width: 860,
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 2
          }}
          >
            <CardMedia sx={{ height: 194, width: 189, display: 'flex' }}
              component="img"
              image={props.hajo.pic_guid}
              alt="boat picture"
            />

            <Box sx={{ height: 194, width: 480, display: 'flex', flexDirection: 'column', border: 0 }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography variant="h1" component="div">
                  {props.hajo.model} | {props.hajo.nev}
                </Typography>
                <Typography variant="body1" component="div">
                  <ReactCountryFlag countryCode="HU" svg style={{ width: '1em', height: '1em' }} /> {props.hajo.kikoto_nev}
                </Typography>
                <Grid container spacing={0} sx={{ pt: 2, pb: 2 }}>
                  <Grid xs={3}>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Yearofmanufacture' />
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body2">
                      {props.hajo.gyartasi_ev}
                    </Typography>
                  </Grid>

                  <Grid xs={3}>
                    <Typography variant="body1">
                      Hajótípus
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body2">
                      Vitorlás
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Maxpassengers' />
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body2">
                      {props.hajo.max_utasok}
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Cabins' />
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body2">
                      {cabin}
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Length' />
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body2">
                      {props.hajo.hossz} m
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Sail' />
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography variant="body2">
                      {props.hajo.fovitorla}
                    </Typography>
                  </Grid>
                </Grid>
                <table className='tableClassWifi'>
                  <tr>
                    {props.hajo.free_wifi &&
                      <td>
                        <Typography variant="subtitle1" >
                          <FormattedMessage id='boat.Free_wifi' />
                        </Typography>
                      </td>
                    }
                    {props.hajo.downpayment_100 > 0 &&
                      <td>
                        <Typography variant="subtitle1">
                          <FormattedMessage
                            id='boat.Downpayment_only'
                            values={{ downpayment: 10 }} />
                        </Typography>
                      </td>
                    }
                    <td>
                      <Typography variant="subtitle2">
                        <FormattedMessage id='boat.Cancellation_possible' />
                      </Typography>
                    </td>

                  </tr>
                </table>
              </CardContent>
            </Box>

            <Box sx={{ height: 194, width: 189, display: 'flex', justifyContent: 'right', pr: 1, border: 0 }}>
              <table className='bovebben'>
                <tr>
                  <td>
                    <Typography variant="h2">
                      {props.hajo.kiado_nev}
                    </Typography>
                  </td>
                </tr>
                {props.hajo.price_per_day_from > 0 &&
                  <tr>
                    <td>
                      <Typography variant="h3">
                        {props.hajo.price_per_day_from}
                      </Typography>
                      <Typography variant="h4">
                        <FormattedMessage id='boat.Price_per_day_from' />
                      </Typography>
                    </td>
                  </tr>
                }
                <tr>
                  <td>
                    <Button
                      className='customBlue'
                      variant='contained'
                      sx={{ height: 48, width: 154, textAlign: 'left', textTransform: 'none', border: 0, backgroundColor: '#5382E9' }}
                      onClick={() => { HandleDescriptionClick(props.hajo.id) }}
                    >
                      <FormattedMessage id='button.Details' />
                    </Button>
                  </td>
                </tr>

              </table>
            </Box>
          </Card>
        }

        {isMobile &&
          <><Paper
            elevation={5}
            sx={{
              p: 1,
              m: 1
            }}
          >
            <Typography variant="h1" component="div">
              {props.hajo.model} | {props.hajo.nev}
            </Typography>
            <Typography variant="body1" component="div">
              <ReactCountryFlag countryCode="HU" svg style={{ width: '1em', height: '1em' }} /> {props.hajo.kikoto_nev}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }}
            >
            </Box>
            <Card sx={{ height: 400, display: 'flex', justifyContent: 'flex-start', m: 2 }}>
              <CardMedia sx={{ maxWidth: 120, display: 'flex' }}
                component="img"
                image={props.hajo.pic_guid}
                alt="boat picture" />

              <Box sx={{ height: 400, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', border: 0 }}>
                <table className='tableClass'>
                  <tr />
                  <td>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Yearofmanufacture' />
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2">
                      {props.hajo.gyartasi_ev}
                    </Typography>
                  </td>

                  <tr />
                  <td>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Maxpassengers' />
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2">
                      {props.hajo.max_utasok}
                    </Typography>
                  </td>

                  <tr />
                  <td>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Length' />
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2">
                      {props.hajo.hossz} m
                    </Typography>
                  </td>

                  <tr />
                  <td>
                    <Typography variant="body1">
                      Hajótípus
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2">
                      Vitorlás
                    </Typography>
                  </td>

                  <tr />
                  <td>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Cabins' />
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2">
                      {cabin}
                    </Typography>
                  </td>

                  <tr />
                  <td>
                    <Typography variant="body1">
                      <FormattedMessage id='boat.Sail' />
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2">
                      {props.hajo.fovitorla}
                    </Typography>
                  </td>


                  {props.hajo.free_wifi &&
                    <>
                      <tr />
                      <td colSpan={2}>
                        <Typography variant="subtitle1">
                          <FormattedMessage id='boat.Free_wifi' />
                        </Typography>
                      </td></>
                  }

                  {props.hajo.downpayment_100 > 0 &&
                    <><tr />
                      <td colSpan={2}>

                        <Typography variant="subtitle1">
                          <FormattedMessage
                            id='boat.Downpayment_only'
                            values={{ downpayment: 10 }} />
                        </Typography>
                      </td></>}
                  <tr />
                  <td colSpan={2}>
                    <Typography variant="subtitle2">
                      <FormattedMessage id='boat.Cancellation_possible' />
                    </Typography>
                  </td>

                  {props.hajo.price_per_day_from > 0 &&
                    <tr>
                      <td>
                        <Typography variant="h3">
                          {props.hajo.price_per_day_from}
                        </Typography>
                        <Typography variant="h4">
                          <FormattedMessage id='boat.Price_per_day_from' />
                        </Typography>
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>
                      <Button
                        className='customBlue'
                        variant='contained'
                        sx={{ height: 48, width: 154, textAlign: 'left', textTransform: 'none', border: 0, backgroundColor: '#5382E9' }}
                        onClick={() => { HandleDescriptionClick(props.hajo.id) }}
                      >
                        <FormattedMessage id='button.Details' />
                      </Button>
                    </td>
                  </tr>




                </table>
              </Box>
            </Card>
          </Paper>
          </>
        }
      </div >
    </ThemeProvider >
  );
}

export default BoatCardSmall;
