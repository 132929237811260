
import React from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

export default function CarouselCard({product}) {
  return (
    <Card sx={{ 
      display: 'flex',
      maxWidth: 640, 
      borderColor: 'primary.main',
      border: 1,
      borderRadius: 3,
      padding: 2,
      margin: 3,
      boxShadow: 5,
      alignItems: 'center',
      justifyContent: 'center'  
    }}>
    <CardMedia
      component="img"
      height="480"
      src={product.pic_guid}
    />
    </Card>
  )
}
