
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from "axios";

import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';

import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { FormattedMessage } from 'react-intl'
//import BoatOverviewCard from "./BoatOverviewCard";
import BoatCardSmall from '../components/BoatCardSmall';
import BoatSearchBar from "../components/BoatSearchBar";
import DoYouHaveQuestions from '../components/DoYouHaveQuestions';
import BoatSearchBarFormType from '../types/BoatSearchBarFormType';
import RootState from '../types/RootState';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const isMobile = window.innerWidth <= 768;

function SearchPage() {
  const form = useSelector(state => state.boatSearchBarForm);
  //const forcedCharterGuid: string = useSelector((state: RootState) => state.forcedCharterGuid); // ez lenne TS esetén
  const forcedCharterGuid = useSelector(state => state.forcedCharterGuid);
  form['forcedCharterGuid'] = forcedCharterGuid;
  const [resultList, setResultList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSearch = (id) => {
    navigate('/search');
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const searchPic = useCallback(async () => {
    //dispatch({ type: 'clearBoatId', null: null });
    // nem töröljük, hanem az ezt hívó  törli, ha szükség van rá!
    // ha előzőleg hajót választottunk, akkor nem szabad törölni!
    setResultList([]);
    setIsLoading(true);
    setIsError(false);


    try {
      const response = await Axios.post(window.backendUrl + 'query_available_boats', form);
      if (response.status === 200) {
        setResultList(response.data);
        //console.log('********************** available boats:')
        //console.log(response.data);
      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
      }
    }
    catch (error) {
      console.log(error);
      setIsError(true);
    };
    setIsLoading(false);
    navigate('/search');
  });

  useEffect(() => {
    searchPic();
  }, []);

  return (
    <div>
      {!isMobile &&
        <Stack direction='row'        >
          <Box sx={{ width: 400 }}>
            <BoatSearchBar
              direction={'column'}
              withReservationButton={false}
              onSearch={searchPic}
              forcedCharterGuid={forcedCharterGuid}
            />
            <DoYouHaveQuestions />
          </Box>
          <Box>

            {isLoading && !isError &&
              <Paper elevation={5} sx={{ p: 1, m: 1 }}>
                <Alert variant='standard' severity='info'>
                  <FormattedMessage id='alert.data_fetch_pending' />
                </Alert>
              </Paper>
            }
            {isError &&
              <Paper elevation={5} sx={{ p: 1, m: 1 }}><Alert variant='standard' severity='warning'>
                <FormattedMessage id='alert.error_please_retry_later' />
              </Alert>
              </Paper>
            }

            {(resultList.length === 0) && !isLoading && !isError &&
              <Paper elevation={5} sx={{ p: 1, m: 1 }}>
                <Alert variant='standard' severity='info'>
                  <FormattedMessage id='alert.no_matching_boat_found' />
                </Alert>
              </Paper>
            }
            {(resultList.length > 0) && !isLoading && !isError &&
              <div>
                {resultList.map((val) => (<BoatCardSmall key={val.id} hajo={val.hajo} />))}
              </div>
            }
          </Box>
        </Stack>
      }
      {isMobile &&
        <Stack direction='column'>
          <Box >
            <BoatSearchBar
              direction={'column'}
              withReservationButton={false}
              onSearch={searchPic}
              forcedCharterGuid={forcedCharterGuid}
            />

            {isLoading && !isError &&
              <Paper elevation={5} sx={{ p: 1, m: 1 }}>
                <Alert variant='standard' severity='info'>
                  <FormattedMessage id='alert.data_fetch_pending' />
                </Alert>
              </Paper>
            }
            {isError &&
              <Paper elevation={5} sx={{ p: 1, m: 1 }}>
                <Alert variant='standard' severity='warning'>
                  <FormattedMessage id='alert.error_please_retry_later' />
                </Alert>
              </Paper>
            }
            {(resultList.length === 0) && !isLoading && !isError &&
              <Paper elevation={5} sx={{ p: 1, m: 1 }}>
                <Alert variant='standard' severity='info'>
                  <FormattedMessage id='alert.no_matching_boat_found' />
                </Alert>
              </Paper>
            }
            {(resultList.length > 0) && !isLoading && !isError &&
              <div>
                {resultList.map((val) => (<BoatCardSmall key={val.id} hajo={val.hajo} />))}
              </div>
            }
            <DoYouHaveQuestions />
          </Box>
        </Stack>
      }
    </div >
  )
}

export default SearchPage
