import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import RootState from '../types/RootState';
import React, { Component, useState } from 'react';
//import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 500
    },
    body1: {
      fontSize: 14,
      fontWeight: 500
    },
  }
});

const isMobile = window.innerWidth <= 768;

function BoatDetailsFurtherdetails() {
  const intl = useIntl();
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  const boatSearchBarForm = useSelector((state: RootState) => state.boatSearchBarForm);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  return (
    <div>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5}
            sx={{ p: 1, m: 1 }}
          >
            <Typography
              variant="h1"
              paragraph={false}
              sx={{ p: 1 }}>
              <FormattedMessage id='boat.Furtherdetails.Title' />
            </Typography>
          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsFurtherdetails;
