import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Paper, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { isValidName, isValidEmailAddress, isValidPhone, isStrongPassword } from '../tools/Tools'

import RootState from '../types/RootState';
import { useEffect, useState } from 'react';
import { fdTheme, blueButton, yellowButton, whiteStyle } from "../themes/fdTheme";
import Axios from 'axios';

interface FormType {
  lastname: string,
  firstname: string,
  email: string,
}
const emptyForm: FormType = {
  lastname: "",
  firstname: "",
  email: "",
}

function FakeDoorSubscribeNewsletter() {
  const intl = useIntl();
  const [form, setForm] = useState(emptyForm);
  const [validation, setValidation] = useState(emptyForm);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputMade, setInputMade] = useState(false);

  //const [accountCreated, setAccountCreated] = useState(false);
  //const [accountExists, setAccountExists] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  //const [interested, setInterested] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log('handleTextFieldChange');
    setInputMade(true);
    const { name, value } = event.target;
    if (name === 'lastname') {
      setForm({ ...form, lastname: value });
    }
    else if (name === 'firstname') {
      setForm({ ...form, firstname: value });
    }
    else if (name === 'email') {
      setForm({ ...form, email: value });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  }

  const checkValidation = () => {
    setSubmitVisible(true);
    if (inputMade) {
      let errors: FormType = JSON.parse(JSON.stringify(validation));
      if (!form?.lastname?.trim()) {
        errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
        setSubmitVisible(false);
      }
      else if (!isValidName(form.lastname)) {
        errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
        setSubmitVisible(false);
      }
      else {
        errors.lastname = '';
      }

      if (!form?.firstname?.trim()) {
        errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' })
        setSubmitVisible(false);
      }
      else if (!isValidName(form.firstname)) {
        errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' });
        setSubmitVisible(false);
      }
      else {
        errors.firstname = '';
      }

      if (!form?.email?.trim()) {
        errors.email = intl.formatMessage({ id: 'input.validation.email_address_required' })
        setSubmitVisible(false);
      }
      else if (!isValidEmailAddress(form.email)) {
        errors.email = intl.formatMessage({ id: 'input.validation.email_address_format_invalid' })
        setSubmitVisible(false);
      }
      else {
        errors.email = '';
      }
      setValidation(errors);
    }
    else {
      setSubmitVisible(false);
    }
  }

  useEffect(() => {
    checkValidation();
  }, [intl, form]);

  const handleSave = () => {
    resetDisplayFlags();
    createAccount();
    setFormSubmitted(true);
  }

  const resetDisplayFlags = () => {
    setFormSubmitted(false);
    //setAccountCreated(false);
    //setAccountExists(false);
    setShowErrorAlert(false);
  }

  async function createAccount() {
    setSubmitVisible(false);

    try {
      const response = await Axios.post(window.backendUrl + 'save_newsletter_registration', form);
      if (response.status === 200) {
        console.log('Registration successful');
        setFormSubmitted(true);
        setShowErrorAlert(false);
      }
      else {
        console.error('Registration unsuccessful');
        setFormSubmitted(true);
        setShowErrorAlert(true);
      }
    }
    catch (error) {
      console.log(error);
      console.error('Registration unsuccessful');
      setFormSubmitted(true);
      setShowErrorAlert(true);
    };
  };


  const css = `
  .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
  },
`;


  return (

    <div>
      <style>
        {css}
      </style>
      <ThemeProvider theme={fdTheme}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
            m: 0,
            background: 'linear-gradient(88deg, #252AA7 21.5%, #5382E9 52.78%, #FFC700 84.71%)',
          }}
        >
          <Typography sx={{ color: 'white' }} variant="h1" paragraph={true}>
            <FormattedMessage id='frontpage.Subscribe_mailing_list' />
          </Typography>
          <Typography sx={{ color: 'white' }} variant="h2" paragraph={true}>
            <FormattedMessage id='frontpage.Subscribe_mailing_list_be_the_first' />
          </Typography>
          {(formSubmitted && showErrorAlert) &&
            <Alert variant='standard' severity='error' style={{ width: '100%' }}>
              <FormattedMessage id='fakedoor.registration.failure' />
            </Alert>}
          {(formSubmitted && !showErrorAlert) &&
            <Alert variant='standard' severity='success' style={{ width: '100%' }}>
              <FormattedMessage id='fakedoor.registration.successful' />
            </Alert>}

          <Box sx={{
            display: 'flex',
            flexWrap: 'true',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : 'space-between',
            alignItems: 'flex-start',
            p: 1,
            m: 1
          }}>
            <Box sx={{ pr: 1 }}>
              <TextField
                sx={{
                  pb: 3,
                  input: { color: 'white' },
                  label: { color: 'white' },
                  fieldset: { borderColor: "white" }
                }}
                size='small'
                value={form?.lastname}
                required
                label={intl.formatMessage({ id: 'fakedoor.input.lastname' })}
                fullWidth
                name='lastname'
                helperText={validation.lastname}
                onChange={handleTextFieldChange} />
            </Box>
            <Box sx={{ pr: 1 }}>
              <TextField
                sx={{
                  pb: 3,
                  input: { color: 'white' },
                  label: { color: 'white' },
                  fieldset: { borderColor: "white" }
                }}
                size='small'
                value={form?.firstname}
                required
                label={intl.formatMessage({ id: 'fakedoor.input.firstname' })}
                fullWidth
                name='firstname'
                helperText={validation.firstname}
                onChange={handleTextFieldChange} />
            </Box>
            <Box sx={{ pr: 1 }}>
              <TextField
                sx={{
                  pb: 3,
                  input: { color: 'white' },
                  label: { color: 'white' },
                  fieldset: { borderColor: "white" }
                }}
                size='small'
                value={form?.email}
                required
                label={intl.formatMessage({ id: 'fakedoor.input.email' })}
                fullWidth
                name='email'
                helperText={validation.email}
                onChange={handleTextFieldChange} />
            </Box>
            {!formSubmitted &&
              <Box sx={{ pr: 1 }}>
                <ThemeProvider theme={isMobile ? yellowButton : blueButton}>
                  <Button
                    onClick={handleSave}
                    variant='contained'
                    sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
                    disabled={!submitVisible}
                  >
                    <FormattedMessage id='frontpage.Button_Subscribe' />
                  </Button>
                </ThemeProvider>
              </Box>
            }
          </Box>
        </Box>
      </ThemeProvider>
    </div >
  );
}

export default FakeDoorSubscribeNewsletter;
