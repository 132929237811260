import Axios from "axios";

export default async function LoadFaq(store: any) {
  const x = await _LoadFaq();
  console.log('Faq: ',x);
  store.dispatch({ type: 'FaqList', payload: x });
}

async function _LoadFaq() {
  var response;
  try {
    response = await Axios.post(window.backendUrl + 'query_faq');
    if (response.status === 200) {
      //console.log(response.data);
    }
    else {
      console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }
  }
  catch (err) {
    console.error(err);
  }
  finally {
    //console.log('LoadFaq ended');
  }
  return (response?.data);
};



