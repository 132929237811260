import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Button, Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import RootState from '../types/RootState';
import { useNavigate } from 'react-router';
import { useState, useEffect, useCallback } from 'react';
import { displayDateWeekday, displayHandoverTime, displayReturnTime } from '../tools/Tools';
import Axios from 'axios';
import { niceNumber, d2sDotIntl } from '../tools/Tools';


const css = `
    .box {
      background: linear-gradient(90deg,  #252AA7, #5382E9);
      color: white;
      }
  .box2 {
    background: lightgrey;
    color: black;
    }
`;


const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 500
    },
    h2: {
      fontSize: 20,
      fontWeight: 800
    },
    body1: {
      fontSize: 16,
      fontWeight: 800
    },
    body2: {
      fontSize: 16,
      fontWeight: 400
    },
    h3: {
      fontSize: 14,
      fontWeight: 800
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 800,
      color: '#6AB2BC'
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 800,
      color: 'red',
    },
  }
});

const isMobile = window.innerWidth <= 768;

function BoatDetailsFreeslots() {
  //console.log('BoatDetailsFreeslots() called');
  const intl = useIntl();
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  const boatSearchBarForm = useSelector((state: RootState) => state.boatSearchBarForm);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const navigateSearchPage = () => {
    navigate('/search');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [freeslots, setFreeslots] = useState([]);


  const fetchBoatList = useCallback(async () => {
    //console.log('fetchBoatList.callback()');
    setIsLoading(true);
    setError(null);
    const req = {
      //boatId: selectedHajo.id
      boatId: boatSearchBarForm.boatId
    }

    try {
      const response = await Axios.post(window.backendUrl + 'query_hajo_daily_availability', req);
      if (response.status === 200) {
        setFreeslots(response.data);
        console.log(response.data);

      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
    }
    catch (error) {
      console.error(error);
      //setError(error.message);

    };
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchBoatList();
  }, [])





  return (
    <div>
      <style>
        {css}
      </style>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5}
            sx={{ p: 1, m: 1 }}
          >
            <Typography
              variant="h1"
              paragraph={false}
              sx={{ p: 1 }}>
              <FormattedMessage id='boat.Freeslots.Title' />
            </Typography>

            <Box
              className='box'
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: isMobile ? 'flex-start' : 'space-between',
                alignItems: isMobile ? 'flex-start' : 'center',
              }}>
              <Box sx={{ p: 1 }} >
                <Typography variant="h2" paragraph={false}>
                  <FormattedMessage id='reservation_details.boat_handover_date' />

                </Typography>
                <Typography variant="body1" paragraph={false}>
                  {displayDateWeekday(intl, boatSearchBarForm.dateFrom)}
                </Typography>
                <Typography variant="body2" paragraph={false}>
                  {displayHandoverTime(intl, boatSearchBarForm.timeFrom)}
                </Typography>
              </Box>
              <Box sx={{ p: 1 }} >
                <Typography variant="h2" paragraph={false}>
                  <FormattedMessage id='reservation_details.boat_return_date' />
                </Typography>
                <Typography variant="body1" paragraph={false}>
                  {displayDateWeekday(intl, boatSearchBarForm.dateTo)}

                </Typography>
                <Typography variant="body2" paragraph={false}>
                  {displayReturnTime(intl, boatSearchBarForm.timeTo)}

                </Typography>
              </Box>
              <Box sx={{ p: 1 }} >
                <Button
                  variant="contained"
                  sx={{
                    p: 1,
                    textAlign: 'left',
                    textTransform: 'none',
                    border: 0,
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 500,
                    cursor: 'pointer'
                  }}
                  onClick={navigateSearchPage}
                >
                  <FormattedMessage id='button.Change' />
                </Button>
              </Box>
            </Box>

            {freeslots &&
              <Box
                className='box2'
                sx={{
                  p: 1,
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: isMobile ? 'row' : 'row',
                  justifyContent: isMobile ? 'flex-start' : 'space-between',
                  alignItems: isMobile ? 'flex-start' : 'flex-start',
                }}>
                {freeslots.slice(0, 5).map((val: any) => (
                  <Paper className='box2' sx={{ mb: 1 }}>
                    <Box key={val.id} sx={{ m: 2 }} >
                      <Typography variant="h3" paragraph={false}>
                        {d2sDotIntl(intl, val.dateFrom)} - {d2sDotIntl(intl, val.dateTo)}
                      </Typography>

                      {val.isFree ?
                        <Typography variant="subtitle1" paragraph={false}>
                          <FormattedMessage id='rental.Isavailable' />
                        </Typography>
                        :
                        <Typography variant="subtitle2" paragraph={false}>
                          <FormattedMessage id='rental.NotvailableShort' />
                        </Typography>
                      }
                      {val.isFree && Boolean(val.priceFrom) &&
                        <>
                          <Typography variant="subtitle1" paragraph={false}>
                            {niceNumber(intl, val.priceFrom)} {val.currency}
                            &nbsp;<FormattedMessage id='boat.Price_per_day_from' />
                          </Typography>
                          <Typography variant="subtitle1" paragraph={false}>
                            {val.szezonNev} {val.alapKiemelt}
                          </Typography>
                        </>

                      }
                    </Box>
                  </Paper>
                ))}
              </Box>
            }

          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsFreeslots;
