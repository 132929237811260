import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import FormControl from '@mui/material/FormControl';
import Grid, { GridDirection } from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'

import BoatSearchBarFormType from '../types/BoatSearchBarFormType';
import KikotoListType from '../types/KikotoListType';
import KikotoType from '../types/KikotoType';
import KiadoListType from '../types/KiadoListType';
import KiadoType from '../types/KiadoType';

import RootState from '../types/RootState';
import { ResponsiveStyleValue } from "@mui/system";
import { Card, CardActionArea, CardContent, CardHeader, CardMedia, Paper, Slider, Stack, Step, StepContent, StepLabel, Stepper, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { addDays, differenceInCalendarDays, isBefore, isAfter, isSameDay, startOfDay } from 'date-fns';
import UserExtType from "../types/UserExtType";
import HajoType from "../types/HajoType";
import PricingType from "../types/PricingType";
import { d2s, d2sDot } from '../tools/Tools';


export default function PricingDetails() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const selectedHajo: HajoType = useSelector((state: RootState) => state.selectedHajo);
  const currentUser: UserExtType = useSelector((state: RootState) => state.currentUser);
  const boatSearchBarForm: BoatSearchBarFormType = useSelector((state: RootState) => state.boatSearchBarForm);
  const kikotoList: KikotoListType = useSelector((state: RootState) => state.kikotoList);
  const kiadoList: KiadoListType = useSelector((state: RootState) => state.kiadoList);
  const pricing: PricingType = useSelector((state: RootState) => state.pricing);



  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
  }));


  const steps = [
    {
      label: 'Ingyenes lemondás',
      description: `Ingyenes lemondás xxx előtt`,
    },
    {
      label: 'Részben visszatérítendő',
      description: `A lemondási díj xx%-a a tejes árból, ha a foglalását ### után vonja vissza`,
    },
    {
      label: 'Nem visszatérítendő',
      description: `Xxx után a lemondási költség 100%`,
    },
  ];

  return (

    <div>
      <Card raised={true}
        sx={{ width: 250 }}>
        <CardHeader
          title={intl.formatMessage({ id: 'pricing_details.how_much_cancellation_costs' })}
        />
        {selectedHajo &&
          <CardContent>
            <div>
              <Stepper orientation="vertical" nonLinear={true}>
                <Step key={0} expanded={true}>
                  <StepLabel >
                    {d2sDot(pricing?.day_0)}
                  </StepLabel>
                  <StepContent sx={{ backgroundColor: 'lightgray' }}>
                    <Typography gutterBottom={false} variant="body2" sx={{ fontSize: 12, fontWeight: 'bold' }}>
                      <FormattedMessage id='pricing_details.free_cancellation' />
                    </Typography>
                    <Typography gutterBottom={false} variant="body2" sx={{ fontSize: 10 }}>
                      <FormattedMessage id='pricing_details.free_cancellation_date' values={{ date: d2sDot(pricing?.day_1) }} />
                    </Typography>
                  </StepContent>
                </Step>
                <Step key={1} expanded={true}>
                  <StepLabel >
                    {d2sDot(pricing?.day_1)}
                  </StepLabel>
                  <StepContent sx={{ backgroundColor: 'lightgray' }}>
                  <Typography gutterBottom={false} variant="body2" sx={{ fontSize: 12, fontWeight: 'bold' }}>
                      <FormattedMessage id='pricing_details.partly_refundable' />
                    </Typography>
                    <Typography gutterBottom={false} variant="body2" sx={{ fontSize: 10 }}>
                      <FormattedMessage id='pricing_details.partly_refundable_percent_date' values={{ date: d2sDot(pricing?.day_1), percent: 'xx' }} />
                    </Typography>
                  </StepContent>
                </Step>
                <Step key={2} expanded={true}>
                  <StepLabel >
                    {d2sDot(pricing?.day_2)}
                  </StepLabel>
                  <StepContent sx={{ backgroundColor: 'lightgray' }}>
                  <Typography gutterBottom={false} variant="body2" sx={{ fontSize: 12, fontWeight: 'bold' }}>
                      <FormattedMessage id='pricing_details.not_refundable' />
                    </Typography>
                    <Typography gutterBottom={false} variant="body2" sx={{ fontSize: 10 }}>
                      <FormattedMessage id='pricing_details.not_refundable_date' values={{ date: d2sDot(pricing?.day_2)}} />
                    </Typography>
                  </StepContent>
                </Step>
                <Step key={3} expanded={false} last={true}>
                  <StepLabel >
                    {d2sDot(pricing?.day_3)}
                  </StepLabel>
                </Step>

              </Stepper>
            </div>
          </CardContent>
        }
      </Card>
    </div >
  );
}

