import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import Axios from "axios";

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import BoatCard from '../components/BoatCard'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { isValidName, isValidEmailAddress, isValidPhone } from '../tools/Tools'
import CustomerDataInputFormType from '../types/CustomerDataInputFormType';
import RootState from '../types/RootState';
import { List, ListItem, ListItemText } from "@mui/material";
import { DialogAllProps } from "../components/DialogAll";

function CustomerDataInput(props: DialogAllProps) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const form: CustomerDataInputFormType = useSelector((state: RootState) => state.customerDataInputForm);
  const [validation, setValidation] = useState<CustomerDataInputFormType>({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'lastname') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, lastname: value } });
    }
    else if (name === 'firstname') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, firstname: value } });
    }
    else if (name === 'phone') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, phone: value } });
    }
    else if (name === 'email') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, email: value } });
    }
    else if (name === 'comment') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, comment: value } });
    }
    else {
      console.error('ERROR, unknown field name: ' + name);
    }
  }


  const checkValidation = () => {
    setSubmitVisible(true);
    let errors: CustomerDataInputFormType = JSON.parse(JSON.stringify(validation));
    if (!form?.lastname?.trim()) {
      errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
      setSubmitVisible(false);
    }
    else if (!isValidName(form.lastname)) {
      errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
      setSubmitVisible(false);
    }
    else {
      errors.lastname = ' ';
    }

    if (!form?.firstname?.trim()) {
      errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' })
      setSubmitVisible(false);
    }
    else if (!isValidName(form.firstname)) {
      errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' });
      setSubmitVisible(false);
    }
    else {
      errors.firstname = ' ';
    }
    if (!form?.phone?.trim()) {
      errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_required' })
      setSubmitVisible(false);
    }
    else if (!isValidPhone(form.phone)) {
      errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_format_invalid' })
      setSubmitVisible(false);
    }
    else {
      errors.phone = ' ';
    }


    if (!form?.email?.trim()) {
      errors.email = intl.formatMessage({ id: 'input.validation.email_address_required' })
      setSubmitVisible(false);
    }
    else if (!isValidEmailAddress(form.email)) {
      errors.email = intl.formatMessage({ id: 'input.validation.email_address_format_invalid' })
      setSubmitVisible(false);
    }
    else {
      errors.email = ' ';
    }
    setValidation(errors);
  }

  useEffect(() => {
    checkValidation();
  }, [form]);

  const loggedIn = useSelector((state: RootState) => state.currentUser.loggedIn);
  const boatSearchBarForm = useSelector((state: RootState) => state.boatSearchBarForm);

  const handleContinue = () => {
    //  console.log('handleContinue, here the parameters:');
    //  console.log(formValues);
    //dispatch({type: 'UpdateCart', newCart: formValues});    
    navigate('/payment');
  }

  async function fetchHajoHandler() {
    //console.log("fetchHajoHandler");  
    //console.log('Hajo ID is: '+selectedBoatId);  

    try {
      const response = await Axios.post(window.backendUrl + 'query_hajo', boatSearchBarForm);
      if (response.status === 200) {
        //console.log("query hajo done");
        //console.log(response.data.hajo);
        var selectedHajo = response.data.hajo;
        dispatch({ type: 'selectedHajo', payload: selectedHajo });
        dispatch({ type: 'CalcFizetesiUtemezes', payload: null });

        // "selectedHajo" néven találja meg a reducer az 
        // "payload" néven találja meg a reducer az adatot

      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
    }
    catch (error) {
      console.log(error);
      //setError('X: '+error.message);

    };
  };


  useEffect(() => {
    console.log('useEffect called in CustomerDataInput()');
    fetchHajoHandler();
  }, [])

  return (
    <div>
      <Card sx={{ borderStyle: 'dotted', borderWidth: '1px', borderColor: 'lightgrey', borderRadius: 2, padding: 3, alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={1} alternativeLabel>
            <Step key='1'>
              <StepLabel>Hajó kiválasztva</StepLabel>
            </Step>
            <Step key='2'>
              <StepLabel>Adja meg az adatait</StepLabel>
            </Step>
            <Step key='3'>
              <StepLabel>Foglalás véglegesítése</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </Card>
      <br />
      <BoatCard />
      <br></br>

      <List
        dense={true}
        sx={{ maxWidth: '100ch' }}
        disablePadding={true}
      >
        {!loggedIn &&
          <ListItem disableGutters>
            <ListItemText sx={{ textAlign: 'left' }}>
              <FormattedMessage id='customer.please_enter_your_data' />
            </ListItemText>
          </ListItem>
        }
        {loggedIn &&
          <ListItem disableGutters>
            <ListItemText sx={{ textAlign: 'left' }}>
              <FormattedMessage id='customer.please_check_your_data' />
            </ListItemText>
          </ListItem>
        }
        <ListItem disableGutters>
          <TextField
            value={form?.lastname}
            required
            label={intl.formatMessage({ id: 'input.label.lastname' })}
            fullWidth
            name='lastname'
            helperText={validation.lastname}
            error={validation.lastname?.trim() !== ''}
            onChange={handleTextFieldChange} />
        </ListItem>

        <ListItem disableGutters>
          <TextField
            value={form?.firstname}
            required
            label={intl.formatMessage({ id: 'input.label.firstname' })}
            fullWidth
            name='firstname'
            helperText={validation.firstname}
            error={validation.firstname?.trim() !== ''}
            onChange={handleTextFieldChange} />
        </ListItem>

        <ListItem disableGutters>

          <TextField
            value={form?.phone}
            required
            label={intl.formatMessage({ id: 'input.label.phone' })}
            fullWidth
            name='phone'
            helperText={validation.phone}
            error={validation.phone?.trim() !== ''}
            onChange={handleTextFieldChange} />
        </ListItem>

        <ListItem disableGutters>

          <TextField
            value={form?.email}
            required
            label={intl.formatMessage({ id: 'input.label.email' })}
            fullWidth
            name='email'
            helperText={validation.email}
            error={validation.email?.trim() !== ''}
            onChange={handleTextFieldChange} />
        </ListItem>

        <ListItem disableGutters>
          <ListItemText sx={{ textAlign: 'left' }}>
            <FormattedMessage id='customer.please_enter_your_request' />
          </ListItemText>
        </ListItem>

        <ListItem disableGutters>
          <TextField
            value={form?.comment}
            name='comment'
            label="Kérjük, jelezze felénk, ha bármilyen kérése van."
            multiline
            rows={5}
            variant="outlined"
            sx={{width: '100ch' }}
            onChange={handleTextFieldChange}
          />
        </ListItem>

        <ListItem disableGutters>
          <Button
            fullWidth
            variant="contained"
            onClick={() => { handleContinue() }}
            disabled={!submitVisible}
            sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}            
          >
            <FormattedMessage id='button.continue_to_finalize_reservation' />
          </Button>
        </ListItem>
      </List>

    </div>
  )
}

export default CustomerDataInput
