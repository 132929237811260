import React, { useState, useEffect, useCallback, isValidElement, Dispatch } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from "axios";
import { CredentialResponse } from "@react-oauth/google";
import jwt_decode from 'jwt-decode';
import GoogleUserType from "../types/GoogleUserType";
import { Action } from "redux";
import { ProfileSuccessResponse } from "@greatsumini/react-facebook-login";



export default async function SajatFacebookLogin(profileSuccessResponse: ProfileSuccessResponse ) {
  var response: any;
  console.log("Facebook login clicked. ProfileSuccessResponse is:");
  console.log(profileSuccessResponse);
  //console.log("Encoded JWT ID token: " + credentialResponse.credential);
  //const userObject: any = jwt_decode(credentialResponse?.credential!);
  //console.log('userObject:');
  //console.log(userObject);
  //console.log('user name is:');
  //console.log(userObject.name);

  const googleUser: GoogleUserType = {
    name: profileSuccessResponse.name!,
    email: profileSuccessResponse.email!,
    email_verified: false,
    locale: '',
    family_name: profileSuccessResponse.name!.split(' ').slice(-1).join(' '),
    given_name: profileSuccessResponse.name!.split(' ').slice(0, -1).join(' '),
    picture: profileSuccessResponse!.picture!.data!.url!,
    sub: profileSuccessResponse.id!
  }

  console.log("Name: " + googleUser.name);
  console.log("Email: " + googleUser.email);
  console.log("Email verified: " + googleUser.email_verified);
  console.log("Locale: " + googleUser.locale);
  console.log("Family name: " + googleUser.family_name);
  console.log("Given name: " + googleUser.given_name);
  console.log("Picture: " + googleUser.picture);
  console.log("Sub: " + googleUser.sub);
  //    console.log(googleUser);
  //      console.log(tokenResponse);
  console.log("Here comes axios");
  try{
    response=await Axios.post(window.backendUrl+'login_with_google', googleUser);
    console.log("Axio post done login_with_google");
    //console.log(response);
    if(response.status === 200){
      console.log('response.data[0]:');
      console.log(response.data[0]);
    }
    else{
      console.log("Error! Response of Axio post is not 200, but "+response.status+" "+response.statusText);    
    }
  }
  catch(error){
    console.log('error');
    console.log(error);
    //setError(error.message);    
  };
  return(response.data[0]);
}