import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { isValidName, isValidEmailAddress, isValidPhone } from '../tools/Tools'
import CustomerDataInputFormType from '../types/CustomerDataInputFormType';
import RootState from '../types/RootState';
import { CardContent, Checkbox, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemText } from "@mui/material";
import BoatSearchBarFormType from "../types/BoatSearchBarFormType";

const css = `
.MuiInputLabel-root {
  font-size: 12px !important;
}
.MuiInputLabel-shrink {
  font-size: 12px !important;
}
.MuiInputBase-root {
  font-size: 14px !important;
}

`

function SingleCustomerDataInput(props: any) {

  const dispatch = useDispatch();
  const intl = useIntl();
  const form: CustomerDataInputFormType = useSelector((state: RootState) => state.customerDataInputForm);
  const boatSearchBarForm: BoatSearchBarFormType = useSelector((state: RootState) => state.boatSearchBarForm);
  const [validation, setValidation] = useState<CustomerDataInputFormType>({});
  const [submitVisible, setSubmitVisible] = useState(false);

  const handleSkipperChange = () => {
    dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...boatSearchBarForm, withSkipper: !boatSearchBarForm.withSkipper } });
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'lastname') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, lastname: value } });
    }
    else if (name === 'firstname') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, firstname: value } });
    }
    else if (name === 'phone') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, phone: value } });
    }
    else if (name === 'email') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, email: value } });
    }
    else if (name === 'comment') {
      dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, comment: value } });
    }
    else {
      console.error('ERROR, unknown field name: ' + name);
    }
  }


  const checkValidation = () => {
    setSubmitVisible(true);
    var isValid: boolean = true;
    let errors: CustomerDataInputFormType = JSON.parse(JSON.stringify(validation));
    if (!form?.lastname?.trim()) {
      errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
      setSubmitVisible(false);
      isValid = false;
    }
    else if (!isValidName(form.lastname)) {
      errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
      setSubmitVisible(false);
      isValid = false;
    }
    else {
      errors.lastname = ' ';
    }

    if (!form?.firstname?.trim()) {
      errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' })
      setSubmitVisible(false);
      isValid = false;
    }
    else if (!isValidName(form.firstname)) {
      errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' });
      setSubmitVisible(false);
      isValid = false;
    }
    else {
      errors.firstname = ' ';
    }
    if (!form?.phone?.trim()) {
      errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_required' })
      setSubmitVisible(false);
      isValid = false;
    }
    else if (!isValidPhone(form.phone)) {
      errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_format_invalid' })
      setSubmitVisible(false);
      isValid = false;
    }
    else {
      errors.phone = ' ';
    }

    if (!form?.email?.trim()) {
      errors.email = intl.formatMessage({ id: 'input.validation.email_address_required' })
      setSubmitVisible(false);
      isValid = false;
    }
    else if (!isValidEmailAddress(form.email)) {
      errors.email = intl.formatMessage({ id: 'input.validation.email_address_format_invalid' })
      setSubmitVisible(false);
      isValid = false;
    }
    else {
      errors.email = ' ';
    }
    setValidation(errors);
    dispatch({ type: 'CustomerDataInputFormValues', customerDataInputForm: { ...form, isValid: isValid } });
  }

  useEffect(() => {
    checkValidation();
    // ha a dependency array-be beteszem az egesz form-ot, akkor végtelen ciklusba kerül az useEffect miatt
  }, [form.email, form.lastname, form.firstname, form.phone]);

  return (
    <div>
      <style>
        {css}
      </style>
      <Card sx={{ m: 0, p: 0, border: 'none', boxShadow: "none" }}>
        <CardContent sx={{ m: 0, p: 0 }} >
          <Grid
            container
            justifyContent="space-around"
            alignItems="flex-start"
          >
            <Grid item maxWidth={'100%'}>
              <div>
                <List
                  dense={true}
                  sx={{ maxWidth: '50ch', m: 0, p: 0 }}
                  disablePadding={true}
                >
                  <ListItem disableGutters>
                    <ListItemText sx={{ textAlign: 'left' }}>
                      <FormattedMessage id='customer.please_enter_your_data_for_reservation' />
                    </ListItemText>
                  </ListItem>
                  <ListItem disableGutters sx={{ m: 0, p: 0 }}>
                    <TextField sx={{ m: 0, p: 0 }}
                      value={form?.lastname}
                      required
                      label={intl.formatMessage({ id: 'input.label.lastname' })}
                      fullWidth
                      name='lastname'
                      onChange={handleTextFieldChange}
                      size='small'
                      variant='outlined'
                      margin='dense'
                      error={validation.lastname?.trim() !== ''}
                      inputProps={{
                        style: {
                          padding: '3px 10px'
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem disableGutters sx={{ m: 0, p: 0 }}>
                    <TextField
                      value={form?.firstname}
                      required
                      label={intl.formatMessage({ id: 'input.label.firstname' })}
                      fullWidth
                      name='firstname'
                      onChange={handleTextFieldChange}
                      size='small'
                      variant='outlined'
                      margin='dense'
                      error={validation.firstname?.trim() !== ''}
                      inputProps={{
                        style: {
                          padding: '3px 10px'
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem disableGutters sx={{ m: 0, p: 0 }}>
                    <TextField
                      value={form?.phone}
                      required
                      label={intl.formatMessage({ id: 'input.label.phone' })}
                      fullWidth
                      name='phone'
                      onChange={handleTextFieldChange}
                      size='small'
                      variant='outlined'
                      margin='dense'
                      error={validation.phone?.trim() !== ''}
                      inputProps={{
                        style: {
                          padding: '3px 10px'
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem disableGutters sx={{ m: 0, p: 0 }}>
                    <TextField
                      value={form?.email}
                      required
                      label={intl.formatMessage({ id: 'input.label.email' })}
                      fullWidth
                      name='email'
                      onChange={handleTextFieldChange}
                      size='small'
                      variant='outlined'
                      margin='dense'
                      error={validation.email?.trim() !== ''}
                      inputProps={{
                        style: {
                          padding: '3px 10px'
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem disableGutters sx={{ m: 0, p: 0 }}>
                    <TextField
                      value={form?.comment}
                      name='comment'
                      label="Kérjük, jelezze felénk, ha bármilyen kérése van."
                      multiline
                      rows={3}
                      sx={{ width: '100ch' }}
                      onChange={handleTextFieldChange}
                      size='small'
                      variant="outlined"
                      margin='dense'
                    />
                  </ListItem>
                  <ListItem disableGutters sx={{ m: 0, p: 0 }}>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox size="small"
                        checked={boatSearchBarForm.withSkipper}
                        onChange={handleSkipperChange}
                      />}
                        label={<FormattedMessage id='singlepage.reservation_with_skipper' />}
                      />
                    </FormGroup>
                  </ListItem>
                </List>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default SingleCustomerDataInput
