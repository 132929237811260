import { createTheme } from "@mui/material";

const documentTheme = createTheme({

  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    h2: {
      fontSize: 20,
      fontWeight: 400
    },
    h3: {
      fontSize: 16,
      fontWeight: 800
    },
    h4: {
      fontSize: 120,
      fontWeight: 400
    },
    h5: {
      fontSize: 11,
      fontWeight: 800
    },
    h6: {
      fontSize: 10,
      fontWeight: 400
    },
    body1: {
      fontSize: 12,
      fontWeight: 400
    },
    
  },

});

export {documentTheme};