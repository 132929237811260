import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import AnchorIcon from '@mui/icons-material/Anchor';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import ChatIcon from '@mui/icons-material/Chat';
import CookieIcon from '@mui/icons-material/Cookie';
import EmailIcon from '@mui/icons-material/Email';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HomeIcon from '@mui/icons-material/Home';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import InfoIcon from '@mui/icons-material/Info';
import LanguageIcon from '@mui/icons-material/Language';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LoginIcon from '@mui/icons-material/Login';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MoneyIcon from '@mui/icons-material/Money';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SailingIcon from '@mui/icons-material/Sailing';
import SendIcon from '@mui/icons-material/Send';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import { Slide, ThemeProvider, formHelperTextClasses, useScrollTrigger } from "@mui/material";

import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

import Divider from '@mui/material/Divider';
import { useSelector, useDispatch, ReactReduxContext } from 'react-redux';
import { FormattedMessage } from 'react-intl'
import RootState from '../types/RootState';
import { MixedLoginProps } from '../components/DialogMixedLogin';
import { DialogAllProps } from '../components/DialogAll';
import { useEffect, useState } from 'react';

const navBarTheme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    body1: {
      fontSize: 16,
      fontWeight: 800
    },
    body2: {
      fontSize: 16,
      fontWeight: 400
    },
  },
  palette: {
    primary: {
      main: '#252AA7',
    },

  },
}
);


interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

export function Navbar(props: DialogAllProps) {
  const dispatch = useDispatch();

  const loggedIn = useSelector((state: RootState) => state.currentUser.loggedIn);
  const database = useSelector((state: RootState) => state.database);
  const picture = useSelector((state: RootState) => state.currentUser.ux_picture);
  const given_name = useSelector((state: RootState) => state.currentUser.ux_email);
  const cart_guid = useSelector((state: RootState) => state.boatSearchBarForm.quoteGuid);
  const selectedBoatId = useSelector((state: RootState) => state.boatSearchBarForm.boatId);
  const forcedCharterGuid: string = useSelector((state: RootState) => state.forcedCharterGuid);
  const kiadoList = useSelector((state: RootState) => state.kiadoList);
  var forcedCharterName: string
  // const forcedCharterName: string = forcedCharterGuid;
  //console.log('kiadoList', kiadoList);
  if (kiadoList.length > 0) {
    forcedCharterName = kiadoList?.find(element => element.guid === forcedCharterGuid)?.nev ?? '';
  }
  else {
    forcedCharterName = '*';
  }

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorServicesMenuEl, setAnchorServicesMenuEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isServicesMenuOpen = Boolean(anchorServicesMenuEl);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleServicesMenuClose = () => {
    setAnchorServicesMenuEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const [anchorHamEl, setAnchorHamEl] = React.useState(null);
  const openHam = Boolean(anchorHamEl);
  const handleHamClick = (event: any) => {
    setAnchorHamEl(event.currentTarget);
  };

  const handleHamClose = () => {
    setAnchorHamEl(null);
  };


  const handleSelectLanguageHU = () => {
    dispatch({ type: 'selectLanguageHU' });
    setAnchorHamEl(null);
  };
  const handleSelectLanguageEN = () => {
    dispatch({ type: 'selectLanguageEN' });
    setAnchorHamEl(null);
  };
  const handleSelectLanguageHR = () => {
    dispatch({ type: 'selectLanguageHR' });
    setAnchorHamEl(null);
  };


  const navigateHome = () => {
    setAnchorHamEl(null);
    navigate('/');
  };
  const navigateAbout = () => {
    setAnchorHamEl(null);
    navigate('/about');
  };
  const navigateContact = () => {
    setAnchorHamEl(null);
    navigate('/contact');
  };
  const navigateTermsAndConditions = () => {
    setAnchorHamEl(null);
    navigate('/termsandconditions');
  };
  const navigateCookiePolicies = () => {
    setAnchorHamEl(null);
    navigate('/cookiepolicies');
  };
  const navigateSearchPage = () => {
    setAnchorHamEl(null);
    navigate('/');
  };
  const navigateLogin = () => {
    setAnchorHamEl(null);
    handleMenuClose();
    props.onMixedLoginClicked();
  };
  const navigateLogout = () => {
    setAnchorHamEl(null);
    handleMenuClose();
    dispatch({ type: 'LogoutNow', null: null });
    navigate('/');
  };
  const navigateRegister = () => {
    setAnchorHamEl(null);
    handleMenuClose();
    props.onRegisterClicked();
  };

  const navigateMyProfile = () => {
    setAnchorHamEl(null);
    handleMenuClose();
    props.onUpdateProfile();
  };

  const navigateMyAccount = () => {
    setAnchorHamEl(null);
    handleMenuClose();
    props.onUpdateAccount();
  };
  const navigateFaqPage = () => {
    console.log('navigateFaqPage()');
    setAnchorHamEl(null);
    navigate('/faq');
  };
  const navigateProfileReservationsPage = () => {
    console.log('navigateProfileReservationsPage()');
    setAnchorHamEl(null);
    navigate('/profilereservations');
  };



  const menuId = 'top-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      {!loggedIn &&
        <MenuItem onClick={navigateLogin}>
          <ListItemIcon>
            <LoginIcon color='primary' />
          </ListItemIcon>
          <ListItemText><FormattedMessage id='hamburger.Login' /></ListItemText>
        </MenuItem>
      }
      {!loggedIn &&
        <MenuItem onClick={navigateRegister}>
          <ListItemIcon>
            <HowToRegIcon color='primary' />
          </ListItemIcon>
          <ListItemText><FormattedMessage id='hamburger.Pleaseregister' /></ListItemText>
        </MenuItem>
      }
      {loggedIn &&
        <MenuItem onClick={navigateLogout}>
          <ListItemIcon>
            <LoginIcon color='primary' />
          </ListItemIcon>
          <ListItemText><FormattedMessage id='hamburger.Logout' /></ListItemText>
        </MenuItem>
      }
      {loggedIn &&
        <MenuItem onClick={navigateMyProfile}>
          <ListItemIcon>
            <HowToRegIcon color='primary' />
          </ListItemIcon>
          <ListItemText><FormattedMessage id='hamburger.Myprofile' /></ListItemText>
        </MenuItem>
      }
      {loggedIn &&
        <MenuItem onClick={navigateMyAccount}>
          <ListItemIcon>
            <ManageAccountsIcon color='primary' />
          </ListItemIcon>
          <ListItemText><FormattedMessage id='hamburger.Myaccount' /></ListItemText>
        </MenuItem>
      }




    </Menu>
  );



  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton >
          <AccountCircle color='primary' />
        </IconButton>
        <FormattedMessage id='hamburgerDivider.MYACCOUNT' />
      </MenuItem>
    </Menu>
  );

  function HideOnScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }


  return (
    <div>
      <ThemeProvider theme={navBarTheme}>
        <CssBaseline />
        <HideOnScroll {...props}>
          <AppBar position='fixed'>
            <Toolbar sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>


              <Box sx={{ display: { xs: 'flex' } }}>
                {forcedCharterGuid &&
                  <AnchorIcon
                    fontSize='large'
                    onClick={navigateSearchPage}
                    sx={{ cursor: 'pointer' }}
                  />

                }
                {forcedCharterGuid &&
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ display: { xs: 'none', sm: 'block' }, cursor: 'pointer' }}
                    onClick={navigateSearchPage}
                  >
                    {forcedCharterName}
                  </Typography>
                }

                {!forcedCharterGuid &&
                  <img src={'https://vizre.hu/media/logo-blue-white.png'}
                    style={{ width: 130, cursor: 'pointer', paddingBottom: '10px' }}
                    onClick={navigateSearchPage}
                  />
                }
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Box
                  sx={{ pl: 2, pr: 2, cursor: 'pointer' }}
                  onClick={navigateHome}
                >
                  <Typography variant='body1'>
                    <FormattedMessage id='navbar.Services' />
                  </Typography>
                </Box>

                <Box
                  sx={{ pr: 2, cursor: 'pointer' }}
                  onClick={navigateHome}
                >
                  <Typography variant='body1'>
                    <FormattedMessage id='navbar.Getaquote' />
                  </Typography>
                </Box>
                <Box
                  sx={{ pr: 2, cursor: 'pointer' }}
                  onClick={navigateContact}
                >
                  <Typography variant='body1'>
                    <FormattedMessage id='navbar.Contact' />
                  </Typography>
                </Box>
                <Box
                  sx={{ pr: 2, cursor: 'pointer' }}
                  onClick={navigateFaqPage}
                >
                  <Typography variant='body1'>
                    <FormattedMessage id='navbar.Faq' />
                  </Typography>
                </Box>
                <Box
                  sx={{ pr: 2, cursor: 'pointer' }}
                  onClick={navigateProfileReservationsPage}
                >
                  <Typography variant='body1'>
                    <FormattedMessage id='navbar.profile_reservations' />
                  </Typography>

                </Box>

              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                <Box
                  sx={{ pl: 2, pr: 2, cursor: 'pointer' }}
                  onClick={navigateLogin}
                >
                  <Typography variant='body2'>
                    <FormattedMessage id='navbar.Login' />
                  </Typography>
                </Box>

                <Box
                  sx={{ pr: 2, cursor: 'pointer' }}
                  onClick={navigateProfileReservationsPage}
                >
                  <Typography variant='body2'>
                    <FormattedMessage id='navbar.Cart' />
                  </Typography>
                </Box>
              </Box>


              <Box sx={{ flexGrow: 1 }} />


              <img src={picture}
                style={{ width: 50 }}
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                {given_name} {selectedBoatId ?? 0} {cart_guid}
              </Typography>





              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                sx={{ mr: 2 }}
                id="basic-button"
                onClick={handleHamClick}
              >
                <MenuIcon />
              </IconButton>


              <Menu
                anchorEl={anchorServicesMenuEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id={'services-menu'}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={isServicesMenuOpen}
                onClose={handleServicesMenuClose}
              >

                {!loggedIn &&
                  <MenuItem onClick={navigateLogin}>
                    <ListItemIcon>
                      <LoginIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Login' /></ListItemText>
                  </MenuItem>
                }
                {!loggedIn &&
                  <MenuItem onClick={navigateRegister}>
                    <ListItemIcon>
                      <HowToRegIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Pleaseregister' /></ListItemText>
                  </MenuItem>
                }
                {loggedIn &&
                  <MenuItem onClick={navigateLogout}>
                    <ListItemIcon>
                      <LoginIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Logout' /></ListItemText>
                  </MenuItem>
                }
                {loggedIn &&
                  <MenuItem onClick={navigateMyProfile}>
                    <ListItemIcon>
                      <HowToRegIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Myprofile' /></ListItemText>
                  </MenuItem>
                }
                {loggedIn &&
                  <MenuItem onClick={navigateMyAccount}>
                    <ListItemIcon>
                      <ManageAccountsIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Myaccount' /></ListItemText>
                  </MenuItem>
                }




              </Menu>



              <Menu
                id="basic-menu"
                anchorEl={anchorHamEl}
                open={openHam}
                onClose={handleHamClose}
              >
                <MenuList dense >

                  <MenuItem onClick={navigateHome}>
                    <ListItemIcon>
                      <HomeIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Mainmenu' /></ListItemText>
                  </MenuItem>
                  <MenuItem onClick={navigateFaqPage}>
                    <ListItemIcon>
                      <HomeIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='navbar.Faq' /></ListItemText>
                  </MenuItem>

                  <Divider textAlign="left" variant='inset'>
                    <FormattedMessage id='hamburgerDivider.SETTINGS' />
                  </Divider>

                  <MenuItem onClick={handleSelectLanguageHU}>
                    <ListItemIcon>
                      <LanguageIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Language' /></ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      <FormattedMessage id='hamburger.hungarian' />
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleSelectLanguageHR}>
                    <ListItemIcon>
                      <LanguageIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Language' /></ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      <FormattedMessage id='hamburger.croatian' />
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleSelectLanguageEN}>
                    <ListItemIcon>
                      <LanguageIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Language' /></ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      <FormattedMessage id='hamburger.english' />
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <MoneyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Currency' /></ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      HUF
                    </Typography>
                  </MenuItem>

                  <Divider textAlign="left" variant='inset'>
                    <FormattedMessage id='hamburgerDivider.CONTACT' />
                  </Divider>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <ChatIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Livechat' /></ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <PhoneInTalkIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>+36 1 234 5678</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <EmailIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>info@vizre.hu</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <WhatsAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>WhatsApp</ListItemText>
                  </MenuItem>

                  <Divider textAlign="left" variant='inset'>
                    <FormattedMessage id='hamburgerDivider.MYACCOUNT' />
                  </Divider>
                  {!loggedIn &&
                    <MenuItem onClick={navigateLogin}>
                      <ListItemIcon>
                        <LoginIcon color='primary' />
                      </ListItemIcon>
                      <ListItemText><FormattedMessage id='hamburger.Login' /></ListItemText>
                    </MenuItem>
                  }
                  {!loggedIn &&
                    <MenuItem onClick={navigateRegister}>
                      <ListItemIcon>
                        <HowToRegIcon color='primary' />
                      </ListItemIcon>
                      <ListItemText><FormattedMessage id='hamburger.Pleaseregister' /></ListItemText>
                    </MenuItem>
                  }
                  {loggedIn &&
                    <MenuItem onClick={navigateLogout}>
                      <ListItemIcon>
                        <LoginIcon color='primary' />
                      </ListItemIcon>
                      <ListItemText><FormattedMessage id='hamburger.Logout' /></ListItemText>
                    </MenuItem>
                  }
                  {loggedIn &&
                    <MenuItem onClick={navigateMyProfile}>
                      <ListItemIcon>
                        <HowToRegIcon color='primary' />
                      </ListItemIcon>
                      <ListItemText><FormattedMessage id='hamburger.Myprofile' /></ListItemText>
                    </MenuItem>
                  }
                  {loggedIn &&
                    <MenuItem onClick={navigateMyAccount}>
                      <ListItemIcon>
                        <ManageAccountsIcon color='primary' />
                      </ListItemIcon>
                      <ListItemText><FormattedMessage id='hamburger.Myaccount' /></ListItemText>
                    </MenuItem>
                  }
                  <Divider textAlign="left" variant='inset'>
                    <FormattedMessage id='hamburgerDivider.INFORMATION' />
                  </Divider>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <LoyaltyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Loyalityprogram' /></ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <InfoIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Howreservationworks' /></ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <RateReviewIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Customerfeedback' /></ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>
                      <SendIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Customerfeedback' /></ListItemText>
                  </MenuItem>

                  <MenuItem onClick={navigateContact}>
                    <ListItemIcon>
                      <SailingIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Contact' /></ListItemText>
                  </MenuItem>

                  <MenuItem onClick={navigateTermsAndConditions}>
                    <ListItemIcon>
                      <HandshakeIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Termsandconditions' /></ListItemText>
                  </MenuItem>

                  <MenuItem onClick={navigateCookiePolicies}>
                    <ListItemIcon>
                      <CookieIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Dataprotectioncookie' /></ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleHamClose}>
                    <ListItemIcon>

                      <SailingIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><FormattedMessage id='hamburger.Pleaseregisteryourboat!' /></ListItemText>
                  </MenuItem>

                </MenuList>

              </Menu>
              {database === 'DEV' &&
                <Box sx={{ backgroundColor: 'yellow', color: 'black', justifySelf: 'center', mr: 2 }}>
                  {database}
                </Box>
              }
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Toolbar />          
        {false && renderMenu}
        {false && renderMobileMenu}
      </ThemeProvider>
    </div >
  );
}
