
import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { FormattedMessage } from 'react-intl'

import { Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import Service from './Service';
import { ServiceType } from '../types/DatabaseTypes';
import RootState from '../types/RootState';

import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    body1: {
      fontSize: 16,
      fontWeight: 800
    },
    body2: {
      fontSize: 16,
      fontWeight: 500
    },
  },
});


function ServicesAll() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const serviceList = useSelector((state: RootState) => state.serviceList);
  const language = useSelector((state: RootState) => state.language);
  useEffect(() => {
    //console.log('Effect ran due to changes in  language or serviceList');
    //console.log(serviceList);
  }, [language, serviceList]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div id='services_root'>        
        <Typography variant="h1" sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
          <FormattedMessage id='services.services' />
        </Typography>
        {serviceList && serviceList.length &&
          <Grid container sx={{ justifyContent: 'center'}}>
            {
              serviceList.map((item: ServiceType) => (
                item.lng === (language ?? 'hu') && (
                  <Grid  sx={{ m:1 }}>
                    <Service {...item} />
                  </Grid>
                )
              )
              )}
          </Grid>
        }
      </div>
    </ThemeProvider>
  );

}

export default ServicesAll;
