import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import FormControl from '@mui/material/FormControl';
import Grid, { GridDirection } from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'

import BoatSearchBarFormType from '../types/BoatSearchBarFormType';
import KikotoListType from '../types/KikotoListType';
import KikotoType from '../types/KikotoType';
import KiadoListType from '../types/KiadoListType';
import KiadoType from '../types/KiadoType';

import RootState from '../types/RootState';
import { ResponsiveStyleValue } from "@mui/system";
import { Paper, Slider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { grey } from "@mui/material/colors";
import { addDays, differenceInCalendarDays, isBefore, isAfter, isSameDay, startOfDay } from 'date-fns';
import UserExtType from "../types/UserExtType";

export interface SearchBarProps {
  direction: ResponsiveStyleValue<GridDirection>,
  withReservationButton: boolean,
  forcedCharterGuid: string,
  onReservation: () => void;
  onSearch?: () => void;
}

interface ErrorType {
  dateFrom: string,
  dateTo: string,

}
const emptyError: ErrorType = {
  dateFrom: '',
  dateTo: '',
}

function d2s(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + String(date.getMonth() + 1)).slice(-2);
  const day = ('0' + String(date.getDate())).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}


export default function BoatSearchBar(props: SearchBarProps) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentUser: UserExtType = useSelector((state: RootState) => state.currentUser);
  const form: BoatSearchBarFormType = useSelector((state: RootState) => state.boatSearchBarForm);
  const kikotoList: KikotoListType = useSelector((state: RootState) => state.kikotoList);
  const kiadoList: KiadoListType = useSelector((state: RootState) => state.kiadoList);
  const [validation, setValidation] = useState<ErrorType>(emptyError);
  const [submitVisible, setSubmitVisible] = useState(false);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'dateFrom') {
      dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...form, dateFrom: value } });
    }
    else if (name === 'dateTo') {
      dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...form, dateTo: value } });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  }
  const handleSelectChange = (event: SelectChangeEvent) => {
    console.log(event.target);
    const { name, value } = event.target;
    if (name === 'boatType') {
      dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...form, boatType: value } });
    }
    else if (name === 'destination') {
      dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...form, destination: value } });
    }
    else if (name === 'charter') {
      dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...form, charter: value } });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  };

  const checkValidation = () => {
    setSubmitVisible(true);
    let errors: ErrorType = JSON.parse(JSON.stringify(validation));
    //const dateToday: Date =new Date(new Date().toISOString().substring(0,10));
    const now: Date = new Date();
    //console.log('Date today: ',dateToday);
    //console.log('form?.dateFrom: ',form?.dateFrom);
    if (form.reservationDurationType === 'O') {
      if (isBefore(startOfDay(form?.dateFrom), startOfDay(now))) {
        //console.log(dateToday);
        errors.dateFrom = intl.formatMessage({ id: 'input.validation.date_input_error' });
      }
      else {
        errors.dateFrom = '';
      }
    }
    else {
      //console.log('Now: ',startOfDay(now));
      //console.log('From: ',startOfDay(form.dateFrom));
      //console.log('To: ',startOfDay(form.dateTo));
      if (isBefore(startOfDay(form?.dateFrom), startOfDay(now))) {
        errors.dateFrom = intl.formatMessage({ id: 'input.validation.date_input_error' });
      }
      else if (isBefore(startOfDay(form?.dateTo), startOfDay(now))) {
        //console.log(dateToday);
        errors.dateTo = intl.formatMessage({ id: 'input.validation.date_input_error' });
      }
      else if (isBefore(startOfDay(form?.dateTo), startOfDay(form?.dateFrom))) {
        errors.dateFrom = intl.formatMessage({ id: 'input.validation.date_input_error' });
        setSubmitVisible(false);
      }
      else {
        errors.dateFrom = '';
        errors.dateTo = '';
      }
    }

    setValidation(errors);
  }

  useEffect(() => {
    checkValidation();
  }, []);

  useEffect(() => {
    checkValidation();
  }, [form]);


  const handleSearch = () => {
    if (props.onSearch) {
      props.onSearch();
    }
  };
  const minDistance = 1;
  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    var valFrom;
    var valTo;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 20 - minDistance);
        valFrom = clamped;
        valTo = clamped + minDistance;
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        valFrom = clamped - minDistance;
        valTo = clamped;
      }
    } else {
      valFrom = newValue[0];
      valTo = newValue[1];
    }
    if (activeThumb === 0) {
      dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...form, timeFrom: valFrom } });
    }
    else if (activeThumb === 1) {
      dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...form, timeTo: valTo } });
    }

  };

  const marks = [
    {
      value: 8,
      label: '8:00',
    },
    {
      value: 12,
      label: '12:00',
    },
    {
      value: 16,
      label: '16:00',
    },
    {
      value: 20,
      label: '20:00',
    },
  ];

  const handleTypeChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView) {
      dispatch({ type: 'BoatSearchBarFormValues', boatSearchBarForm: { ...form, reservationDurationType: nextView } });
    }
  };

  return (
    <Paper elevation={5} variant='elevation'sx={{p:1, m:1}}>
      <Grid
        container
        direction={props.direction}
        spacing={1}
        justifyContent='center'
        alignItems='center'
      >
        {props.withReservationButton &&
          <Grid item >
            <Box sx={{ minWidth: 220 }}>
              <Button variant="contained" onClick={props.onReservation}>
                <FormattedMessage id='button.Reservation' />
              </Button>
            </Box>
          </Grid>
        }
        {!props.forcedCharterGuid && currentUser?.loggedIn && currentUser?.is_admin &&
          < Grid item >
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel><FormattedMessage id='select.charter.title' /></InputLabel>
                <Select
                  name='charter'
                  value={form?.charter}
                  onChange={handleSelectChange}
                  label={<FormattedMessage id='select.charter.title' />}
                >
                  {kiadoList.length > 0 &&
                    <MenuItem value={'charter_*'}><FormattedMessage id='select.charter.all' /></MenuItem>
                  }
                  {kiadoList.length > 0 &&
                    kiadoList.map((val: KiadoType) => (
                      <MenuItem key={val.id} value={'charter_' + val.guid}> {val.nev}</MenuItem>
                    ))
                  }

                </Select>
              </FormControl>
            </Box>
          </Grid>
        }
        {!props.forcedCharterGuid &&
          < Grid item >
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel><FormattedMessage id='select.destination.title' /></InputLabel>
                <Select
                  name='destination'
                  value={form?.destination}
                  onChange={handleSelectChange}
                  label={<FormattedMessage id='select.destination.title' />}
                >
                  {kikotoList.length > 0 &&
                    <MenuItem value={'city_0'}><FormattedMessage id='select.destination.city_all' /></MenuItem>
                  }
                  {kikotoList.length > 0 &&
                    kikotoList.map((val: KikotoType) => (
                      <MenuItem key={val.id} value={'city_' + val.id}> {val.telepules}</MenuItem>
                    ))
                  }

                  {kikotoList.length === 0 &&
                    <>
                      <ListSubheader><FormattedMessage id='select.destination.port' /></ListSubheader>
                      <MenuItem value={"p_1"}>Zamárdi vitorláskikötő</MenuItem>
                      <MenuItem value={"p_2"}>Siófok – Siófok Ezüstpart VE</MenuItem>
                      <MenuItem value={"p_3"}>Balatonlelle – BH Vitorláskikötő</MenuItem>
                      <MenuItem value={"p_4"}>Siófok – Stefánia vitorláskikötő BH Zrt.</MenuItem>
                      <MenuItem value={"p_5"}>Füred Marina</MenuItem>
                      <ListSubheader><FormattedMessage id='select.destination.region' /></ListSubheader>
                      <MenuItem value={"r_balaton"}><FormattedMessage id='region.Balaton' /></MenuItem>
                      <MenuItem value={"r_tiszato"}><FormattedMessage id='region.Tiszato' /></MenuItem>
                      <MenuItem value={"r_fertoto"}><FormattedMessage id='region.Fertoto' /></MenuItem>
                      <ListSubheader><FormattedMessage id='select.destination.country' /></ListSubheader>
                      <MenuItem value={"c_hungary"}><FormattedMessage id='country.Hungary' /></MenuItem>
                      <MenuItem value={"c_austria"}><FormattedMessage id='country.Austria' /></MenuItem>
                      <MenuItem value={"c_slovakia"}><FormattedMessage id='country.Slovakia' /></MenuItem>
                    </>
                  }
                </Select>
              </FormControl>
            </Box>
          </Grid>
        }
        <Grid item
        >
          <Box sx={{
            border: 0,
            py: 0,
            px: 0,
            justifyContent: 'center',
          }}>
            <FormControl fullWidth>
              <ToggleButtonGroup
                sx={{
                  justifyContent: 'right',
                  px: 0,
                  mx: 0

                }}
                orientation="horizontal"
                value={form.reservationDurationType}
                exclusive
                onChange={handleTypeChange}
                size="small"
              >
                <ToggleButton value="N" sx={{
                  textTransform: 'None',
                  fontSize: 11,
                  width: 110
                }}>
                  <CalendarMonthIcon />
                  <FormattedMessage id='reservationDurationType.daily.line1' />
                  <br></br>
                  <FormattedMessage id='reservationDurationType.daily.line2' />
                </ToggleButton>
                <ToggleButton value="O" sx={{
                  textTransform: 'None',
                  fontSize: 11,
                  width: 110
                }}>
                  <AccessAlarmIcon />
                  <FormattedMessage id='reservationDurationType.hourly.line1' />
                  <br></br>
                  <FormattedMessage id='reservationDurationType.hourly.line2' />
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Box>

        </Grid>

        {form.reservationDurationType === 'O' &&
          <>
            <Grid item>
              <Box sx={{ minWidth: 220 }}>
                <TextField
                  value={d2s(form?.dateFrom)}
                  label={<FormattedMessage id='datepicker.Date' />}
                  type='date'
                  name='dateFrom'
                  helperText={validation.dateFrom}
                  sx={{ width: 220 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleTextFieldChange} />
              </Box>
            </Grid>
            <Grid item >
              <Box sx={{
                minWidth: 170,
                border: 1,
                borderColor: 'grey.400',
                borderRadius: 1,
                py: 0.3,
                px: 3
              }}>
                <FormControl fullWidth>
                  <Slider
                    value={[form?.timeFrom, form?.timeTo]}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    disableSwap
                    defaultValue={10}
                    step={1}
                    min={8}
                    max={20}
                    marks={marks}
                    size="small"
                    sx={{
                      "& .MuiSlider-markLabel": {
                        fontSize: "10px",
                        fontWeight: "lighter",
                      }
                    }} />
                </FormControl>
              </Box>
            </Grid>
          </>
        }

        {form.reservationDurationType !== 'O' &&
          <><Grid item>
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <TextField
                  value={d2s(form?.dateFrom)}
                  label={<FormattedMessage id='datepicker.Datefrom' />}
                  type='date'
                  name='dateFrom'
                  helperText={validation.dateFrom}
                  sx={{ width: 220 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleTextFieldChange} />
              </FormControl>
            </Box>
          </Grid>
            <Grid item>
              <Box sx={{ minWidth: 220 }}>
                <FormControl fullWidth>
                  <TextField
                    value={d2s(form?.dateTo)}
                    label={<FormattedMessage id='datepicker.Dateto' />}
                    type='date'
                    name='dateTo'
                    helperText={validation.dateTo}
                    sx={{ width: 220 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleTextFieldChange} />
                </FormControl>
              </Box>
            </Grid>
          </>
        }

        <Grid item >
          <Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel><FormattedMessage id='select.Boattype' /></InputLabel>
              <Select
                name='boatType'
                value={form?.boatType}
                onChange={handleSelectChange}
                label={<FormattedMessage id='select.Boattype' />}
              >
                <MenuItem value={1}><FormattedMessage id='selectoption.Sailboat' /></MenuItem>
                <MenuItem value={2}><FormattedMessage id='selectoption.Catamaran' /></MenuItem>
                <MenuItem value={3}><FormattedMessage id='selectoption.Motorboat' /></MenuItem>
                <MenuItem value={4}><FormattedMessage id='selectoption.Electricboat' /></MenuItem>
                <MenuItem value={5}><FormattedMessage id='selectoption.Electricyacht' /></MenuItem>
                <MenuItem value={6}><FormattedMessage id='selectoption.Jetski' /></MenuItem>
                <MenuItem value={7}><FormattedMessage id='selectoption.Electricjetski' /></MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item >
          <Box sx={{ minWidth: 220 }}>
            <Button
              variant="contained"
              onClick={handleSearch}
              disabled={!submitVisible}>
              <FormattedMessage id='button.SEARCH' />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper >
  );
}

