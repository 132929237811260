import { useState } from "react";
import { FormattedMessage } from 'react-intl'
import FakeDoorInputDialog from './FakeDoorInputDialog';
import FakeDoorServicesAll from './FakeDoorServicesAll';
import FakeDoorNavbar from './FakeDoorNavbar';
import FakeDoorSubscribeNewsletter from "./FakeDoorSubscribeNewsletter";
import { Button, Paper, ThemeProvider, Typography } from "@mui/material";
import { fdTheme, yellowButton } from "../themes/fdTheme";

function FakeDoor() {
  const [openDialog, setOpenDialog] = useState(false);
  const handleInterested = () => {
    setOpenDialog(true);
  };

  return (
    <div>
      <ThemeProvider theme={fdTheme}>
        <FakeDoorNavbar />
        <div style={{
          margin: 0,
          padding: 1,
          backgroundImage: 'url("https://vizre.hu/media/fausto-garcia-menendez-mxhq9OdSTcg-unsplash_2.png")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
          backgroundPositionY: 'center'
        }}>
          <br />
          <br />
          <Typography variant="h1"
            sx={{ color: 'white', display: 'flex', justifyContent: 'center', m: 1 }}>
            <FormattedMessage id='fakedoor.title.title' />
          </Typography>
          <Typography variant="h2"
            sx={{ color: 'white', display: 'flex', justifyContent: 'center', m: 1 }}>
            <FormattedMessage id='fakedoor.title.text' />
          </Typography>
          <br />
          <br />
        </div>
        <br />
        <div style={{
          margin: 0,
          padding: 1,
        }}>
          <Typography variant="h3"
            sx={{ color: 'black', display: 'flex', justifyContent: 'center', m: 1 }}>
            <FormattedMessage id='fakedoor.subtitle.title' />
          </Typography>
          {false &&
            <Typography variant="h4"
              sx={{ color: 'black', display: 'flex', justifyContent: 'center', m: 1 }}>
              <FormattedMessage id='fakedoor.subtitle.text' />
            </Typography>
          }
        </div>
        <br />
        <FakeDoorServicesAll />
        <br />
        {true &&
          <Paper
            elevation={0}
            sx={{
              m: 0,
              border: 0,
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '100%'
            }}>
            <ThemeProvider theme={yellowButton}>
              <Button
                onClick={handleInterested}
                variant='contained'
              >
                <FormattedMessage id='fakedoor.button.interested' />
              </Button>
              <br /> <br /><br />

            </ThemeProvider>
          </Paper>
        }
        <FakeDoorInputDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
        <FakeDoorSubscribeNewsletter />
      </ThemeProvider>
    </div >
  );
}

export default FakeDoor
