import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl'

import Axios from "axios";
import RootState from '../types/RootState';
import BoatSearchBarFormType from '../types/BoatSearchBarFormType'
import Grid from "@mui/material/Grid";
import ReservationType from '../types/ReservationType'
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";

import Calendar from 'react-calendar';
import CalendarTileProperties from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { addDays, differenceInCalendarDays, isBefore, isAfter, isSameDay, lastDayOfMonth, startOfDay } from 'date-fns';
import 'react-calendar/dist/Calendar.css';
import Button from '@mui/material/Button';
import { convertCompilerOptionsFromJson } from "typescript";
import LoadBoatData from "../tools/LoadBoatData";
import BoatCardSmall from "../components/BoatCardSmall";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const emptyReservation: ReservationType = {
  date: new Date(),
  reserved: -1,
}

function BoatCalendarPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form: BoatSearchBarFormType = useSelector((state: RootState) => state.boatSearchBarForm);
  const hajo = useSelector((state: RootState) => state.selectedHajo);
  //console.log('This is BoatCalendarPage. boatId is: ', form.boatId);
  const [reservationList, setReservationList] = useState([]);
  const [monthList, setMonthList] = useState(new Array);
  const [minDateLimit, setMinDateLimit] = useState(new Date());
  const [maxDateLimit, setMaxDateLimit] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [calendarDefaultValue, setCalendarDefaultValue] = useState(new Date());
  const [submitVisible, setSubmitVisible] = useState(false);



  function procReservationList(x: Array<ReservationType>) {
    //console.log('procReservationList...')
    //console.log('array length: ', x.length)
    var dMin: Date = new Date(9999, 0, 1);
    var dMax: Date = new Date(0, 0, 1);
    var startDates: Date[] = new Array(0);
    for (var i: number = 0; i < x.length; i++) {
      x[i].date = new Date(x[i].date); // ez azért kell, mert itt még string van a 'date' mezőben! :(
      var year: number = x[i].date.getUTCFullYear();
      var month: number = x[i].date.getUTCMonth();
      var startDate: Date = new Date(year, month, 1);
      //console.log(startDate);
      //console.log(startDates);
      // var index = startDates.indexOf(startDate); ez mindig -1-et ad, mert két különböző instance sohasem egyenlő
      var index = startDates.findIndex((x) => { return x.getTime() === startDate.getTime() });
      if (index < 0) startDates.push(startDate);
      //console.log('index: ', index);
      //console.log(x[i].date, 'min: ', dMin);
      if (x[i].date < dMin) { dMin = x[i].date; }
      if (x[i].date > dMax) { dMax = x[i].date; }
    }
    //console.log('startDates', startDates);
    //console.log('Date min: ', dMin);
    //console.log('Date max: ', dMax);
    setMinDateLimit(dMin);
    setMaxDateLimit(dMax);
    return (startDates);
  }

  const fetchCalendar = useCallback(async () => {
    //console.log('This fetchCalendar');


    setReservationList([]);
    setMonthList(new Array);
    setMinDate(form.dateFrom);
    setMaxDate(form.dateTo);
    const req = {
      boatId: form.boatId
    }

    try {
      const selectedHajo = await LoadBoatData(form.boatId);
      dispatch({ type: 'selectedHajo', payload: selectedHajo });
      //console.log(selectedHajo);
      const response = await Axios.post(window.backendUrl + 'query_calendar', req);
      if (response.status === 200) {
        //console.log(response.data);
        setReservationList(response.data);
        //console.log('reservationList: ', reservationList);
        var startDates: Date[] = procReservationList(response.data);
        setMonthList(startDates);
      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
    }
    catch (error) {
      console.error(error);

    };
  }, []);

  useEffect(() => {
    fetchCalendar();
  }, [])

  const now = new Date();

  function isSameDay(a: Date, b: Date): boolean {
    return differenceInCalendarDays(a, b) === 0;
  }

  function d2s(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + String(date.getMonth() + 1)).slice(-2);
    const day = ('0' + String(date.getDate())).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function withinRange(date: Date, lowerLimit: Date, upperLimit: Date): boolean {
    var res: boolean = false;
    if (isSameDay(date, lowerLimit) ||
      isSameDay(date, upperLimit) ||
      isAfter(date, lowerLimit) && isBefore(date, upperLimit)) {
      res = true;
    }
    return res;
  }

  const css = `
  .react-calendar .notactive {
    background-color: lightgrey;
    outline: 1px dotted black;
   }
   .react-calendar .notapplicable {
    background-color: lightgrey;
    outline: 1px solid black;
   }
     .react-calendar .reserved {
  background-color: salmon;
  outline: 1px solid black;
 }
 .react-calendar .free {
  background-color: lightgreen;
  outline: 1px solid black;
 }
 .react-calendar .selectedrange {
  background-color: #006edc;
  outline: 1px solid black;
 }
 .react-calendar .conflict {
  background-color: yellow;
  outline: 1px solid black;
 }
`;

  type CustomTileProps = {
    date: Date,
    view: String,
    activeStartDate: Date,
  }

  function tileClassName(props: CustomTileProps) {
    //console.log(props.activeStartDate);
    var date: Date = props.date;
    setSubmitVisible(true);
    var found: ReservationType = reservationList.find(myFunction) ?? emptyReservation;

    function myFunction(value: ReservationType, index: any, array: any) {
      return isSameDay(value.date, date);
    };


    var res: string = "";
    if (isBefore(date, props.activeStartDate)) {
      res = 'notactive';
    }
    else if (isAfter(date, lastDayOfMonth(props.activeStartDate))) {
      res = 'notactive';
    }
    else if (isBefore(startOfDay(date), startOfDay(new Date()))) {
      res = 'notactive';
    }
    else {
      if (found.reserved === -1) {
        res = 'notapplicable'
      }
      else if (found.reserved === 0) {
        res = 'free'
      }
      else if (found.reserved === 1) {
        res = 'reserved'
      }
      if (withinRange(date, minDate, maxDate)) {
        res = 'selectedrange';
      }
    }
    if (found.reserved === 1 && withinRange(date, minDate, maxDate)) {
      // res = 'conflict'
      setSubmitVisible(false);
    }
    return res;
  }

  function setNewRange(value: Date) {
    //console.log('clicked date: ', d2s(value));
    if (isBefore(startOfDay(value), startOfDay(new Date()))) {
    }
    else {

      if (isBefore(value, minDate)) {
        setMinDate(value);
        dispatch({ type: 'calendarSelectDateFrom', dateFrom: value });
      }
      else if (isAfter(value, maxDate)) {
        setMaxDate(value);
        dispatch({ type: 'calendarSelectDateTo', dateTo: value });
      }
      else if (withinRange(value, minDate, maxDate)) {
        setMinDate(value);
        setMaxDate(value);
        setCalendarDefaultValue(value)
        dispatch({ type: 'calendarSelectDateFrom', dateFrom: value });
        dispatch({ type: 'calendarSelectDateTo', dateTo: value });
      }

      if (isAfter(minDate, maxDate)) {
        setMinDate(maxDate);
        setMaxDate(minDate);
        /*
        itt vajon mi legyen?!
        dispatch({ type: 'calendarSelectDateFrom', dateFrom: minDate });
        dispatch({ type: 'calendarSelectDateTo', dateTo: maxDate });
        */

      }
    }

    //console.log(minDate.toISOString(), maxDate.toISOString());
  }

  function onClickDay(value: any, event: any) {
    setNewRange(value);
  }

  const handleSearch = () => {
    console.log('Search button pressed on BoatCalendarPage page');
    dispatch({ type: 'clearSelectedBoatType', null: null });
    navigate('/search')
  }

  return (
    <div>
      <style>
        {css}
      </style>
      <button onClick={fetchCalendar}>(Refresh page)</button>

      <Box sx={{ minWidth: 220 }}>
        <Button
          variant="contained"
          onClick={handleSearch}
          disabled={!submitVisible}>
          <FormattedMessage id='button.CONTINUE' />
        </Button>
      </Box>
      <br></br>
      {hajo &&
        <BoatCardSmall
          key={'1'}
          hajo={hajo}
          dateFrom={minDate}
          dateTo={maxDate}
        />
      }

      {monthList &&
        <><br></br><br></br>
          {monthList.map((val: Date) => (
            <Calendar
              tileClassName={tileClassName}
              onClickDay={onClickDay}
              activeStartDate={val}
              defaultView="month"
              defaultValue={calendarDefaultValue}
              showNavigation={true}
              nextLabel={null}
              next2Label={null}
              prevLabel={null}
              prev2Label={null}
              minDate={minDateLimit}
              maxDate={maxDateLimit}
            />
          ))}
        </>
      }
    </div>
  )
}

export default BoatCalendarPage
