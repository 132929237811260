import React from 'react'

function About() {
  return (
    <div>
      <h1>We Are The Champions</h1>

      <br/>I've paid my dues
      <br/>Time after time
      <br/>I've done my sentence
      <br/>But committed no crime
      <br/>And bad mistakes
      <br/>I've made a few
      <br/>I've had my share of sand kicked in my face
      <br/>But I've come through

<br/>
<br/>We are the champions, my friends
<br/>And we'll keep on fighting - till the end
<br/>We are the champions
<br/>We are the champions
<br/>No time for losers
<br/>Cause we are the champions - of the world
<br/>
<br/>I've taken my bows
<br/>And my curtain calls
<br/>You brought me fame and fortune and everything that goes with it
<br/>I thank you all
<br/>But it's been no bed of roses
<br/>No pleasure cruise -
<br/>I consider it a challenge before the whole human race
<br/>And I aint gonna lose -
<br/>
<br/>We are the champions - my friends
<br/>And we'll keep on fighting - till the end
<br/>We are the champions
<br/>We are the champions
<br/>No time for losers
<br/>Cause we are the champions - of the world
<br/>
    </div>
  )
}

export default About
