import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Slide, ThemeProvider, useScrollTrigger } from "@mui/material";
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import RootState from '../types/RootState';
import ReactCountryFlag from 'react-country-flag';
import { useEffect, useState } from 'react';
//A képek Typescript alatti importjához ez is kell: /src/react-app-env.d.ts
import Flag_of_Croatia_Flat_Round_128x128 from '../flags/Flag_of_Croatia_Flat_Round_128x128.png';
import Flag_of_Hungary_Flat_Round_128x128 from '../flags/Flag_of_Hungary_Flat_Round_128x128.png';
import Flag_of_United_Kingdom_Flat_Round_128x128 from '../flags/Flag_of_United_Kingdom_Flat_Round_128x128.png';

const navBarTheme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    body1: {
      fontSize: 16,
      fontWeight: 800
    },
    body2: {
      fontSize: 16,
      fontWeight: 400
    },
  },
  palette: {
    primary: {
      main: '#252AA7',
    },

  },
}
);

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

export default function FakeDoorNavbar() {
  const dispatch = useDispatch();
  const database = useSelector((state: RootState) => state.database);
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeUILng = (lng: string) => {
    dispatch({ type: 'changeUILng', payload: lng });
  };

  const navigateHome = () => {
    navigate('/');
  };

  function HideOnScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  return (
    <div>
      <ThemeProvider theme={navBarTheme}>
        <CssBaseline />
        <HideOnScroll >
          <AppBar position='fixed' onClick={navigateHome}>
            <Toolbar sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              {!isMobile &&
                <Box sx={{ display: 'flex', width: '150px' }} />
              }
              <Box sx={{ display: 'flex' }}>
                <img src={'https://vizre.hu/media/logo-blue-white.png'}
                  style={{ width: 130, cursor: 'pointer', paddingBottom: '10px' }}
                />
              </Box>
              <Box sx={{ display: 'flex' }}>
                <img src={Flag_of_Hungary_Flat_Round_128x128}
                  onClick={() => changeUILng('hu')}
                  style={{ width: '2em', height: '2em', margin: 10, cursor: 'pointer' }}
                />
                <img src={Flag_of_Croatia_Flat_Round_128x128}
                  onClick={() => changeUILng('hr')}
                  style={{ width: '2em', height: '2em', margin: 10, cursor: 'pointer' }}
                />
                <img src={Flag_of_United_Kingdom_Flat_Round_128x128}
                  onClick={() => changeUILng('en')}
                  style={{ width: '2em', height: '2em', margin: 10, cursor: 'pointer' }}
                />
                {database === 'DEV' &&
                  <Box sx={{ backgroundColor: 'yellow', color: 'black', mr: 2 }}>
                    {database}
                  </Box>
                }
              </Box>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Toolbar />
      </ThemeProvider>
    </div >
  );
}
