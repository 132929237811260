import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import BoatCardSmall from './BoatCardSmall';
import HajoType from '../types/HajoType'
import LoadBoatData from "../tools/LoadBoatData";
import RootState from '../types/RootState';

function BoatCardList(props: any) {
  //console.log(props);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function clickHandler(boatId: number) {
    console.log('boatId: ', boatId);
    dispatch({ type: 'selectBoatId', selectedBoatId: boatId });
    navigate('/calendar')
  }

  const [boatList, setBoatList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const forcedCharterGuid: string = useSelector((state: RootState) => state.forcedCharterGuid);

  const fetchBoatList = useCallback(async () => {
    //console.log('fetchBoatList.callback()');
    setBoatList([])
    setIsLoading(true);
    setError(null);
    const req = {
      forcedCharterGuid: forcedCharterGuid
    }

    try {
      const response = await Axios.post(window.backendUrl + 'query_boat_list', req);
      if (response.status === 200) {
        console.log(response);
        setBoatList(response.data);
      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
    }
    catch (error) {
      console.error(error);
      //setError(error.message);

    };
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchBoatList();
  }, [])

  return (
    <div>
      {boatList &&
        <Grid container>
          {boatList.map((val: HajoType) => (
            <Grid key={val.id} item sx={{ m: 2 }} onClick={() => clickHandler(val.id)}>
              <BoatCardSmall hajo={val} />
            </Grid>
          ))}
        </Grid>
      }
    </div>
  )
}

export default BoatCardList;
