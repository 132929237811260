import React, { useState, useEffect, useCallback, isValidElement, Dispatch } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from "axios";
import { CredentialResponse, TokenResponse } from "@react-oauth/google";
import jwt_decode from 'jwt-decode';
import GoogleUserType from "../types/GoogleUserType";
import { Action } from "redux";

export default async function LoadAccountData(email: string ) {
  var response: any;  
  const req = {
    email: email,
  }

  console.log("Here comes axios");
  try{
    response=await Axios.post(window.backendUrl+'read_account', req);
    console.log("Axios post done read_account");
    //console.log(response);
    if(response.status === 200){
      console.log('response.data[0]:');
      console.log(response.data[0]);
    }
    else{
      console.log("Error! Response of Axio post is not 200, but "+response.status+" "+response.statusText);    
    }
  }
  catch(error){
    console.log('error');
    console.log(error);
    //setError(error.message);    
  };
  return(response.data[0]);
}



