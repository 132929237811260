import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import RootState from '../types/RootState';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 500
    },
  }
});

function BoatDetailsFurtherboats() {

  const navigate = useNavigate();

  const navigateFaqPage = () => {
    navigate('/faq');
  };

  //console.log('BoatDetailsFurtherboats() called');
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  return (
    <div>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5}
            sx={{ p: 1, m: 1 }}
          >
            <Box
              sx={{ pr: 2, cursor: 'pointer' }}
              onClick={navigateFaqPage}
            >
              <Typography
                variant='h1'
                sx={{ p: 1, textDecoration: "underline" }}

              >
                <FormattedMessage id='faq.faq' />
              </Typography>
            </Box>

          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsFurtherboats;
