import React from 'react'

function Consent() {
	return (
		<div>
			<h1>Adatkezelési hozzájárulás</h1>
		</div>
	)
}
export default Consent
