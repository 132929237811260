import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AcUnit from '@mui/icons-material/AcUnit';
import Badge from '@mui/icons-material/Badge';
import BeachAccess from '@mui/icons-material/BeachAccess';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import CheckBox from '@mui/icons-material/CheckBox';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import Description from '@mui/icons-material/Description';
import DryCleaning from '@mui/icons-material/DryCleaning';
import ElectricMeter from '@mui/icons-material/ElectricMeter';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import Flatware from '@mui/icons-material/Flatware';
import GasMeter from '@mui/icons-material/GasMeter';
import Group from '@mui/icons-material/Group';
import Hotel from '@mui/icons-material/Hotel';
import Kitchen from '@mui/icons-material/Kitchen';
import KingBed from '@mui/icons-material/KingBed';
import LocalCafe from '@mui/icons-material/LocalCafe';
import LocalGasStation from '@mui/icons-material/LocalGasStation';
import LocalLaundryService from '@mui/icons-material/LocalLaundryService';
import LocalPolice from '@mui/icons-material/LocalPolice';
import Microwave from '@mui/icons-material/Microwave';
import OutdoorGrill from '@mui/icons-material/OutdoorGrill';
import Pets from '@mui/icons-material/Pets';
import Power from '@mui/icons-material/Power';
import Radio from '@mui/icons-material/Radio';
import Radar from '@mui/icons-material/Radar';
import Sailing from '@mui/icons-material/Sailing';
import Shower from '@mui/icons-material/Shower';
import Settings from '@mui/icons-material/Settings';
import SingleBed from '@mui/icons-material/SingleBed';
import SkipNext from '@mui/icons-material/SkipNext';
import SmartToy from '@mui/icons-material/SmartToy';
import Soap from '@mui/icons-material/Soap';
import SolarPower from '@mui/icons-material/SolarPower';
import Straighten from '@mui/icons-material/Straighten';
import Speaker from '@mui/icons-material/Speaker';
import Speed from '@mui/icons-material/Speed';
import Star from '@mui/icons-material/Star';
import WbSunny from '@mui/icons-material/WbSunny';
import Surfing from '@mui/icons-material/Surfing';
import Tv from '@mui/icons-material/Tv';
import Wash from '@mui/icons-material/Wash';
import Waves from '@mui/icons-material/Waves';
import Wc from '@mui/icons-material/Wc';

import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { FormattedMessage, useIntl } from 'react-intl'
import DestinationSelect from '../DestinationSelect.tsx'
import BoatTypeSelect from '../BoatTypeSelect'
import PictureCarousel from '../components/PictureCarousel';

import BoatSearchBar from "../components/BoatSearchBar";
import ReservationDetails from "../components/ReservationDetails";
import PricingDetails from "../components/PricingDetails";
import { textAlign } from "@mui/system";
import { d2s } from '../tools/Tools';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function DetailsPage() {
  //  const [detailResultList, setDetailResultList] = useState([]);
  const [detailResult, setDetailResult] = useState({});
  const [product, setProduct] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const boatSearchBarForm = useSelector(state => state.boatSearchBarForm);


  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchBoat = useCallback(async () => {
    dispatch({ type: 'clearBoatId', null: null });
    navigate('/search');
  }, []);

  const customerDataInput = (hajoId) => {
    console.log('customerDataInput pressed, hajoId:');
    console.log(hajoId);
    navigate('/customerDataInput');
  };

  const fetchDetailsHandler = useCallback(async () => {
    console.error('This is fetchDetailsHandler');
    setDetailResult({});
    setIsLoading(true);
    setError(null);
    dispatch({ type: 'clearBoatId', null: null });
    var response;
    try {
      response = await Axios.post(window.backendUrl + 'query_hajo', boatSearchBarForm);
      if (response.status === 200) {
        //console.log("boat query done");
        //console.log(response.data);
        //setDetailResultList(response.data[0]);
        setDetailResult(response.data);
        setProduct(response.data);
        dispatch({ type: 'selectedHajo', payload: response.data.hajo });
        dispatch({ type: 'CalcFizetesiUtemezes', payload: null });        
      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }

      const req = {
        boatSearchBarForm: {
          boatId: boatSearchBarForm.boatId,
          dateFrom: d2s(boatSearchBarForm.dateFrom),
          dateTo: d2s(boatSearchBarForm.dateTo),
          reservationDurationType: boatSearchBarForm.reservationDurationType,
          timeFrom: boatSearchBarForm.timeFrom,
          timeTo: boatSearchBarForm.timeTo,
          withSkipper: boatSearchBarForm.withSkipper,
        }
      }

      dispatch({ type: 'SetHajoAr', payload: 0 });
      dispatch({ type: 'SetKapitanyAr', payload: 0 });
      response = await Axios.post(window.backendUrl + 'get_price', req);
      if (response.status === 200) {
        try {
          let result = response.data.map((x) => Object.keys(x));
          let key = result.flat(1);
          let val = response.data[Object.keys(response.data)[0]];
          var rs = JSON.parse(val[key]);
          console.log('price result: ');
          console.log(rs);
          var total_price = 0;
          var kapitanyAr = 0;
          var hajoAr = 0;
          var total_days = 0;
          var total_hours = 0;

          try { total_price = JSON.parse(rs.TOTAL_PRICE); } catch (e) { };
          try { kapitanyAr = JSON.parse(rs.SKIPPER_PRICE); } catch (e) { };
          try { hajoAr = JSON.parse(rs.BOAT_PRICE); } catch (e) { };
          try { total_days = JSON.parse(rs.TOTAL_DAYS); } catch (e) { };
          try { total_hours = JSON.parse(rs.TOTAL_HOURS); } catch (e) { };
          console.log('Hajó ár: ', hajoAr, ' kapitány ár: ', kapitanyAr, ' teljes ár: ', total_price);
          dispatch({ type: 'SetHajoAr', payload: hajoAr });
          dispatch({ type: 'SetKapitanyAr', payload: kapitanyAr });
          const pricing = {
            totalDays: total_days,
            totalHours: total_hours,
          }
          dispatch({ type: 'SetPricing', payload: pricing });
          dispatch({ type: 'CalcFizetesiUtemezes', payload: null });                
        }
        catch (ex) {
          console.error(ex);
        }

      }
      else {
        console.error("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }

    }
    catch (error) {
      console.log(error);
      setError('X: ' + error.message);

    };
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchDetailsHandler();
  }, [fetchDetailsHandler])




  return (
    <div align='left'>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Item>
          <BoatSearchBar
            direction={'column'}
            withReservationButton={true}
            onReservation={customerDataInput}
            onSearch={searchBoat}
          />
        </Item>

        <ReservationDetails />
        <PricingDetails />

        <Grid container direction={'row'} spacing={{ xs: 1, md: 1 }} columns={12}>

          <Grid item xs={'12'} md={'8'} align={'middle'}>

            <PictureCarousel />

            <Card sx={{
              display: 'flex',
              maxWidth: 600,
              borderColor: 'primary.main',
              border: 1,
              borderRadius: 3,
              padding: 2,
              margin: 3,
              boxShadow: 5


            }}>
              <CardActionArea
                title={intl.formatMessage({ id: 'action.Carousel' })}
              >
                {product.pic_guid &&
                  <CardMedia
                    component="img"
                    height="200"
                    src={product.pic_guid}
                    alt={product.nev}
                  />
                }
              </CardActionArea>

              <CardActionArea
                title={intl.formatMessage({ id: 'action.Details' })}
              >

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center'
                }}>
                  <CardHeader
                    title={product.nev}
                    subheader={product.model}
                  />

                  <CardContent>
                    <Typography variant="body" color="text.primary">
                      <FormattedMessage id='boat.Length' /> {product.hossz} m <br />
                      <FormattedMessage id='boat.Draft' /> {product.merules} m
                    </Typography>

                    <Typography variant="body2" color="text.primary">
                      <FormattedMessage id='boat.Enginetype' /> {product.motor} <br />
                      <FormattedMessage id='boat.Enginepower' /> {product.motor_le} <FormattedMessage id='boat.EngineHP' />
                    </Typography>

                    <Typography variant="body2" color="text.primary">
                      <FormattedMessage id='boat.Make' /> {product.gyarto}
                    </Typography>
                  </CardContent>
                </Box>
              </CardActionArea>

              <React.Fragment>
                <CssBaseline />
                <Container fixed>
                  <Box sx={{
                    width: 'auto',
                    borderStyle: 'dotted',
                    borderWidth: '1px',
                    borderRadius: 2,
                    padding: 1,
                    alignItems: 'center'
                  }}>
                  </Box>
                </Container>
              </React.Fragment>

            </Card>


            <Grid container spacing={{ xs: 1, md: 1 }} columns={12}>
              <Grid item xs={'12'} md={'6'} lg={'4'}>
                <ListItem enablePadding>
                  <ListItemIcon><CalendarMonth /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Yearofmanufacture' /></ListItemText>
                  <Typography variant="body2" color="text.primary"> {product.gyartasi_ev}</Typography>
                </ListItem>
                <Divider />
              </Grid>
              {product.felujitas_ev > 0 &&
                <Grid item xs={'12'} md={'6'} lg={'4'} >
                  <ListItem enablePadding>
                    <ListItemIcon><CalendarMonth /></ListItemIcon>
                    <ListItemText inset><FormattedMessage id='boat.Yearofrenewal' /></ListItemText>
                    <Typography variant="body2" color="text.primary"> {product.felujitas_ev}</Typography>
                  </ListItem>
                  <Divider />
                </Grid>
              }

              <Grid item xs={'12'} md={'6'} lg={'4'} >
                <ListItem enablePadding>
                  <ListItemIcon><DirectionsBoat /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Length' /></ListItemText>
                  <Typography variant="body2" color="text.primary"> {product.hossz}&nbsp;m</Typography>
                </ListItem>
                <Divider />
              </Grid>

              <Grid item xs={'12'} md={'6'} lg={'4'} >
                <ListItem enablePadding>
                  <ListItemIcon><DirectionsBoat /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Beam' /></ListItemText> <Typography variant="body2" color="text.primary">{product.szelesseg}&nbsp;m</Typography>
                </ListItem>
                <Divider />
              </Grid>
              <Grid item xs={'12'} md={'6'} lg={'4'} >
                <ListItem enablePadding>
                  <ListItemIcon><DirectionsBoat /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Draft' /></ListItemText>
                  <Typography variant="body2" color="text.primary">{product.merules}&nbsp;m</Typography>
                </ListItem>
                <Divider />
              </Grid>

              <Grid item xs={'12'} md={'6'} lg={'4'} >
                <ListItem enablePadding>
                  <ListItemIcon><Group /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Maxpassengers' /></ListItemText> <Typography variant="body2" color="text.primary">{product.max_utasok}</Typography>
                </ListItem>
                <Divider />
              </Grid>

              <Grid item xs={'12'} md={'6'} lg={'4'} >
                <ListItem enablePadding>
                  <ListItemIcon><Settings /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Enginetype' /></ListItemText>
                  <Typography variant="body1" color="text.primary">{product.motor}</Typography>
                </ListItem>
                <Divider />
              </Grid>
              <Grid item xs={'12'} md={'6'} lg={'4'} >
                <ListItem enablePadding>
                  <ListItemIcon><Settings /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Enginepower' /></ListItemText> <Typography variant="body2" color="text.primary">{product.motor_le}&nbsp;<FormattedMessage id='boat.EngineHP' /></Typography>
                </ListItem>
                <Divider />
              </Grid>
              <Grid item xs={'12'} md={'6'} lg={'4'} >
                <ListItem enablePadding>
                  <ListItemIcon><GasMeter /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Fuelconsumption' /></ListItemText> <Typography variant="body2" color="text.primary">{product.motor_fogyasztas}</Typography>
                </ListItem>
                <Divider />
              </Grid>
              <Grid item xs={'12'} md={'6'} lg={'4'} >
                <ListItem enablePadding>
                  <ListItemIcon><LocalGasStation /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Fueltank' /></ListItemText> <Typography variant="body2" color="text.primary">{product.uzemanyag_tartaly}&nbsp;l</Typography>
                </ListItem>
                <Divider />
              </Grid>

            </Grid>
            <Divider />

            <Grid container spacing={{ xs: 1, md: 1 }} columns={12} >
              <Grid item xs={'12'}>
                <ListItem enablePadding>
                  <ListItemIcon><Description /></ListItemIcon>
                  <ListItemText inset><FormattedMessage id='boat.Description' /></ListItemText>
                </ListItem>
                <Divider variant={'inset'} />
                <ListItem enablePadding>
                  <ListItemText inset>
                    <Typography variant="body2" color="text.primary">{product.leiras}</Typography>
                  </ListItemText>
                </ListItem>

              </Grid>

            </Grid>
            <Divider />

            <Grid container spacing={{ xs: 1, md: 1 }} columns={12} border={0}>
              <Grid item xs={'12'}>
                <List >
                  <ListItem enablePadding >
                    <ListItemIcon><Badge /></ListItemIcon>
                    <ListItemText inset >
                      <FormattedMessage id='boat.Name' />
                    </ListItemText>
                    <Typography variant="body1" align="right" color="text.primary" width={1000}> {product.nev} </Typography>
                  </ListItem>
                  <Divider />
                  <ListItem enablePadding>
                    <ListItemIcon><Badge /></ListItemIcon>
                    <ListItemText inset><FormattedMessage id='boat.Make' /></ListItemText>
                    <Typography variant="body1" align="right" color="text.primary" width={1000}> {product.gyarto}</Typography>
                  </ListItem>
                  <Divider />
                  <ListItem enablePadding>
                    <ListItemIcon><Badge /></ListItemIcon>
                    <ListItemText inset><FormattedMessage id='boat.Model' /></ListItemText>
                    <Typography variant="body1" align="right" color="text.primary" width={1000}> {product.model}</Typography>
                  </ListItem>
                  <Divider />


                  {product.fovitorla &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Sailing /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Sail' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }

                  {product.mosdok &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Wash /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Restrooms' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.kisallat &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Pets /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Pet' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.minimum_experience &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><EmojiEvents /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Minimumexperience' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.kapitannyal &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><LocalPolice /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Withskipper' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.kapitany_nelkul &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><LocalPolice /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Withoutskipper' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.aludni_lehet &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Hotel /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Sleeping' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.aludhat_max &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Hotel /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Beds' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.kabin_1_db &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><SingleBed /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Cabin1' /></ListItemText> <Typography variant="body2" color="text.primary">{product.kabin_1_db}</Typography>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.kabin_2_db &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><KingBed /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Cabin2' /></ListItemText> <Typography variant="body2" color="text.primary">{product.kabin_2_db}</Typography>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.emeletes_kabin_db &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><KingBed /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Cabinx' /></ListItemText> <Typography variant="body2" color="text.primary">{product.emeletes_kabin_db}</Typography>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.wc_db &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Wc /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Wc' /></ListItemText> <Typography variant="body2" color="text.primary">{product.wc_db}</Typography>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.max_sebesseg &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Speed /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Maxspeed' /></ListItemText>
                        <Typography variant="body2" color="text.primary">{product.max_sebesseg}</Typography>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.klima &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><AcUnit /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Ac' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.tuzhely &&
                    <>

                      <ListItem enablePadding>
                        <ListItemIcon><Kitchen /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Stove' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.mosogato &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Soap /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Dishwasher' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.mosogep &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><LocalLaundryService /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Washingmachine' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.huto &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Kitchen /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Fridge' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.melyhuto &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Kitchen /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Freezer' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.bbq &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><OutdoorGrill /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Bbq' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.futes &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><WbSunny /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Heating' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.konyhai_eszkozok &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Flatware /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Kitchenware' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.mikro &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Microwave /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Microwave' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.suto &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Microwave /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Bakingoven' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.agynemu &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Star /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Bedding' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.zuhany &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Shower /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Shower' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.torolkozo &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><DryCleaning /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Towel' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.kabegep &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><LocalCafe /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Coffeemaker' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.tv &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Tv /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Tv' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.hangszoro &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Speaker /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Loudspeaker' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.radio &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Radio /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Radio' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.dvd &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><SkipNext /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Dvd' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.napelemek &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><SolarPower /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Solarpanel' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.bimini &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><BeachAccess /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Bimini' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.generator &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><ElectricMeter /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Generator' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.elektromos_csorlo &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Star /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Electricwinch' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.radar &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Radar /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Radar' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.inverter &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Power /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Inverter' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.robotpilota &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><SmartToy /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Autopilot' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.hullamtores &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Waves /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Wave' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }
                  {product.vizisi &&
                    <>
                      <ListItem enablePadding>
                        <ListItemIcon><Surfing /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Waterski' /></ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }

                  {product.comment &&
                    <>
                      <ListItem enablePadding >
                        <ListItemIcon><Star /></ListItemIcon>
                        <ListItemText inset><FormattedMessage id='boat.Comment' />
                          <Typography variant="body2" color="text.primary">{product.comment}</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                    </>
                  }

                </List >

              </Grid >
            </Grid >
          </Grid >
        </Grid >
      </Stack>

    </div >

  );


}

export default DetailsPage
