
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Button, Box, ThemeProvider, Paper } from "@mui/material";
import FakeDoorService from './FakeDoorService';
import { ServiceType, emptyService } from '../types/DatabaseTypes';
import RootState from '../types/RootState';
import { fdTheme, yellowButton } from "../themes/fdTheme";

function FakeDoorServicesAll() {

  const serviceList = useSelector((state: RootState) => state.fakeDoorServiceList);
  const language = useSelector((state: RootState) => state.language);
  const [activeOption, setActiveOption] = useState('hotel');

  const handleOptionChange = (event: string) => {
    console.log('Change: ', event);
    setActiveOption(event ?? 'hotel');
  };

  function getService(lng: string, buttonId: string) {
    var item: ServiceType = emptyService;
    if (serviceList?.length > 0) {
      item = serviceList.find(x => x.lng === lng && x.fake_door_id === buttonId) ?? emptyService;
    }
    return (
      <FakeDoorService {...item} />
    )
  }

  function getButtonTitle(lng: string, buttonId: string) {
    var item: ServiceType = emptyService;
    if (serviceList?.length > 0) {
      item = serviceList.find(x => x.lng === lng && x.fake_door_id === buttonId) ?? emptyService;
    }
    return item?.title
  }

  useEffect(() => {
  }, [language, serviceList]);


  const ServiceButton = ({ lng, buttonId }: { lng: string, buttonId: string }) => {
    return (
      <Button
        variant={activeOption === buttonId ? 'contained' : 'outlined'}
        onClick={() => {
          handleOptionChange(buttonId);
        }}
      >
        {getButtonTitle(lng, buttonId)}
      </Button>
    )
  }

  return (
    <>
      <ThemeProvider theme={fdTheme}>
        <ThemeProvider theme={yellowButton}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                gap: 1,
                border: 0,

              }}>
              {serviceList?.length > 0 &&
                serviceList?.filter((item: ServiceType) => item.lng === language)?.map((x: ServiceType) =>
                  <ServiceButton buttonId={x.fake_door_id} lng={x.lng} />
                )
              }
            </Box>
            {getService(language, activeOption)}
        </ThemeProvider>
      </ThemeProvider>
    </>
  );
}

export default FakeDoorServicesAll;
