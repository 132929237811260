import Axios from "axios";

export default async function LoadKikotoList(store: any){
  console.log('LoadKikotoList start **************************');  
  const x=await _LoadKikotoList();
  store.dispatch({ type: 'KikotoList', payload: x });
}

async function _LoadKikotoList() {
  //console.log('LoadKikotoList started');
  //const dispatch = useDispatch();
  var response;
  try {
    response = await Axios.post(window.backendUrl + 'query_kikoto_list');
    if (response.status === 200) {
      //console.log(response.data);
    }
    else {
      console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }
  }
  catch (err) {
    console.error(err);
  }
  finally {
    //console.log('LoadKikotoList ended');
  }
  return(response?.data);
};

// export {doIt};


