import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Divider, Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import RootState from '../types/RootState';
import { useState, useEffect } from 'react';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 500
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 16,
      fontWeight: 800
    },
  }
});
const isMobile = window.innerWidth <= 768;

function BoatDetailsAlapadatok() {
  //console.log('BoatDetailsAlapadatok() called');
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  //console.log('selectedHajo:', selectedHajo);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var cabin: number = 0;
  if (selectedHajo) {
    cabin = Number(selectedHajo.kabin_1_db ?? 0) + Number(selectedHajo.kabin_2_db ?? 0) + Number(selectedHajo.emeletes_kabin_db ?? 0);
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5} sx={{ p: 1, m: 1 }}>
            <Typography
              variant="h1"
              paragraph={false}
              sx={{ p: 1, m: 1 }}>
              <FormattedMessage id='boat.Basicdata' />
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  pl: 1, pr: 1,
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  justifyContent: 'flex-start'
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1" paragraph={false}>
                    <FormattedMessage id='boat.Yearofmanufacture' />
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {selectedHajo.gyartasi_ev ?? ''}
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Maxpassengers' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.max_utasok ?? '-'}
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Beds' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.aludhat_max ?? '-'}
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Cabins' />
                  </Typography>
                  <Typography variant="body2">
                    {cabin}
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Restrooms' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.mosdok ?? '-'}
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Wc' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.wc_db ?? '-'}
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Sail' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.fovitorla ?? '-'}
                  </Typography>
                </Box>
                <Divider />

              </Box>

              <Box
                sx={{
                  pl: 1, pr: 1,
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  justifyContent: 'flex-start'
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Length' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.hossz ?? '-'} m
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Draft' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.merules ?? '-'}&nbsp;m
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Beam' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.szelesseg ?? '-'}&nbsp;m
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Enginetype' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.motor ?? '-'}
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Enginepower' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.motor_le ?? '-'}&nbsp;<FormattedMessage id='boat.EngineHP' />
                  </Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body1">
                    <FormattedMessage id='boat.Fueltank' />
                  </Typography>
                  <Typography variant="body2">
                    {selectedHajo.uzemanyag_tartaly ?? '-'}&nbsp;l
                  </Typography>
                </Box>
                <Divider />

              </Box>
            </Box>
          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsAlapadatok;
