import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Axios from "axios";

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import RootState from '../types/RootState';
import FormType from '../types/FormType';
import { isValidEmailAddress } from '../tools/Tools';

export interface DialogEmailLoginProps {
  open: boolean;
  selectedValue: string;
  onClose: () => void;
  onMixedLoginClicked: () => void;
  onResetPasswordClicked: () => void;
}

export function DialogEmailLogin(props: DialogEmailLoginProps) {

  const dispatch = useDispatch();
  const intl = useIntl();
  const form = useSelector((state: RootState) => state.form);
  const [validation, setValidation] = useState<FormType>({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'email') {
      dispatch({ type: 'FormValues', form: { ...form, email: value } });
    }
    else if (name === 'password') {
      dispatch({ type: 'FormValues', form: { ...form, password: value } });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  }

  const checkValidation = () => {
    setSubmitVisible(true);
    let errors = JSON.parse(JSON.stringify(validation));
    if (!form?.email?.trim()) {
      errors.email = intl.formatMessage({ id: 'input.validation.email_address_required' })
      setSubmitVisible(false);
    }
    else if (!isValidEmailAddress(form?.email?.trim())) {
      errors.email = intl.formatMessage({ id: 'input.validation.email_address_format_invalid' })
      setSubmitVisible(false);
    }
    else {
      errors.email = '';
    }
    if (!form?.password?.trim()) {
      errors.password = intl.formatMessage({ id: 'input.validation.password_required' })
      setSubmitVisible(false);
    }
    else {
      errors.password = '';
    }
    setValidation(errors);
  }

  useEffect(() => {
    checkValidation();
  }, [form]);

  useEffect(() => {
    checkValidation();
  }, [props.open]);

  const handleLogin = () => {
    loginWithEmail();
  };

  const dispatchLogin = (data: string) => {
    dispatch({ type: 'LoginWithEmail', currentUser: data });
  };

  const dispatchLogout = () => {
    dispatch({ type: 'Logout' });
  };

  const handleClose = () => {
    dispatch({ type: 'FormValues', form: { ...form, password: '' } });
    setFormSubmitted(false);
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    props.onClose();
  };

  async function loginWithEmail() {
    setSubmitVisible(false);
    try {
      const response = await Axios.post(window.backendUrl + 'login_with_email', form);
      if (response.status === 200) {
        console.log('Login was successful');
        setFormSubmitted(true);
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        console.log(response.data[0]);
        dispatchLogin(response.data[0]);

      }
      else {
        console.log('Login was not successful, logout current user!');
        setFormSubmitted(true);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        dispatchLogout();
      }
    }
    catch (error) {
      console.log('Login was not successful, logout current user!');
      console.log(error);
      setFormSubmitted(true);
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
      dispatchLogout();
    };
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      fullWidth
    >
      <DialogTitle><FormattedMessage id='dialog.title.login' />
        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>

        <List>
          <ListItem>
            <TextField
              value={form?.email}
              required
              label={intl.formatMessage({ id: 'input.label.email' })}
              fullWidth
              name='email'
              helperText={validation.email}
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              type='password'
              required
              label={intl.formatMessage({ id: 'input.label.password' })}
              fullWidth
              name='password'
              helperText={validation.password}
              onChange={handleTextFieldChange} />
          </ListItem>

          {!formSubmitted &&
            <ListItem>
              <Button
                onClick={handleLogin}
                variant='contained'
                fullWidth
                sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
                disabled={!submitVisible}
              >
                <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
                  <FormattedMessage id='submit.login' />
                </ListItemText>
              </Button>
            </ListItem>
          }

          {formSubmitted && showSuccessAlert &&
            <ListItem>
              <Alert variant='standard' severity='success' style={{width:'100%'}}>
                <FormattedMessage id='alert.login_successful' />
              </Alert>
            </ListItem>
          }

          {formSubmitted && showErrorAlert &&
            <ListItem>
              <Alert variant='standard' severity='error' style={{width:'100%'}}>
                <FormattedMessage id='alert.login_unsuccessful' />
              </Alert>
            </ListItem>
          }


          {(!formSubmitted || (formSubmitted && showErrorAlert)) &&
            <>
              <ListItem onClick={props.onResetPasswordClicked} sx={{ pt: 0, m: 0 }}>
                <Button variant="outlined" fullWidth sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}>
                  <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
                    <FormattedMessage id='button.forgotten_password' />
                  </ListItemText>
                </Button>
              </ListItem>

              <ListItem onClick={props.onMixedLoginClicked}>
                <Button variant="outlined" fullWidth sx={{ textAlign: 'left', textTransform: 'none', border: 0 }} >
                  <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
                    <FormattedMessage id='button.further_login_options' />
                  </ListItemText>
                </Button>
              </ListItem>
            </>
          }
        </List>
      </DialogContent>

      <DialogActions>
        <DeveloperModeIcon onClick={handleLogin} />

        <Button autoFocus onClick={handleClose}>
          <FormattedMessage id='button.close' />
        </Button>
      </DialogActions>


    </Dialog>


  );
}


