import { useSelector, useDispatch} from 'react-redux';
//import HajoType from '../types/HajoType'
import RootState from '../types/RootState'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {FormattedMessage } from 'react-intl'


import LocalPolice from '@mui/icons-material/LocalPolice';
import Tv from '@mui/icons-material/Tv';
import Radar from '@mui/icons-material/Radar';
import AcUnit from '@mui/icons-material/AcUnit';
import BeachAccess from '@mui/icons-material/BeachAccess';
import Power from '@mui/icons-material/Power';
import Microwave from '@mui/icons-material/Microwave';
import Grid from '@mui/material/Grid';

import ReactCountryFlag from "react-country-flag";

function BoatCard() {
  const hajo = useSelector((state: RootState)  => state.selectedHajo);  
  //console.log('***  This is BoatCard   ***');
  //console.log('Hajó objektum:');
  //console.log(hajo);
  return (
    <div>
    {hajo &&    
      <Card sx={{borderStyle: 'dotted', borderWidth: '1px', borderColor:'lightgrey', borderRadius: 2, padding: 3, alignItems: 'center' }}>
<Stack
  direction={{ xs: 'column', lg: 'row' }}
  spacing={{ xs: 1, sm: 2, md: 4 }}
>
      <CardMedia
        component="img"
        alt="boat picture"
        sx={{ width: 480 }}
        image={hajo.pic_guid}
      />

    <CardContent>
    <Typography variant="button" color="text.primary" bgcolor="lightgray">{hajo.hajo_tipus_nev}</Typography>
    <Typography variant="h5" color="text.primary">{hajo.nev} | {hajo.model}</Typography>
    <Typography variant="h6" color="text.primary"><ReactCountryFlag countryCode="HU" svg style={{ width: '1em', height: '1em' }} /> {hajo.kikoto_telepules}, {hajo.kikoto_nev}</Typography>
    <br/>

    <Grid container spacing={{ xs: 1, md: 1 }} columns={12} sx={{ boxShadow: 3 }}>
    <Grid item xs={12} md={6}  lg={4} >
    {(hajo.kapitannyal || hajo.kapitany_nelkul) &&
    <LocalPolice/>     
    }
    {hajo.kapitannyal && !hajo.kapitany_nelkul &&
     <FormattedMessage id='boat.Withskipper'/>
    }  
    {hajo.kapitannyal && hajo.kapitany_nelkul &&
       <FormattedMessage id='boat.Withorwithoutskipper'/>
    }  
    {!hajo.kapitannyal && hajo.kapitany_nelkul &&
    <FormattedMessage id='boat.Withoutskipper'/>
    }  
    </Grid>

    {hajo.tv  && 
    <Grid item xs={12} md={6} lg={4}>
    <Tv/> <FormattedMessage id='boat.Tv'/> 
    </Grid>
    }
    {hajo.tv && 
    <Grid item xs={12} md={6} lg={4}>
    <Radar/> <FormattedMessage id='boat.Radar'/> 
    </Grid>
    }

    {hajo.klima && 
    <Grid item xs={12} md={6} lg={4}>
    <AcUnit/> <FormattedMessage id='boat.Ac'/> 
    </Grid>
    }

    {hajo.bimini &&
    <Grid item xs={12} md={6} lg={4}>
    <BeachAccess/> <FormattedMessage id='boat.Bimini'/> 
    </Grid>
    }
    {hajo.inverter &&
    <Grid item xs={12} md={6} lg={4}>
    <Power/> <FormattedMessage id='boat.Inverter'/> 
    </Grid>
    }
    {hajo.mikro &&
    <Grid item xs={12} md={6} lg={4}>
    <Microwave/> <FormattedMessage id='boat.Microwave'/>
    </Grid>
    }
    </Grid>
    <br/>

    <Grid container spacing={{ xs: 1, md: 1 }} columns={12} sx={{ boxShadow: 3 }}   textAlign={'center'}>
    <Grid item xs={6} md={3}  sx={{boxShadow: 1 } }>
    <Typography variant="h6" color="text.secondary"><FormattedMessage id='boat.Yearofmanufacture'/></Typography>
    <Typography variant="h5" color="text.primary"> {hajo.gyartasi_ev}</Typography>
    </Grid>
    <Grid item xs={6} md={3}  sx={{boxShadow: 1 } }>
    <Typography variant="h6" color="text.secondary"><FormattedMessage id='boat.Maxpassengers'/></Typography>
    <Typography variant="h5" color="text.primary"> {hajo.max_utasok}</Typography>
    </Grid>
    <Grid item xs={6} md={3}  sx={{boxShadow: 1 } }>
    <Typography variant="h6" color="text.secondary"><FormattedMessage id='boat.Beds'/></Typography>
    <Typography variant="h5" color="text.primary"> {hajo.aludhat_max}</Typography>
    </Grid>
    <Grid item xs={6} md={3}  sx={{boxShadow: 1 } }>
    <Typography variant="h6" color="text.secondary"><FormattedMessage id='boat.Cabins'/></Typography>
    <Typography variant="h5" color="text.primary"> {hajo.aludhat_max}</Typography>
    </Grid>
   
    </Grid>

      </CardContent>
      </Stack>                
  </Card>

        }
  </div>

  );
}

export default BoatCard;
