export  interface HajoType{
  id: number,
	nev: string,
	kod: string,
	hajo_tipus_id: number,
	kiado_id: number,
	gyartasi_szam: string,
	max_utasok: number,
	sajat : boolean,
	tulaj_user_id: number,
	tulaj_nev: string,
	tulaj_email: string,
	tulaj_telefon: string,
	bevetel_100: number,
	fo_kikoto: string,
	gyarto: string,
	model: string,
	hossz: number,
	merules: number,
	motor: string,
	motor_le: number,
	motor_fogyasztas: number,
	uzemanyag_tartaly: number,
	szelesseg: number,
	fovitorla: string,
	leiras: string,
	berbeadhato : boolean,
	mosdok: number,
	kisallat: boolean,
	online_megjelenes : boolean,
	minimum_tapasztalat: string,
	kapitannyal : boolean,
	kapitany_nelkul : boolean,
	aludni_lehet: boolean,
	aludhat_max: number,
	kabin_1_db: number,
	kabin_2_db: number,
	emeletes_kabin_db: number,
	wc_db: number,
	gyartasi_ev: number,
	felujitas_ev: number,
	max_sebesseg: number,
	klima: boolean,
	tuzhely: boolean,
	mosogato: boolean,
	mosogep: boolean,
	huto: boolean,
	melyhuto: boolean,
	bbq: boolean,
	futes: boolean,
	konyhai_eszkozok: boolean,
	mikro: boolean,
	suto: boolean,
	agynemu: boolean,
	zuhany: boolean,
	torolkozo: boolean,
	kabegep: boolean,
	tv: boolean,
	hangszoro: boolean,
	radio: boolean,
	dvd: boolean,
	napelemek: boolean,
	bimini: boolean,
	generator: boolean,
	elektromos_csorlo: boolean,
	radar: boolean,
	inverter: boolean,
	robotpilota: boolean,
	hullamtores: boolean,
	biztositas: boolean,
	vizisi: boolean,
	komment: string,
	max_napok: number,
  pic_guid: string,
  hajo_tipus_nev: string,
  kikoto_telepules: string,
  kikoto_nev: string,
  kikoto_cim: string,
	kiadas_tol_ig: string,
	visszahozatal_tol_ig: string,
	kiado_nev: string,
	lemondhato: number,
	foglalo_veszik: number,
	teljes_veszik: number,
	free_wifi: boolean,
	downpayment_100: number,
	price_per_day_from: number,
}
export interface KiadoType {
  id: number
  nev: string
	email: string
	telefon: string
  guid: string
}

export interface KikotoType {
  id: number
	cim: string
	nev: string
  telepules: string
  online_megjelenes: boolean
	country: string
}

export interface FoglalasType {
	allapot	: string
	arazas_tipus	: string
	atvett_kaucio	: number
	azonosito	: string
	berlo	: 	number
	berlore_var	: boolean
	bevetel_100	: number
	dij	: number
	eloleg	: number
	eloleg_mail_sent: boolean
	hajo_id: number
	id	: number
	ig	: Date
	init_mail_sent	: boolean
	kapitannyal	: boolean
	kapitany_id	: number
	kaucio	: number
	kiadas_time: Date
	megjegyzes: string
	munkatars1_id: number
	munkatars2_id: number
	tipus	: string
	tol	: Date
	ugyfel_id	: number
	visszaadott_kaucio	: number
	visszahozatal_time	: Date
}
export interface QuoteType {
  quote_id: number
	userid: number
	email: string
  hajo_id: number
  tol: Date
	ig: Date
	quote_status: string
	ts_insert: Date
	calculation_valid: boolean
	total_days: number
	total_hours: number
	total_price: number
	price_details: string
	guid: string
	firstname: string
	lastname: string
	phone: string
	remark: string
	ts_calc: Date
	day_0: Date
	day_1: Date
	day_2: Date
	day_3: Date
	boat_price: number
	skipper_price: number
	currency: string
	downpayment100: number
}

export const emptyQuote : QuoteType = {
	quote_id: 0,
	userid: 0,
	email: "",
	hajo_id: 0,
	tol: new Date(0),
	ig: new Date(0),
	quote_status: "",
	ts_insert: new Date(0),
	calculation_valid: false,
	total_days: 0,
	total_price: 0,
	price_details: "",
	guid: "",
	firstname: "",
	lastname: "",
	phone: "",
	remark: "",
	total_hours: 0,	
	ts_calc: new Date(0),
	day_0: new Date(0),
	day_1: new Date(0),
	day_2: new Date(0),
	day_3: new Date(0),
	boat_price: 0,
	skipper_price: 0,
	currency: 'HUF',
	downpayment100: 0,
};



export interface ServiceType  {
  id: number,
  lng: string,
  display_order: number,
  valid: boolean,
  title: string,
  content: string,
  background_color: string,
  text_color: string,
  landing_page: string,
  image_name: string,
	id_hash: string,
	virtual_url: string,
	is_fake_door: boolean,
	fake_door_id: string
}

export const emptyService : ServiceType = {
	id: 0,
	lng: "",
	display_order: 0,
	valid: false,
	title: "",
	content: "",
	background_color: "",
	text_color: "",
	landing_page: "",
	image_name: "",
	id_hash: "",
	virtual_url: "",
	is_fake_door: false,
	fake_door_id: ""
}

export interface ServiceListType extends Array <ServiceType> {};

export interface DocumentTemplateType  {
  id: number,
	doc_type: string, 
  lng: string,
  valid: boolean,
  title: string,
  subtitle: string,
  body: string,
}

export const emptyDocumentTemplate : DocumentTemplateType = {
	id: 0,
	doc_type: "",
	lng: "",
	valid: false,
	title: "",
	subtitle: "",
	body: ""
}

export interface DocumentTemplateListType extends Array <DocumentTemplateType> {};

export interface DatabaseType  {
	foglalas: FoglalasType
	hajo: HajoType
	kiado: KiadoType
	kikoto: KikotoType
	quote: QuoteType
	service: ServiceType
}
