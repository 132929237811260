import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from "axios";
import { FormattedMessage } from 'react-intl';
import Accordion from '@mui/material/Accordion';
import Alert from '@mui/material/Alert';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ProfileReservationCardSummary from '../components/ProfileReservationCardSummary';
import ProfileReservationCardDetails from '../components/ProfileReservationCardDetails';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    body1: {
      fontSize: 16,
      fontWeight: 800
    },
    body2: {
      fontSize: 16,
      fontWeight: 500
    },
  },
});




export default function ProfileReservationsPage() {

  const [reservationList, setReservationList] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [displayFilter, setDisplayFilter] = React.useState('active');

  const handleTabChange = (event, newValue) => {
    setDisplayFilter(newValue);
  };

  const fetchReservations = useCallback(async () => {
    console.log('Callback: fetchReservations');
    setIsLoading(true);
    setIsError(false);
    setReservationList([]);
    try {
      const req = {
        dummy: 0,
      }
      const response = await Axios.post(window.backendUrl + 'query_my_reservations', req);
      if (response.status === 200) {
        setReservationList(response.data);
        console.log('Reservation list length: ', response.data.length);
        console.log(response.data);
      }
      else {
        console.error("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
    }
    catch (error) {
      setIsError(true);
      console.error(error);

    };
    setIsLoading(false);


  }, []);

  useEffect(() => {
    fetchReservations();
  }, [])

  /*
              <Typography variant="h1" sx={{ p: 2 }}>
                <FormattedMessage id='navbar.current_reservations' /> {' | '}
                <FormattedMessage id='navbar.past_reservations' />
              </Typography>
  
  */


  return (
    <div>
      {isLoading && !isError &&
        <Alert variant='standard' severity='info'>
          <FormattedMessage id='alert.data_fetch_pending' />
        </Alert>
      }
      {isError &&
        <Alert variant='standard' severity='warning'>
          <FormattedMessage id='alert.data_fetch_pending' />
        </Alert>
      }
      {!isLoading && !isError &&

        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Grid container spacing={0} sx={{ pt: 2, pb: 2 }}>
            <Grid xs={6} sx={{ pb: 2 }}>
              <Typography variant="h1" sx={{ p: 2 }}>
                <FormattedMessage id='navbar.profile_reservations' />
              </Typography>

            </Grid>
            <Grid xs={6} sx={{ pb: 2 }}>
              <Tabs
                value={displayFilter}
                onChange={handleTabChange}
              >
                <Tab value="active" label="Aktív foglalások" />
                <Tab value="past" label="Korábbi foglalások" />
              </Tabs>
            </Grid>
          </Grid>



          <div>

            {reservationList.length &&
              <div>
                {
                  reservationList.map((item) => (

                    (item.display == displayFilter) && (<div>
                      <Accordion
                        expanded={expanded === item.id}
                        onChange={handleChange(item.id)}
                        id={item.id}
                        sx={{ pb: 1, m: 2, boxShadow: 3 }}
                      >
                        <AccordionSummary
                          sx={{ height: 148, width: 850, p: 0, m: 0, border: '0' }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <ProfileReservationCardSummary {...item} />
                        </AccordionSummary>
                        <AccordionDetails>
                          <ProfileReservationCardDetails {...item} />
                        </AccordionDetails>
                      </Accordion>
                    </div>)
                  )
                  )}
              </div>
            }
          </div>
        </ThemeProvider>
      }
    </div>
  );
}