import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { FormattedMessage } from 'react-intl'

function Payment() {
  const [error, setError] = useState(null);
  const state = useSelector(state => state);

  //console.log('selectedDateFrom: '+selectedDateFrom);
  //console.log('selectedBoatTypeId: '+selectedBoatTypeId);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function handleContinueWithDebug() {
    handleContinue(true);
  }
  async function handleContinueWithoutDebug() {
    handleContinue(false);
  }

  async function FinalizeReservation() {
    console.log('CART guid: ', state.cart.guid);

    try {
      const response = await Axios.post(window.backendUrl + 'reservation_confirmation', { guid: state.boatSearchBarForm.quoteGuid });
      if (response.status === 200) {
        //setResultList(response.data);
        console.log('Sikeres reservation confirmation');
      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw (response.statusText);
      }
    }
    catch (error) {
      console.error(error);
      throw (error);
    };

  };


  async function handleContinue(withDebug) {

    console.log('Continue in Payment() pressed, debug is: ', withDebug);
    console.log('state is');
    console.log(state);
    var goto = 'error';
    var reqBody = {
      cart: state.cart,
      customerDataInputForm: state.customerDataInputForm,
      boatSearchBarForm: state.boatSearchBarForm,
    };
    try {
      const response = await Axios.post(window.backendUrl + 'save_quote', reqBody);
      console.log("reservation done");
      console.log("response.data is:");
      console.log(response.data);
      if (response.status === 200) {
        console.log('Quote saved successfully');
        dispatch({ type: 'UpdateCartGuid', newCartGuid: response.data });
        goto = 'confirmation';

      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
      await FinalizeReservation();
      if (!withDebug) {
        dispatch({ type: 'Confirmation' });
      }
    }
    catch (error) {
      console.log(error);
      setError(error.message);
      goto = 'error'
    };

    if (!withDebug) {
      dispatch({ type: 'Confirmation' });
      navigate('/' + goto);
    }

  };

  return (
    <div>

      <Card sx={{ borderStyle: 'dotted', borderWidth: '1px', borderColor: 'lightgrey', borderRadius: 2, padding: 3, alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={2} alternativeLabel>
            <Step key='1'>
              <StepLabel>Hajó kiválasztva</StepLabel>
            </Step>
            <Step key='2'>
              <StepLabel>Adja meg az adatait</StepLabel>
            </Step>
            <Step key='3'>
              <StepLabel>Foglalás véglegesítése</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </Card>
      <br></br>

      <Card raised={true} sx={{ borderStyle: 'dotted', borderWidth: '1px', borderColor: 'lightgrey', borderRadius: 2, padding: 0, alignItems: 'center' }}>
        <FormControl>
          <FormLabel>Válasszon fizetési módot</FormLabel>
          <br></br>
          <RadioGroup >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', border: 1, borderRadius: 1 }}>
              <FormControlLabel
                value="banktransfer"
                control={<Radio checked={true} />}
                label=""
                sx={{ align: 'top' }}
              />
              <FormattedMessage id='payment.I_will_decide_later' />
              <br></br>
              <br></br>
              A kollégánk kapcsolatba fog lépni Önnel,<br></br>
              hogy az Ön számára legkedvezőbb fizetési módot megbeszéljék.<br></br>
            </Box>
            <br></br>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', border: 1, borderRadius: 1, justifyContent: 'flex-start' }}>
              <FormControlLabel
                value="card"
                control={<Radio checked={false} />}
                label=""
                sx={{ align: 'top' }}
              />
              <FormattedMessage id='payment.ByCard' />
              <br></br>
              <br></br>
              Ez a fizetési mód jelenleg még nem elérhető.<br></br>
              Kérjük, válasszon másik fizetési módot.<br></br>
            </Box>

            <br></br>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', border: 1, borderRadius: 1, justifyContent: 'flex-start' }}>
              <FormControlLabel
                value="banktransfer"
                control={<Radio checked={false} />}
                label=""
                sx={{ align: 'top' }}
              />
              <FormattedMessage id='payment.ByBankTransfer' />
              <br></br>
              <br></br>
              Kérjük, hogy az előleg összegét az alábbi bankszámlaszámra szíveskedjen befizetni:<br></br>
              12345678-90123456-78901234<br></br>
              A közlemény rovatban adja meg a foglalási számot.<br></br>
            </Box>
          </RadioGroup>
        </FormControl>
        <br></br>
        <br></br>

        <Button
          variant="contained"
          onClick={handleContinueWithoutDebug}
          sx={{ align: 'left', textAlign: 'center', textTransform: 'none', border: 0 }}
        >
          <FormattedMessage id='button.Finalize.Reservation' />
        </Button>
        <DeveloperModeIcon onClick={handleContinueWithDebug} />
      </Card>


    </div >
  )
}

export default Payment
