import React from 'react'

function TermsAndConditions() {
  return (
    <div>
      <h1>This is the "TermsAndConditions" page</h1>
      <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</p>      
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec feugiat nisl pretium fusce id velit ut tortor pretium. Tristique senectus et netus et malesuada. Amet facilisis magna etiam tempor orci eu. Nunc vel risus commodo viverra. Ut etiam sit amet nisl purus. Senectus et netus et malesuada fames ac turpis egestas. Sit amet massa vitae tortor condimentum lacinia quis vel. Nisi lacus sed viverra tellus in. At consectetur lorem donec massa sapien faucibus et. Vitae suscipit tellus mauris a diam maecenas sed. Arcu ac tortor dignissim convallis aenean et. Est sit amet facilisis magna etiam tempor orci eu lobortis. Nascetur ridiculus mus mauris vitae. Sed risus ultricies tristique nulla. Ut etiam sit amet nisl.

Quis lectus nulla at volutpat diam ut venenatis tellus. Mattis aliquam faucibus purus in massa tempor nec feugiat. Dictum at tempor commodo ullamcorper. Quisque non tellus orci ac auctor augue mauris augue. Vel eros donec ac odio tempor. Eu lobortis elementum nibh tellus molestie nunc non blandit. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Iaculis nunc sed augue lacus viverra. Facilisi morbi tempus iaculis urna id. In arcu cursus euismod quis viverra. Enim facilisis gravida neque convallis a cras semper auctor. Elit at imperdiet dui accumsan sit amet. Tincidunt nunc pulvinar sapien et. Habitant morbi tristique senectus et netus et malesuada fames ac. Nullam vehicula ipsum a arcu cursus vitae. Sagittis nisl rhoncus mattis rhoncus urna neque. Diam phasellus vestibulum lorem sed risus. Pharetra et ultrices neque ornare aenean euismod elementum nisi.

Risus at ultrices mi tempus imperdiet. Egestas sed tempus urna et pharetra pharetra massa massa ultricies. Integer feugiat scelerisque varius morbi enim nunc faucibus a. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Gravida rutrum quisque non tellus orci ac auctor augue mauris. Faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam. Dignissim sodales ut eu sem integer vitae justo eget magna. Id aliquet risus feugiat in ante. Velit sed ullamcorper morbi tincidunt ornare massa. Ultricies integer quis auctor elit sed vulputate mi. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut. Suspendisse interdum consectetur libero id faucibus. Nunc sed id semper risus in hendrerit gravida.

Non blandit massa enim nec. Pharetra sit amet aliquam id diam maecenas ultricies mi. Elit eget gravida cum sociis natoque penatibus et magnis dis. Tempus imperdiet nulla malesuada pellentesque elit. Suspendisse interdum consectetur libero id faucibus nisl tincidunt. Commodo odio aenean sed adipiscing diam. Urna nunc id cursus metus. Adipiscing diam donec adipiscing tristique. Feugiat vivamus at augue eget arcu dictum varius duis at. In ante metus dictum at tempor commodo ullamcorper a lacus. Aliquet bibendum enim facilisis gravida neque. Tristique senectus et netus et malesuada fames ac turpis. Ultricies lacus sed turpis tincidunt id aliquet risus. Vivamus arcu felis bibendum ut tristique et. Cursus sit amet dictum sit. Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada. At varius vel pharetra vel turpis nunc eget lorem dolor. Velit scelerisque in dictum non. Eu consequat ac felis donec et odio pellentesque diam volutpat.

Malesuada pellentesque elit eget gravida cum sociis natoque. Nunc sed id semper risus in. Nulla aliquet enim tortor at auctor urna. Quisque id diam vel quam elementum pulvinar etiam non. Egestas dui id ornare arcu. Neque vitae tempus quam pellentesque nec nam aliquam. Tincidunt praesent semper feugiat nibh sed. Et sollicitudin ac orci phasellus egestas. Sed euismod nisi porta lorem mollis aliquam ut porttitor leo. Fringilla urna porttitor rhoncus dolor purus non. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada nunc. Suspendisse in est ante in. Integer eget aliquet nibh praesent tristique magna sit amet purus.

Aliquet lectus proin nibh nisl condimentum id venenatis a. Consectetur a erat nam at lectus urna duis. Vitae tortor condimentum lacinia quis vel eros donec ac. Nunc pulvinar sapien et ligula ullamcorper malesuada proin libero nunc. Pharetra pharetra massa massa ultricies. Mattis vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor. Magna ac placerat vestibulum lectus mauris. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Mollis aliquam ut porttitor leo a diam sollicitudin. Integer feugiat scelerisque varius morbi enim. Risus feugiat in ante metus dictum. Facilisis sed odio morbi quis commodo odio aenean sed.

Ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus. Ultrices neque ornare aenean euismod elementum nisi quis. Adipiscing bibendum est ultricies integer quis auctor elit sed. Neque gravida in fermentum et sollicitudin ac orci phasellus. Sed odio morbi quis commodo odio aenean sed adipiscing. Nisl tincidunt eget nullam non nisi est. Euismod in pellentesque massa placerat duis ultricies lacus sed. Nulla at volutpat diam ut venenatis. Aenean sed adipiscing diam donec adipiscing tristique risus nec feugiat. Leo duis ut diam quam nulla porttitor massa id. Velit egestas dui id ornare arcu. Tincidunt augue interdum velit euismod in pellentesque massa. Interdum posuere lorem ipsum dolor sit. Porttitor lacus luctus accumsan tortor posuere ac ut consequat. Senectus et netus et malesuada. Urna et pharetra pharetra massa massa ultricies. Pharetra et ultrices neque ornare aenean euismod elementum. Nulla facilisi cras fermentum odio eu feugiat pretium nibh. Porttitor eget dolor morbi non. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae.

Vestibulum lorem sed risus ultricies tristique nulla. Nunc id cursus metus aliquam eleifend. Nibh venenatis cras sed felis eget velit aliquet sagittis. Maecenas ultricies mi eget mauris pharetra et ultrices. Orci a scelerisque purus semper. Est placerat in egestas erat imperdiet sed euismod nisi porta. Pulvinar sapien et ligula ullamcorper malesuada proin libero nunc consequat. Tellus at urna condimentum mattis pellentesque. Integer feugiat scelerisque varius morbi enim nunc. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum. Libero nunc consequat interdum varius sit. In metus vulputate eu scelerisque felis imperdiet proin fermentum. Nullam non nisi est sit amet facilisis magna etiam tempor. In fermentum et sollicitudin ac orci phasellus. Eu sem integer vitae justo eget. Volutpat maecenas volutpat blandit aliquam etiam. Suspendisse faucibus interdum posuere lorem ipsum. Sed viverra tellus in hac habitasse.

At urna condimentum mattis pellentesque id nibh. Sit amet massa vitae tortor condimentum lacinia quis vel. Eu facilisis sed odio morbi quis commodo odio aenean. Cursus sit amet dictum sit amet justo donec enim. Et sollicitudin ac orci phasellus egestas tellus. In mollis nunc sed id semper risus in hendrerit gravida. Lacus vel facilisis volutpat est. Vitae elementum curabitur vitae nunc sed velit dignissim. Leo urna molestie at elementum eu facilisis sed. Pellentesque eu tincidunt tortor aliquam nulla. Amet venenatis urna cursus eget nunc scelerisque. Amet tellus cras adipiscing enim eu turpis egestas pretium. Quam quisque id diam vel quam elementum. Enim diam vulputate ut pharetra sit. Nunc congue nisi vitae suscipit. Turpis in eu mi bibendum neque. Diam ut venenatis tellus in metus vulputate eu scelerisque. At consectetur lorem donec massa sapien faucibus et. Egestas quis ipsum suspendisse ultrices gravida dictum.

Quam pellentesque nec nam aliquam sem et tortor consequat id. Urna neque viverra justo nec ultrices dui sapien eget. Non arcu risus quis varius quam quisque id diam. Magna etiam tempor orci eu lobortis elementum nibh. A scelerisque purus semper eget duis at tellus at urna. Duis tristique sollicitudin nibh sit amet. In pellentesque massa placerat duis ultricies. Porttitor eget dolor morbi non arcu risus quis. Malesuada fames ac turpis egestas sed. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui. Mauris nunc congue nisi vitae suscipit tellus mauris. Urna molestie at elementum eu. Sed blandit libero volutpat sed cras ornare arcu dui vivamus. Laoreet id donec ultrices tincidunt arcu non sodales. Eu non diam phasellus vestibulum lorem.      
    </div>
  )
}

export default TermsAndConditions
