import React, { useState, useEffect, useCallback } from "react";
import Carousel from 'react-material-ui-carousel'
import CarouselCard from "../Pages/CarouselCard";
import Axios from "axios";

function PictureCarousel() {
  console.log('PictureCarousel()');
  const [carouselResultList, setCarouselResultList] = useState([]);   
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); 
  
  const fetchCarouselPicturesHandler = useCallback( async () => {
    setCarouselResultList([])
    setIsLoading(true);
    setError(null);

    try{
      const response=await Axios.post(window.backendUrl+'query_carousel');
      if(response.status === 200){
        setCarouselResultList(response.data);
      }
      else{
        console.log("Error! Response of Axio post is not 200, but "+response.status+" "+response.statusText);    
        throw new Error('Something went wrong');
      }  
    }
    catch(error){
      console.log(error);
      setError(error.message);
  
    };
    setIsLoading(false);
  },[]);

  useEffect(() => {
    fetchCarouselPicturesHandler();
  },[fetchCarouselPicturesHandler])


  let content = <p>Found no pictures</p>;

  if(carouselResultList.length > 0){
    content=
      <Carousel
        navButtonsAlwaysVisible = 'true'
        animation = 'slide'
        autoPlay = 'false'
        interval = '600000'
      >
      {carouselResultList.map((val)=> (<CarouselCard key={val.id} product={val}/> ) )}        
      </Carousel>    
  }

  if(error){
    content=<p>{error}</p>
  }

  if(isLoading){
    content=<p>Loading...</p>
  }

  return (
    <div align='middle'>
    <button onClick={fetchCarouselPicturesHandler}>(Refresh pictures)</button>       
      {content}
    </div>
  )
}

export default PictureCarousel;
