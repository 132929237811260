
// import './App.css';
import { React, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch, useStore } from 'react-redux';
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Home from "../Pages/Home";
import SearchPage from "../Pages/SearchPage";
import LoginPage from "../Pages/LoginPage";
import TermsAndConditions from "../Pages/TermsAndConditions";
import CookiePolicies from "../Pages/CookiePolicies";
import PageNotFound from "../Pages/PageNotFound";
import CarouselPage from '../Pages/CarouselPage';
import DetailsPage from '../Pages/DetailsPage';
import BoatDetailsPage from '../Pages/BoatDetailsPage';
import BoatCalendarPage from '../Pages/BoatCalendarPage';
import CustomerDataInput from '../Pages/CustomerDataInput';
import Payment from '../Pages/Payment';
import Confirmation from '../Pages/Confirmation';
import Orderdetails from '../Pages/Orderdetails';
import Info from '../Pages/Info';
import Error from '../Pages/Error';
import Faq from '../Pages/FaqPage';
import ProfileReservations from '../Pages/ProfileReservationsPage';
import { LoginContext } from "./Helper/Context";
import jwt_decode from "jwt-decode";
import { IntlProvider } from 'react-intl'
import { Navbar } from '../components/Navbar';
import { GoogleOAuthProvider, useGoogleLogin, CredentialResponse } from '@react-oauth/google';
import DialogAll from "../components/DialogAll";
import { DialogUseResetPasswordLink } from '../components/DialogUseResetPasswordLink';
import DialogMixedLogin from '../components/DialogMixedLogin';
import LoadCharterDefaults from '../tools/LoadCharterDefaults';
import SinglePageCalendar from '../Pages/SinglePageCalendar';
import SinglePageCustomer from '../Pages/SinglePageCustomer';
import SinglePageConfirmation from '../Pages/SinglePageConfirmation';
import Usageagreement from '../Pages/Usageagreement';
import Privacypolicy from '../Pages/Privacypolicy';
import Consent from '../Pages/Consent';
import ScrollToTop from '../components/ScrollToTop';
import FakeDoor from '../components/FakeDoor';

import { useAnalytics } from '../useAnalytics';

function Root() {
  console.log('App.Root()');


  //const dispatch = useDispatch();  
  //const [searchParams] = useSearchParams();
  // console.log('searchParams:');
  // console.log(...searchParams);
  /*
    const CHARTER_PATTERN = 'setcharter';
    if(searchParams.get(CHARTER_PATTERN)){
      const charter = searchParams.get(CHARTER_PATTERN);
      console.log('charter is: ', charter);
      //dispatch({ type: 'SetForcedCharterGuid', payload: charter }); 
      //LoadCharterDefaults(charter);    
    }
    
    const BOAT_PATTERN = 'setboat';
    if(searchParams.get(BOAT_PATTERN)){
      const boat = searchParams.get(BOAT_PATTERN);
      console.log('boat is: ', boat);
      //dispatch({ type: 'SetForcedBoatGuid', payload: boat }); 
    }
    */
  //const MyNavbar = Navbar();
  //const MyDialogAll = DialogAll();
  //const MyDialogMixedLogin = DialogMixedLogin();
  //const MyDialogRegister = DialogRegister();
  //const MyDialogEmailLogin = DialogEmailLogin();
  //const MyDialogUpdateAccount = DialogUpdateAccount();

  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  //console.log('searchParams', searchParams);
  //const quoteId = searchParams.get('quoteid')
  //console.log('quoteid is:');
  //console.log(quoteId);
  //dispatch({type: 'quoteId', quoteId});    

  //Google

  function handleCallbackResponse(response) {
    console.log("Encoded JWT ID token: " + response.credential);
    var userObject = jwt_decode(response.credential);
    console.log(userObject);
    setUser(userObject);
    document.getElementById("signInDiv").hidden = true;
  }

  function handleSignOut(e) {
    setUser({});
    document.getElementById("signInDiv").hidden = false;
  }

  const responseGoogle = (response) => {
    console.log(response);
    const userObject = jwt_decode(response.credential);
    console.log(userObject);
    localStorage.setItem('user', JSON.stringify(userObject));
    const { name, sub, picture } = userObject;
    const doc = {
      _id: sub,
      _type: 'user',
      userName: name,
      image: picture,
    };
    /*
    client.createIfNotExists(doc).then(() => {
      navigate('/', { replace: true });
    });
   */
  }
  const [openDialogMixedLogin, setOpenDialogMixedLogin] = useState(false);
  // http://localhost:3000/?setcharter=3b40723b-4e8c-4e83-b1f4-4c7ab2ed6925&setboat=65d6d34e-2df1-494a-85ac-c1cccd185af3  
  const forcedCharterGuid = useSelector(state => state.forcedCharterGuid);
  const forcedBoatGuid = useSelector(state => state.forcedBoatGuid);
  //  console.log('beolvasva charter : ', forcedCharterGuid);
  //  console.log('beolvasva boat: ', forcedBoatGuid);

  const singlePage = forcedCharterGuid && forcedBoatGuid;
  const fakedooronly = true;
  useAnalytics();

  const dispatch = useDispatch();
  
  const changeUILng = (lng) => {
    dispatch({ type: 'changeUILngByBrowser', payload: lng });
  };

  var userLang = navigator.language || navigator.userLanguage;
  changeUILng(userLang);

  return (
    <div>
      {Boolean(fakedooronly) &&
        <>
          <FakeDoor />
          <ScrollToTop />
        </>
      }
      {!Boolean(fakedooronly) &&
        <>
          <GoogleOAuthProvider clientId="921867755490-jijag9p4nup7mq35fqkuj9coo7e26e5d.apps.googleusercontent.com">
            <DialogAll
              displayNavbar={!singlePage} />
            <Routes>
              {singlePage &&
                <><Route path="/" exact element={<SinglePageCalendar
                  charterGuid={forcedCharterGuid}
                  boatGuid={forcedBoatGuid} />} />
                  <Route path="/spcustomer" exact element={<SinglePageCustomer />} />
                  <Route path="/spconfirmation" exact element={<SinglePageConfirmation />} />
                  <Route path="/error" element={<Error />} />
                </>}
              {!singlePage &&
                <>
                  <Route path="/" exact element={<Home />} />
                  <Route path="/home" exact element={<Home />} />
                  <Route path="/aboutus" exact element={<About />} />
                  <Route path="/contact" exact element={<Contact />} />
                  <Route path="/search" exact element={<SearchPage />} />
                  <Route path="/termsandconditions" exact element={<TermsAndConditions />} />
                  <Route path="/cookiepolicies" exact element={<CookiePolicies />} />
                  <Route path="/login" exact element={<LoginPage />} />
                  <Route path="/carousel" exact element={<CarouselPage />} />
                  <Route path="/boatdetails_old" exact element={<DetailsPage />} />
                  <Route path="/boatdetailspage" exact element={<BoatDetailsPage />} />
                  <Route path="/calendar" element={<BoatCalendarPage />} />
                  <Route path="/customerDataInput" exact element={<CustomerDataInput />} />
                  <Route path="/payment" exact element={<Payment />} />
                  <Route path="/confirmation" exact element={<Confirmation />} />
                  <Route path="/orderdetails" exact element={<Orderdetails />} />
                  <Route path="/services" exact element={<Home />} />
                  <Route path="/getaquote" exact element={<Home />} />
                  <Route path="/impressum" exact element={<Home />} />
                  <Route path="/info" exact element={<Info />} />
                  <Route path="/function" element={<Home />} />
                  <Route path="/error" element={<Error />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/consent" element={<Consent />} />
                  <Route path="/privacypolicy" element={<Privacypolicy />} />
                  <Route path="/usageagreement" element={<Usageagreement />} />
                  <Route path="/profilereservations" element={<ProfileReservations />} />
                </>}
              <Route path="*" exact element={<PageNotFound />} />
            </Routes>
          </GoogleOAuthProvider>
          <ScrollToTop />
        </>
      }
    </div>
  )
}

export default function App() {
  console.log('This is App()');

  const lng = useSelector(store => store.language);
  const msg = useSelector(store => store.message)


  return (
    <IntlProvider locale={lng} messages={msg}>
      <Router basename={window.frontendBaseUrl}>
        <Root />
      </Router>
    </IntlProvider>
  );
}

