import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HajoType from '../types/HajoType'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CssBaseline, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import WifiIcon from '@mui/icons-material/Wifi';
import PercentIcon from '@mui/icons-material/Percent';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ReactCountryFlag from 'react-country-flag';
//import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
//import yellowtail from 'typeface-yellowtail';
import BoatDetailsAlapadatok from "./BoatDetailsAlapadatok";
import BoatDetailsFelszereltseg from "./BoatDetailsFelszereltseg";
import BoatDetailsLeiras from "./BoatDetailsLeiras";
import BoatDetailsAdvantages from "./BoatDetailsAdvantages";
import BoatDetailsCabins from "./BoatDetailsCabins";
import BoatDetailsFreeslots from "./BoatDetailsFreeslots";
import BoatDetailsReservationdetails from "./BoatDetailsReservationdetails";
import BoatDetailsExtras from "./BoatDetailsExtras";
import BoatDetailsFurtherdetails from "./BoatDetailsFurtherdetails";
import BoatDetailsFurtherboats from "./BoatDetailsFurtherboats";
import BoatDetailsFaq from "./BoatDetailsFaq";


//import '../styles/figma.css';

import RootState from '../types/RootState';




const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3', // Change primary color
    },
    secondary: {
      main: '#FF5722', // Change secondary color
    },

  },

  typography: {
    // fontFamily: 'Sans-serif', // Change default font
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    h2: {
      fontSize: 12,
      fontWeight: 600
    },
    h3: {
      fontSize: 20,
      fontWeight: 800,
      color: '#6AB2BC',
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,
      color: '#6AB2BC',
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 500,
      backgroundColor: '#FFD600',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
      color: 'white',
      backgroundColor: 'black',
    },
    body1: {
      fontSize: 12,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 700
    },
  },
});

interface MyProps {
  hajo: HajoType,
  dateFrom?: Date,
  dateTo?: Date,

}
function d2s(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + String(date.getMonth() + 1)).slice(-2);
  const day = ('0' + String(date.getDate())).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}


const isMobile = window.innerWidth <= 768;

function BoatDetailsAll() {
  //console.log('BoatDetailsAll() called');
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  const HandleDescriptionClick = (id: number) => {
    dispatch({ type: 'selectBoatId', selectedBoatId: id });
    navigate('/boatdetailspage');
  };

  const displayPrice = (price: number) => {
    var x;
    if (price > 0) {
      x = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'HUF',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(price);
      // x = price+' Ft';
    }
    else {
      x = 'Az árról érdeklődjön munkatársunknál';
    }
    return (x);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const css = `

  .bovebben  {
    width: 100%;
    padding: 0px;
    margin: 0px;
    text-align: right;
    background-color: white;
    border-collapse: collapse;
    border: 0;
  },
  .bovebben button {
    background-color: white;

  }
  .tableClassWifi  {
    width: 100%;
    padding: 0px;
    margin: 0px;
    text-align: center;
    background-color: white;
    border-collapse: collapse;
    border: 0;
  },

  .customBlue {
    color: white;
    background:'#5382E9';
  }
  `;


  //console.log('BoatCardSmall hajo:', props);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style>
        {css}
      </style>
      <div>
        <BoatDetailsAlapadatok />
        <BoatDetailsFelszereltseg />
        <Box
          sx={{
            display: 'grid',
            gridAutoFlow: isMobile ? 'row' : 'column',
            gridAutoColumns: '1fr'
          }}
        >
          <BoatDetailsLeiras />
          <BoatDetailsAdvantages />
        </Box>
        <BoatDetailsCabins />
        <BoatDetailsFreeslots />
        <BoatDetailsReservationdetails />
        <BoatDetailsExtras />
        <BoatDetailsFurtherdetails />
        <BoatDetailsFaq />
        <BoatDetailsFurtherboats />
      </div>
    </ThemeProvider>
  );
}

export default BoatDetailsAll;

