import React from 'react'

function Usageagreement() {
	return (
		<div>
			<h1>Felhasználási feltételek</h1>
		</div>
	)
}
export default Usageagreement
