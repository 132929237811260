import { createStore } from "redux";
import messages_hu from '../translations/hu.json';
import messages_en from '../translations/en.json';
import messages_hr from '../translations/hr.json';
import KikotoListType from '../types/KikotoListType';
import KiadoListType from '../types/KiadoListType';
import { startOfDay, format, isSameDay, isAfter, isBefore, endOfDay } from 'date-fns';
import { emptyQuote } from "../types/DatabaseTypes";

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function d2s(date) {
  var formattedDate = '';
  if (date) {
    const year = date.getFullYear();
    const month = ('0' + String(date.getMonth() + 1)).slice(-2);
    const day = ('0' + String(date.getDate())).slice(-2);
    formattedDate = `${year}-${month}-${day}`;
  }
  return formattedDate;
}

// nem lehet async!!
const loginReducer = (state = {}, action) => {
  //console.log(action);
  //console.log('loginReducer action.type: ', action.type);
  //console.log('action: ', action);
  switch (action.type) {
    case 'SetForcedCharterGuid': {
      //console.log('SetForcedCharterGuid:', action.payload)
      state = { ...state, forcedCharterGuid: action.payload }
      break;
    }
    case 'SetForcedBoatGuid': {
      //console.log('SetForcedBoatGuid:', action.payload)
      state = { ...state, forcedBoatGuid: action.payload }
      break;
    }
    case 'BoatSearchBarFormValues': {
      state.boatSearchBarForm = { ...state.boatSearchBarForm, dateFrom: new Date(action.boatSearchBarForm.dateFrom) }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, dateTo: new Date(action.boatSearchBarForm.dateTo) }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, timeFrom: action.boatSearchBarForm.timeFrom }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, timeTo: action.boatSearchBarForm.timeTo }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, reservationDurationType: action.boatSearchBarForm.reservationDurationType }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, destination: action.boatSearchBarForm.destination }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, charter: action.boatSearchBarForm.charter }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, boatType: action.boatSearchBarForm.boatType }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, withSkipper: action.boatSearchBarForm.withSkipper }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, quoteGuid: '' }
      break;
    }
    case 'CustomerDataInputFormValues': {
      state.customerDataInputForm = { ...state.customerDataInputForm, lastname: action.customerDataInputForm.lastname }
      state.customerDataInputForm = { ...state.customerDataInputForm, firstname: action.customerDataInputForm.firstname }
      state.customerDataInputForm = { ...state.customerDataInputForm, phone: action.customerDataInputForm.phone }
      state.customerDataInputForm = { ...state.customerDataInputForm, email: action.customerDataInputForm.email.trim().toLowerCase() }
      state.customerDataInputForm = { ...state.customerDataInputForm, comment: action.customerDataInputForm.comment }
      state.customerDataInputForm = { ...state.customerDataInputForm, withSkipper: action.customerDataInputForm.withSkipper }
      state.customerDataInputForm = { ...state.customerDataInputForm, isValid: action.customerDataInputForm.isValid }
      break;
    }

    case 'ClearRegisterFormValues': {
      state.registerForm = { ...state.registerForm, lastname: '' }
      state.registerForm = { ...state.registerForm, firstname: '' }
      state.registerForm = { ...state.registerForm, phone: '' }
      state.registerForm = { ...state.registerForm, email: '' }
      state.registerForm = { ...state.registerForm, password: '' }
      state.registerForm = { ...state.registerForm, password2: '' }
      break;
    }
    case 'RegisterFormValues': {
      state.registerForm = { ...state.registerForm, lastname: action.registerForm.lastname }
      state.registerForm = { ...state.registerForm, firstname: action.registerForm.firstname }
      state.registerForm = { ...state.registerForm, phone: action.registerForm.phone }
      state.registerForm = { ...state.registerForm, email: action.registerForm.email }
      state.registerForm = { ...state.registerForm, password: action.registerForm.password }
      state.registerForm = { ...state.registerForm, password2: action.registerForm.password2 }
      break;
    }

    case 'PutProfileDataIntoForm': {
      //console.log('currentuser: ', state.currentUser)
      state.form = { ...state.form, email: state.currentUser.ux_email }
      state.form = { ...state.form, lastname: state.currentUser.ux_family_name }
      state.form = { ...state.form, firstname: state.currentUser.ux_given_name }
      state.form = { ...state.form, phone: state.currentUser.ux_phone }
      break;
    }
    case 'PutAccountDataIntoForm': {
      //console.log('state.currentUser:');
      //console.log(state.currentUser);
      state.formBerloAdatok = action.berloAdatok;
      state.formBerloAdatok = { ...state.formBerloAdatok, email: state.currentUser.ux_email } // az emailt nem a BERLO_ADATOK táblából vesszük
      break;
    }
    case 'FormValues': {
      state.form = { ...state.form, email: action.form.email }
      state.form = { ...state.form, password: action.form.password }
      state.form = { ...state.form, lastname: action.form.lastname }
      state.form = { ...state.form, firstname: action.form.firstname }
      state.form = { ...state.form, phone: action.form.phone }
      break;
    }
    case 'FormValuesBerloAdatok': {
      state.formBerloAdatok = { ...state.formBerloAdatok, nev: action.form.nev }
      state.formBerloAdatok = { ...state.formBerloAdatok, telefonszam: action.form.telefonszam }
      state.formBerloAdatok = { ...state.formBerloAdatok, cim_irsz: action.form.cim_irsz }
      state.formBerloAdatok = { ...state.formBerloAdatok, cim_varos: action.form.cim_varos }
      state.formBerloAdatok = { ...state.formBerloAdatok, cim_utca: action.form.cim_utca }
      state.formBerloAdatok = { ...state.formBerloAdatok, cim_hsz: action.form.cim_hsz }
      state.formBerloAdatok = { ...state.formBerloAdatok, szamlazasi_irsz: action.form.szamlazasi_irsz }
      state.formBerloAdatok = { ...state.formBerloAdatok, szamlazasi_varos: action.form.szamlazasi_varos }
      state.formBerloAdatok = { ...state.formBerloAdatok, szamlazasi_utca: action.form.szamlazasi_utca }
      state.formBerloAdatok = { ...state.formBerloAdatok, szamlazasi_hsz: action.form.szamlazasi_hsz }
      state.formBerloAdatok = { ...state.formBerloAdatok, adoszam: action.form.adoszam }
      state.formBerloAdatok = { ...state.formBerloAdatok, szemely_ig: action.form.szemely_ig }
      state.formBerloAdatok = { ...state.formBerloAdatok, vezetoi_engedely: action.form.vezetoi_engedely }
      state.formBerloAdatok = { ...state.formBerloAdatok, tipus: action.form.tipus }
      state.formBerloAdatok = { ...state.formBerloAdatok, cegnev: action.form.cegnev }
      state.formBerloAdatok = { ...state.formBerloAdatok, csoportos_adoszam: action.form.csoportos_adoszam }
      state.formBerloAdatok = { ...state.formBerloAdatok, kozossegi_adoszam: action.form.kozossegi_adoszam }
      state.formBerloAdatok = { ...state.formBerloAdatok, bank: action.form.bank }
      state.formBerloAdatok = { ...state.formBerloAdatok, iban_bankszamla: action.form.iban_bankszamla }
      state.formBerloAdatok = { ...state.formBerloAdatok, szamlazasi_levelezesi_egyezik: action.form.szamlazasi_levelezesi_egyezik }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_nev: action.form.vez_nev }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_email: action.form.vez_email }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_telefon: action.form.vez_telefon }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_szemely_ig: action.form.vez_szemely_ig }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_vezeto_engedely: action.form.vez_vezeto_engedely }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_irsz: action.form.vez_irsz }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_varos: action.form.vez_varos }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_utca: action.form.vez_utca }
      state.formBerloAdatok = { ...state.formBerloAdatok, vez_hsz: action.form.vez_hsz }
      break;
    }

    case 'LoginWithGoogle': {
      //console.log('Reducer: LoginWithGoogle: ' + action.googleUser.email)
      state = { ...state, loggedIn: true }
      state = { ...state, email: action.googleUser.email }
      state = { ...state, name: action.googleUser.name }
      state = { ...state, email_verified: action.googleUser.email_verified }
      state = { ...state, locale: action.googleUser.locale }
      state = { ...state, family_name: action.googleUser.family_name }
      state = { ...state, given_name: action.googleUser.given_name }
      state = { ...state, picture: action.googleUser.picture }
      state = { ...state, sub: action.googleUser.sub }
      break;
    }
    case 'set_UX_ID': {
      //console.log('Reducer: set_UX_ID: ' + action.ux_id)
      state = { ...state, ux_id: action.ux_id }
      break;
    }

    case 'selectBoatId': {
      //console.log('Reducer: Select boat ID: ' + action.selectedBoatId)
      state.boatSearchBarForm = { ...state.boatSearchBarForm, boatId: action.selectedBoatId }
      break;
    }
    case 'clearBoatId': {
      //console.log('Reducer: Clear boat ID');
      state.boatSearchBarForm = { ...state.boatSearchBarForm, boatId: 0 }
      break;
    }

    case 'selectLanguageHU': {
      state = { ...state, language: 'hu' }
      state = { ...state, message: messages_hu }
      break;
    }
    case 'selectLanguageEN': {
      state = { ...state, language: 'en' }
      state = { ...state, message: messages_en }
      break;
    }
    case 'selectLanguageHR': {
      state = { ...state, language: 'hr' }
      state = { ...state, message: messages_hr }
      break;
    }
    case 'quoteId': {
      state = { ...state, quoteId: action.quoteId }
      break;
    }
    case 'selectedQuote': {
      //console.log('new selectedQuote, new quote_id is: ');
      //console.log(action.selectedQuote.quote.quote_id);
      //state = { ...state, selectedQuote: action.selectedQuote }
      break;
    }
    case 'selectedHajo': {
      //console.log('new selectedHajo, new HAJO data is: ');
      //console.log(action.selectedHajo.id);
      //console.log(action.payload);
      state = { ...state, selectedHajo: action.payload }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, boatId: action.payload.id }
      break;
    }

    case 'LoginWithEmail': {
      //console.log('Reducer: LoginWithEmail: ' + action.currentUser.ux_email);
      action.currentUser.loggedIn = true;
      state = { ...state, currentUser: action.currentUser };
      state = { ...state, language: action.currentUser.ux_locale };
      state.cart = { ...state.cart, ux_email: action.currentUser.ux_email };
      state.cart = { ...state.cart, ux_family_name: action.currentUser.ux_family_name };
      state.cart = { ...state.cart, ux_given_name: action.currentUser.ux_given_name };
      state.cart = { ...state.cart, ux_phone: action.currentUser.ux_phone };
      state.form = { ...state.form, email: action.currentUser.ux_email };
      state.customerDataInputForm = { ...state.customerDataInputForm, lastname: action.currentUser.ux_family_name }
      state.customerDataInputForm = { ...state.customerDataInputForm, firstname: action.currentUser.ux_given_name }
      state.customerDataInputForm = { ...state.customerDataInputForm, phone: action.currentUser.ux_phone }
      state.customerDataInputForm = { ...state.customerDataInputForm, email: action.currentUser.ux_email }
      break;
    }
    case 'LogoutNow': {
      //console.log('Reducer: LogoutNow');
      state = initialState;
      break;
    }
    case 'Confirmation': {
      //console.log('Reducer: Confirmation');
      state = { ...state, cart: emptyCart };
      state = { ...state, boatSearchBarForm: emptyBoatSearchBarForm };
      if (state.currentUser.loggedIn) {
        state.customerDataInputForm = { ...state.customerDataInputForm, comment: '' };
      } else {
        state = { ...state, customerDataInputForm: emptyCustomerDataInputForm };
      }
      break;
    }
    case 'UpdateCart': {
      //console.log('Reducer: UpdateCart');
      //console.log(action.newCart);
      state = { ...state, cart: action.newCart };
      break;
    }
    case 'UpdateCartGuid': {
      //console.log('Reducer: UpdateCartGuid');
      //console.log(action.newCartGuid);
      state.boatSearchBarForm = { ...state.boatSearchBarForm, quoteGuid: action.newCartGuid }
      break;
    }
    case 'calendarSelectDateFrom': {
      //console.log('Reducer: calendarSelectDateFrom: ' + action.dateFrom)
      state.boatSearchBarForm = { ...state.boatSearchBarForm, dateFrom: new Date(action.dateFrom) }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, reservationDurationType: 'N' }
      break;
    }
    case 'calendarSelectDateTo': {
      //console.log('Reducer: calendarSelectDateTo: ' + action.dateTo)
      state.boatSearchBarForm = { ...state.boatSearchBarForm, dateTo: new Date(action.dateTo) }
      state.boatSearchBarForm = { ...state.boatSearchBarForm, reservationDurationType: 'N' }
      break;
    }
    case 'clearSelectedBoatType': {
      //console.log('Reducer: clearSelectedBoatType: ')
      state.boatSearchBarForm = { ...state.boatSearchBarForm, boatType: '' }
      break;
    }
    case 'KikotoList': {
      state = { ...state, kikotoList: action.payload }
      break;
    }
    case 'KiadoList': {
      state = { ...state, kiadoList: action.payload }
      break;
    }
    case 'SetCharterDefaults': {
      state = { ...state.charterDefaults, charterDefaults: action.payload }
      break;
    }
    case 'SetTestMode': {
      //console.log('Reducer: SetTestMode: ');
      state = { ...state, testMode: true }
      break;
    }
    case 'ClearTestMode': {
      //console.log('Reducer: ClearTestMode: ');
      state = { ...state, testMode: false }
      break;
    }
    case 'Database': {
      //console.log('Reducer: Database: ', action.payload);
      state = { ...state, database: action.payload }
      break;
    }
    case 'SetHajoAr': {
      //console.log('Reducer: SetHajoAr: ', action.payload);
      state = { ...state, hajoAr: action.payload }
      break;
    }
    case 'SetKapitanyAr': {
      //console.log('Reducer: SetKapitanyAr: ', action.payload);
      state = { ...state, kapitanyAr: action.payload }
      break;
    }
    case 'SetPricing': {
      //console.log('Reducer: SetPricing: ', action.payload);
      state.pricing = { ...state.pricing, totalDays: action.payload.totalDays }
      state.pricing = { ...state.pricing, totalHours: action.payload.totalHours }

      var day_0 = new Date();
      //console.log('Day 0: ', d2s(day_0));
      var day_1 = new Date();  // eddig ingyenes lemondas + foglalo veszik, kombinált
      var day_1a = new Date();  // eddig ingyenes lemondas
      var day_1b = new Date();  // ezutan a foglalo veszik
      var day_2 = new Date();  // ezutan a teljes veszik
      var day_3 = state.boatSearchBarForm.dateFrom;

      if (state.selectedHajo) {
        if (state.selectedHajo.lemondhato) {
          day_1a = addDays(day_0, state.selectedHajo.lemondhato);
        }
        if (state.selectedHajo.foglalo_veszik) {
          day_1b = addDays(day_3, -(state.selectedHajo.foglalo_veszik));
        }
        day_1 = day_1b;
        if (isBefore(day_1a, day_1)) {
          day_1 = day_1a;
        }
        if (isBefore(day_1a, day_0)) {
          day_1 = day_0;
        }
        if (isBefore(day_1, day_0)) {
          day_1 = day_0; // nehogy visszamenjünk a mai napnál régebbre
        }
        if (state.selectedHajo.teljes_veszik) {
          day_2 = addDays(day_3, -(state.selectedHajo.teljes_veszik));
        }
        if (isBefore(day_2, day_0)) {
          day_2 = day_0; // nehogy visszamenjünk a mai napnál régebbre
        }
        if (isBefore(day_3, day_0)) {
          day_3 = day_0; // nehogy visszamenjünk a mai napnál régebbre
        }
      }

      //console.log('Day 1a: ', d2s(day_1a));
      //console.log('Day 1b: ', d2s(day_1b));
      //console.log('Day 1: ', d2s(day_1));
      //console.log('Day 2: ', d2s(day_2));
      //console.log('Day 3: ', d2s(day_3));
      state.pricing = { ...state.pricing, day_0: day_0 }
      state.pricing = { ...state.pricing, day_1: day_1 }
      state.pricing = { ...state.pricing, day_2: day_2 }
      state.pricing = { ...state.pricing, day_3: day_3 }
      break;
    }
    case 'CalcFizetesiUtemezes': {
      //console.log('Reducer: CalcFizetesiUtemezes');
      break;
    }
    case 'FaqList': {
      state = { ...state, faqList: action.payload }
      break;
    }
    case 'ServiceList': {
      //console.log('Store update / ServiceList:');
      //console.log(action.payload);
      state = { ...state, serviceList: action.payload }
      // Figyelem! Az utólagos item.virtual_url értékadás miért módosítja a "state" értékét?!
      //console.log('window.location.href: ', window.location.href);
      //console.log('window: ', window);
      //console.log('window.frontendBaseUrl: ', window.frontendBaseUrl); // set in index.js
      action.payload.map((item) => {
        item.id_hash = 'service_id_' + item.id
        //console.log('item.id_hash:', item.id_hash);
        // ezt kommwentwztwm ki 2023-11-07-én window.location.hash = item.id_hash;
        //console.log('window.location.hash:', window.location.hash);
        item.virtual_url = window.location.href;
        //console.log('item.virtual_url: ', item.virtual_url);
      });
      break;
    }
    case 'DocumentTemplateList': {
      state = { ...state, documentTemplateList: action.payload }
      break;
    }
    case 'Quote': {
      if (action.payload === undefined) {
        console.log('Reducer: new (empty) Quote: ', action.payload);
        state = { ...state, quote: emptyQuote };
      }
      else {
        console.log('Reducer: new Quote: ', action.payload);
        state = { ...state, quote: action.payload };
      }
      break;
    }

    case 'FakeDoorServiceList': {
      state = { ...state, fakeDoorServiceList: action.payload }
      action.payload.map((item) => {
      });
      break;
    }

    case 'changeUILng': {
      var lng;
      console.log('Browser lng is: ', action.payload);
      if (action.payload?.startsWith('hu')) {
        lng = 'hu';
        state = { ...state, message: messages_hu }
      }
      else if (action.payload?.startsWith('hr')) {
        lng = 'hr';
        state = { ...state, message: messages_hr }
      }
      else {
        lng = 'en';
        state = { ...state, message: messages_en }
      }
      state = { ...state, language: lng }
      break;
    }
    case 'changeUILngByBrowser': {
      if (!state.firstLngSet) {
        var lng;
        if (action.payload?.startsWith('hu')) {
          lng = 'hu';
          state = { ...state, message: messages_hu }
        }
        else if (action.payload?.startsWith('hr')) {
          lng = 'hr';
          state = { ...state, message: messages_hr }
        }
        else {
          lng = 'en';
          state = { ...state, message: messages_en }
        }
        state = { ...state, language: lng }
        state = { ...state, firstLngSet: true }
        console.log('Inital UI language set to: ', lng, '(', action.payload ?? '', ')');
      }
      else {
        console.log('No more UI lng change');
      }
      break;
    }


    default:
      //@@redux/INITd.e.h.n.q
      if (action.type.startsWith('@@redux/INIT')) {
        console.log('Redux init: ', action.type);
      }
      else {
        console.error('Reduced called with unhandled parameter ', action.type);
      }
      break;
  }
  console.log('NEW STATE OF REDUCER (Action.type: ', action.type, ')');
  console.log(state);
  return { ...state };
};



const emptyUser = {
  loggedIn: false,
  ux_email: '',
  ux_name: '',
  ux_family_name: '',
  ux_given_name: '',
  ux_locale: 'hu',
  ux_picture: '',
  ux_sub: '',
  ux_is_email_verified: false,
  ux_phone: '',
  is_admin: 0,
}


const emptyCart = {
  ux_email: '',
  ux_family_name: '',
  ux_given_name: '',
  ux_phone: '',
  comment: ''
}

const emptyForm = {
  email: '',
  password: '',
  firstname: '',
  lastname: '',
  phone: ''
}
const emptyFormBerloAdatok = {
}

const emptyRegisterForm = {
  email: '',
  password: '',
  password2: '',
  firstname: '',
  lastname: '',
  phone: ''
}

const emptyCustomerDataInputForm = {
  withSkipper: false,
  userid: 0,
  lastname: '',
  firstname: '',
  phone: '',
  email: '',
  comment: '',
  isValid: false,
}

const emptyBoatSearchBarForm = {
  //dateFrom: new Date(0),
  //dateTo: new Date(0),
  dateFrom: new Date(),
  dateTo: new Date(),
  timeFrom: 9,
  timeTo: 17,
  reservationDurationType: 'N',
  destination: '',
  charter: '',
  boatType: '',
  quoteGuid: '',
  withSkipper: false,
}

const EmptyKikotoList = {
}
const EmptyKiadoList = {
}
const EmptyCharterDefaults = {
}
const EmptyFaqList = {
}
const EmptyServiceList = {
}
const EmptyDocumentTemplateList = {
}

const EmptyPricing = {
  pricingValid: false,
  boatPrice: 0,
  skipperPrice: 0,
  totalPrice: 0,
  totalDays: 0,
  totalHours: 0,
  downpayment100: 0,
  deposit: 0,
  withSkipper: false,
  day_0: null,
  day_1: null,
  day_2: null,
  day_3: null,
}




const initialState = {
  firstLngSet: false,
  forcedCharterGuid: '',
  forcedBoatGuid: '',
  kiadoId: 0,
  language: 'hu',
  message: messages_hu,
  profilePicture: null,
  currentUser: emptyUser,
  cart: emptyCart,
  selectedHajo: null,
  form: emptyForm,
  formBerloAdatok: emptyFormBerloAdatok,
  registerForm: emptyRegisterForm,
  customerDataInputForm: emptyCustomerDataInputForm,
  boatSearchBarForm: emptyBoatSearchBarForm,
  kikotoList: EmptyKikotoList,
  kiadoList: EmptyKiadoList,
  faqList: EmptyFaqList,
  serviceList: EmptyServiceList,
  documentTemplateList: EmptyDocumentTemplateList,
  charterDefaults: EmptyCharterDefaults,
  testMode: false,
  database: null,
  hajoAr: 0,
  kapitanyAr: 0,
  pricing: EmptyPricing,
  quote: emptyQuote,
  fakeDoorServiceList: EmptyServiceList,
  documentTemplateList: EmptyDocumentTemplateList,
}

//const store = createStore(loginReducer, initialState);
//const store = configureStore(loginReducer);
//export default store;
export { loginReducer, initialState };
