import React, { useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl'
import Axios from "axios";

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';


import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';


import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { CredentialResponse, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { byAccessToken } from "../tools/SajatGoogleLogin";
import { useSelector, useDispatch, ReactReduxContext } from 'react-redux';
import FacebookLogin, { ProfileSuccessResponse, SuccessResponse } from '@greatsumini/react-facebook-login';
import SajatFacebookLogin from "../tools/SajatFacebookLogin";

//import SimpleDialogProps from '../types/SimpleDialogProps';



const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface MixedLoginProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  onEmailLoginClicked: () => void;
  onGoogleLoginClicked: () => void;
  onFacebookLoginClicked: () => void;
  onRegisterClicked: () => void;
}

type CustomProps = {
  onClick: undefined,
  logout: undefined
}

const FacebookLoginCustomComponent = (props: CustomProps) => {
  return (
    <button>Jelentkezz be!</button>

  );

  //                   <FacebookLoginCustomComponent onClick={onClick} onLogoutClick={logout} />
}


export default function DialogMixedLogin(props: MixedLoginProps) {
  const dispatch = useDispatch();
  const [showGoogleAlert, setShowGoogleAlert] = useState(false);
  const [showFacebookAlert, setShowFacebookAlert] = useState(false);
  //const { onClose, onEmailLoginClicked, selectedValue, open } = props;
  var onClose = props.onClose;
  var selectedValue = props.selectedValue;
  var onEmailLoginClicked = props.onEmailLoginClicked;
  var onRegisterClicked = props.onRegisterClicked;
  var open = props.open;

  const gLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const s: string = tokenResponse.access_token;
      try{
        const data: any = await byAccessToken(s);
        console.log('G login successful here data:')
        console.log(data);
        dispatch({ type: 'LoginWithEmail', currentUser: data });
      }
      catch(err){
        console.log(err);
        setShowGoogleAlert(true);
        dispatch({ type: 'Logout' });        
        throw(err);
      }
      onClose(selectedValue);
    },
    onError: errorResponse => {
      console.log(errorResponse);
      setShowGoogleAlert(true);
      dispatch({ type: 'Logout' });      
    },
    flow: 'implicit',
    scope: 'email',
  });


  const handleFacebookLoginSuccess = async (response: SuccessResponse) => {
    console.log('response');
    console.log(response);
    onClose(selectedValue);
  }


  const handleFacebookLoginProfileSuccess = async (profileSuccessResponse: ProfileSuccessResponse) => {
    console.log('profileSuccessResponse');
    console.log(profileSuccessResponse);

    const data: any = await SajatFacebookLogin(profileSuccessResponse);
    console.log('User data in DialogMixedLogin:');
    console.log(data);
    dispatch({ type: 'LoginWithEmail', currentUser: data });
    onClose(selectedValue);
  }



  const handleGoogleLoginClicked = () => {
    setShowGoogleAlert(false);
    gLogin();
  };

  const handleClose = () => {
    setShowGoogleAlert(false);
    setShowFacebookAlert(false);
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
    >
      <DialogTitle>
        <FormattedMessage id='dialog.title.login' />

        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>

        <List>
          <ListItem onClick={handleGoogleLoginClicked}>
            <Button variant="outlined" fullWidth sx={{ textAlign: 'left', textTransform: 'none' }}>
              <ListItemIcon>
                <GoogleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id='button.login_with_google_account' />
              </ListItemText>
            </Button>
          </ListItem>
          {showGoogleAlert &&
            <ListItem>
              <Alert variant='standard' severity='error' style={{ width: '100%' }}>
                <FormattedMessage id='alert.google_login_unsuccessful' />
              </Alert>
            </ListItem>
          }

          <FacebookLogin
            appId="1280979642729484"
            initParams={{
              version: 'v14.0',
              xfbml: true,
            }}
            loginOptions={{
              return_scopes: true
            }}
            onSuccess={(response) => {
              handleFacebookLoginSuccess(response);
            }}
            onFail={(error) => {
              console.log('Login Failed!', error);
            }}
            onProfileSuccess={(response) => {
              handleFacebookLoginProfileSuccess(response);
            }}

            render={renderProps => (
              <>
                <ListItem onClick={renderProps.onClick}>
                  <Button variant="outlined" fullWidth sx={{ textAlign: 'left', textTransform: 'none' }}>
                    <ListItemIcon>
                      <FacebookIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage id='button.login_with_facebook_account' />
                    </ListItemText>
                  </Button>
                </ListItem>
                {showFacebookAlert &&
                  <ListItem>
                    <Alert variant='standard' severity='error' style={{ width: '100%' }}>
                      <FormattedMessage id='alert.facebook_login_unsuccessful' />
                    </Alert>
                  </ListItem>
                }
              </>
            )}
          />
          {false &&
            <ListItem onClick={handleClose}>
              <Button variant="outlined" fullWidth sx={{ textAlign: 'left', textTransform: 'none' }}>
                <ListItemIcon>
                  <AppleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id='button.login_with_apple_account' />
                </ListItemText>
              </Button>
            </ListItem>
          }
          <Divider variant='fullWidth' />
          <ListItem onClick={onEmailLoginClicked}>
            <Button variant="outlined" fullWidth sx={{ textAlign: 'left', textTransform: 'none' }}>
              <ListItemIcon>
                <EmailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id='button.login_with_email_address' />
              </ListItemText>
            </Button>
          </ListItem>
          <Divider variant='fullWidth' />
          <ListItem onClick={onRegisterClicked}>
            <Button variant="outlined" fullWidth sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}>
              <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>Még nem regisztrált? &nbsp;</ListItemText>
              <ListItemText sx={{ textAlign: 'center' }}>Regisztráljon </ListItemText>
            </Button>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          <FormattedMessage id='button.close' />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

