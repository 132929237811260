import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import FormControl from '@mui/material/FormControl';
import Grid, { GridDirection } from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'

import BoatSearchBarFormType from '../types/BoatSearchBarFormType';
import KikotoListType from '../types/KikotoListType';
import KikotoType from '../types/KikotoType';
import KiadoListType from '../types/KiadoListType';
import KiadoType from '../types/KiadoType';

import RootState from '../types/RootState';
import { ResponsiveStyleValue } from "@mui/system";
import { Card, CardActionArea, CardContent, CardHeader, CardMedia, Paper, Slider, Stack, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { addDays, differenceInCalendarDays, isBefore, isAfter, isSameDay, startOfDay } from 'date-fns';
import UserExtType from "../types/UserExtType";
import HajoType from "../types/HajoType";
import PricingType from "../types/PricingType";


function d2sDot(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + String(date.getMonth() + 1)).slice(-2);
  const day = ('0' + String(date.getDate())).slice(-2);
  const formattedDate = `${year}.${month}.${day}`;
  return formattedDate;
}

function d2s(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + String(date.getMonth() + 1)).slice(-2);
  const day = ('0' + String(date.getDate())).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}


export default function BoatSearchBar() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const selectedHajo: HajoType = useSelector((state: RootState) => state.selectedHajo);
  const currentUser: UserExtType = useSelector((state: RootState) => state.currentUser);
  const boatSearchBarForm: BoatSearchBarFormType = useSelector((state: RootState) => state.boatSearchBarForm);
  const kikotoList: KikotoListType = useSelector((state: RootState) => state.kikotoList);
  const kiadoList: KiadoListType = useSelector((state: RootState) => state.kiadoList);
  const pricing: PricingType = useSelector((state: RootState) => state.pricing);

  const marks = [
    {
      value: 6,
      label: '6',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 16,
      label: '18',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 22,
      label: '22',
    },
  ];


  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
  }));


  return (

    <div>
      <Card raised={true}
        sx={{ width: 250 }}>
        <CardHeader
          title={intl.formatMessage({ id: 'reservation_details.reservation_details' })}
        />
        {selectedHajo &&
          <CardContent>
            <div>
              <Typography gutterBottom={false} variant="body2">
                <FormattedMessage id='reservation_details.boat_handover_date' />
              </Typography>
              <Typography gutterBottom={true} variant="body2">
                {d2sDot(boatSearchBarForm?.dateFrom)}

                {boatSearchBarForm.reservationDurationType === 'O' &&
                  ' ' + boatSearchBarForm.timeFrom.toString() + ':00'
                }
                {boatSearchBarForm.reservationDurationType === 'N' &&
                  ' ' + selectedHajo.kiadas_tol_ig.toString()
                }
              </Typography>
              <FormControl fullWidth>
                <Slider
                  min={8}
                  max={20}
                  value={boatSearchBarForm.reservationDurationType === 'O' ? boatSearchBarForm.timeFrom : [8, 9]}
                  valueLabelDisplay="off"
                  step={12}
                  marks={marks}
                  size="small"
                  sx={{
                    "& .MuiSlider-markLabel": {
                      fontSize: "10px",
                      fontWeight: "lighter",
                    }
                  }} />
              </FormControl>

              <Typography gutterBottom={false} variant="body2">
                <FormattedMessage id='reservation_details.boat_return_date' />
              </Typography>
              <Typography gutterBottom={true} variant="body2">
                {boatSearchBarForm.reservationDurationType === 'O' ? d2sDot(boatSearchBarForm?.dateFrom) : d2sDot(boatSearchBarForm?.dateTo)}
                {boatSearchBarForm.reservationDurationType === 'O' &&
                  ' ' + boatSearchBarForm.timeTo.toString() + ':00'
                }
                {boatSearchBarForm.reservationDurationType === 'N' &&
                  ' ' + selectedHajo.visszahozatal_tol_ig.toString()
                }
              </Typography>
              <FormControl fullWidth>
                <Slider
                  min={8}
                  max={20}
                  value={boatSearchBarForm.reservationDurationType === 'O' ? boatSearchBarForm.timeTo : [18, 19]}
                  valueLabelDisplay="off"
                  step={12}
                  marks={marks}
                  size="small"
                  sx={{
                    "& .MuiSlider-markLabel": {
                      fontSize: "10px",
                      fontWeight: "lighter",
                    }
                  }} />
              </FormControl>


              <Typography gutterBottom={false} variant="body2">
                <FormattedMessage id='reservation_details.reservation_full_length' />
              </Typography>

              <Typography gutterBottom={true} variant="body2">

                {pricing.totalDays + pricing.totalHours}

                {pricing.totalDays === 1 && pricing.totalHours === 0 &&
                  <FormattedMessage id='reservation_details.space_days_singular' />
                }
                {pricing.totalDays > 0 && pricing.totalDays !== 1 && pricing.totalHours === 0 &&
                  <FormattedMessage id='reservation_details.space_days_plural' />
                }
                {pricing.totalDays === 0 && pricing.totalHours === 1 &&
                  <FormattedMessage id='reservation_details.space_hours_singular' />
                }
                {pricing.totalDays === 0 && pricing.totalHours !== 1 && pricing.totalHours > 0 &&
                  <FormattedMessage id='reservation_details.space_hours_plural' />
                }

              </Typography>

              <Typography gutterBottom={false} variant="body2">
                <FormattedMessage id='reservation_details.boat_handover_place' />
              </Typography>
              <Typography gutterBottom={true} variant="body2">
                {selectedHajo?.kikoto_cim ?? ''}
              </Typography>

              <Typography gutterBottom={false} variant="body2">
                <FormattedMessage id='reservation_details.charter_company_name' />
              </Typography>
              <Typography gutterBottom={true} variant="body2">
                {selectedHajo?.kiado_nev ?? ''}
              </Typography>
            </div>
          </CardContent>
        }
      </Card>
    </div >
  );
}

