import Axios from "axios";

export default async function LoadServices(store: any) {
  const x = await _LoadServices();
  console.log('Services: ', x);
  store.dispatch({ type: 'ServiceList', payload: x });
}

async function _LoadServices() {
  var response;
  try {
    response = await Axios.post(window.backendUrl + 'query_services');
    if (response.status === 200) {
    } else {
      console.log("Error! Response of Axios post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }
  } catch (err) {
    console.error(err);
  }
  return response?.data;
}
