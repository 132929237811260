import React, { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Axios from "axios";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { isValidName, isValidEmailAddress, isValidPhone, isStrongPassword } from '../tools/Tools'
import RegisterFormType from '../types/RegisterFormType';
import RootState from '../types/RootState';


export interface RegisterProps {
  open: boolean;
  selectedValue: string;
  onClose: () => void;
  onMixedLoginClicked: () => void;
}

export function DialogRegister(props: RegisterProps) {

  const navigate = useNavigate();  
  const dispatch = useDispatch();
  const intl = useIntl();
  const form: RegisterFormType = useSelector((state: RootState) => state.registerForm);
  const [validation, setValidation] = useState<RegisterFormType>({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'lastname') {
      dispatch({ type: 'RegisterFormValues', registerForm: { ...form, lastname: value } });
    }
    else if (name === 'firstname') {
      dispatch({ type: 'RegisterFormValues', registerForm: { ...form, firstname: value } });
    }
    else if (name === 'phone') {
      dispatch({ type: 'RegisterFormValues', registerForm: { ...form, phone: value } });
    }
    else if (name === 'email') {
      dispatch({ type: 'RegisterFormValues', registerForm: { ...form, email: value } });
    }
    else if (name === 'password') {
      dispatch({ type: 'RegisterFormValues', registerForm: { ...form, password: value } });
    }
    else if (name === 'password2') {
      dispatch({ type: 'RegisterFormValues', registerForm: { ...form, password2: value } });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  }
  
  const checkValidation = () => {
    setSubmitVisible(true);
    let errors: RegisterFormType = JSON.parse(JSON.stringify(validation));
    if (!form?.lastname?.trim()) {
      errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
      setSubmitVisible(false);
    }
    else if (!isValidName(form.lastname)) {
      errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
      setSubmitVisible(false);
    }
    else {
      errors.lastname = '';
    }

    if (!form?.firstname?.trim()) {
      errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' })
      setSubmitVisible(false);
    }
    else if (!isValidName(form.firstname)) {
      errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' });
      setSubmitVisible(false);
    }
    else {
      errors.firstname = '';
    }
    if (!form?.phone?.trim()) {
      errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_required' })
      setSubmitVisible(false);
    }
    else if (!isValidPhone(form.phone)) {
      errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_format_invalid' })
      setSubmitVisible(false);
    }
    else {
      errors.phone = '';
    }


    if (!form?.email?.trim()) {
      errors.email = intl.formatMessage({ id: 'input.validation.email_address_required' })
      setSubmitVisible(false);
    }
    else if (!isValidEmailAddress(form.email)) {
      errors.email = intl.formatMessage({ id: 'input.validation.email_address_format_invalid' })
      setSubmitVisible(false);
    }
    else {
      errors.email = '';
    }


    if (!form?.password?.trim()) {
      errors.password = intl.formatMessage({ id: 'input.validation.password_required' })
      setSubmitVisible(false);
    }
    else if (!isStrongPassword(form.password)) {
      errors.password = intl.formatMessage({ id: 'input.validation.password_weak' })
      setSubmitVisible(false);
    }
    else {
      errors.password = '';
    }

    if (!form?.password2?.trim()) {
      errors.password2 = intl.formatMessage({ id: 'input.validation.password2_required' })
      setSubmitVisible(false);
    }
    else if (form.password != form.password2) {
      errors.password2 = intl.formatMessage({ id: 'input.validation.passwords_do_not_match' })
      setSubmitVisible(false);
    }
    else {
      errors.password2 = '';
    }

    setValidation(errors);
  }

  useEffect(() => {
    checkValidation();
  }, [form]);

  useEffect(() => {
    checkValidation();
  }, [props.open]);

  const handleSave = () => {
    resetDisplayFlags();
    createAccount();
  }

  const dispatchLogin = (data: string) => {
    dispatch({ type: 'LoginWithEmail', currentUser: data });
  };

  const resetDisplayFlags =() => {
    setFormSubmitted(false);
    setAccountCreated(false);
    setAccountExists(false);
    setShowErrorAlert(false);
  }
  const handleClose = () => {
    resetDisplayFlags();
    dispatch({type: 'ClearRegisterFormValues', null: null});       
    props.onClose();
  };


  async function createAccount() {
    setSubmitVisible(false);
    try {
      const response = await Axios.post(window.backendUrl + 'save_reg_form', form);
      if (response.status === 200) {
        setFormSubmitted(true);
        if (response.data === 'USER_ALREADY_EXISTS') {
          console.log(response.data);
          setAccountExists(true);
        }
        else {
          console.log('Create Account successful');
          setAccountCreated(true);
          console.log(response.data[0]);
          dispatchLogin(response.data[0]);
        }
      }
      else {
        console.log('Create Account unsuccessful');
        setFormSubmitted(true);
        setShowErrorAlert(true);
      }
    }
    catch (error) {
      console.log(error);
      console.log('Create Account unsuccessful');
      setFormSubmitted(true);
      setShowErrorAlert(true);
    };
  };

  const navigateTermsAndConditions = () => {
    resetDisplayFlags();
    dispatch({type: 'ClearRegisterFormValues', null: null});       
    props.onClose();    
    navigate('/termsandconditions');
  };
  const navigateCookiePolicies = () => {
    resetDisplayFlags();
    dispatch({type: 'ClearRegisterFormValues', null: null});       
    props.onClose();    
    navigate('/cookiepolicies');
  };

  return (

    <Dialog 
    onClose={handleClose} 
    open={props.open}
    fullWidth
    >
      <DialogTitle><FormattedMessage id='dialog.title.create_account' />
        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <List>

          <ListItem>
            <TextField
              value={form?.lastname}
              required
              label={intl.formatMessage({ id: 'input.label.lastname' })}
              fullWidth
              name='lastname'
              helperText={validation.lastname}
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              value={form?.firstname}
              required
              label={intl.formatMessage({ id: 'input.label.firstname' })}
              fullWidth
              name='firstname'
              helperText={validation.firstname}
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              value={form?.phone}
              required
              label={intl.formatMessage({ id: 'input.label.phone' })}
              fullWidth
              name='phone'
              helperText={validation.phone}
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              value={form?.email}
              required
              label={intl.formatMessage({ id: 'input.label.email' })}
              fullWidth
              name='email'
              helperText={validation.email}
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              value={form?.password}
              type='password'
              required
              label={intl.formatMessage({ id: 'input.label.password' })}
              fullWidth
              name='password'
              helperText={validation.password}
              onChange={handleTextFieldChange} />
          </ListItem>

          <ListItem>
            <TextField
              value={form?.password2}
              type='password'
              required
              label={intl.formatMessage({ id: 'input.label.password2' })}
              fullWidth
              name='password2'
              helperText={validation.password2}
              onChange={handleTextFieldChange} />
          </ListItem>


          <ListItem>
            <p>A fiókba való belépéssel elfogadja az alábbiakat:<br></br>

              <Link href="#" underline="none" onClick={navigateTermsAndConditions}>
                <FormattedMessage id='hamburger.Termsandconditions' />
              </Link>
              &nbsp; és &nbsp;
              <Link href="#" underline="none" onClick={navigateCookiePolicies}>
                <FormattedMessage id='hamburger.Dataprotectioncookie' />
              </Link>
            </p>
          </ListItem>

          {!formSubmitted &&
            <ListItem>
              <Button
                onClick={handleSave}
                variant='contained'
                fullWidth
                sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
                disabled={!submitVisible}
              >
                <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
                  <FormattedMessage id='submit.save' />
                </ListItemText>
              </Button>
            </ListItem>
          }

          {(formSubmitted && accountExists) &&
            <ListItem>
              <Alert variant='standard' severity='error' style={{ width: '100%' }}>
                Már létezik regisztráció ezzel a felhasználónévvel!
              </Alert>
            </ListItem>
          }
          {(formSubmitted && showErrorAlert) &&
            <ListItem>
              <Alert variant='standard' severity='error' style={{ width: '100%' }}>
                A regisztráció nem sikerült!
              </Alert>
            </ListItem>
          }
          {(formSubmitted && accountCreated) &&
            <ListItem>
              <Alert variant='standard' severity='success' style={{ width: '100%' }}>
                A regisztráció sikeres volt.
              </Alert>
            </ListItem>
          }
        </List>
      </DialogContent>

      <DialogActions>
        <DeveloperModeIcon onClick={handleSave} />
        <Button autoFocus onClick={handleClose}>
          <FormattedMessage id='button.close' />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

