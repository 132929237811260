import { useEffect, useState } from 'react';
import { Typography, CardMedia, Box, ThemeProvider } from "@mui/material";
import { ServiceType } from '../types/DatabaseTypes';
import { fdTheme } from '../themes/fdTheme';

function FakeDoorService(props: ServiceType) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <ThemeProvider theme={fdTheme}>
        {Boolean(props.id) &&
          <Box

            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 1,
              border: 0,
            }}>
            <Box width={isMobile ? '100%' : '40%'}
              sx={{
                p: 0,
                align: 'center',
                maxWidth: 450,
                height: 300,
                borderRadius: 2,
              }}
            >
              <CardMedia
                component="img"
                alt="image"
                sx={{
                  align: 'center',
                  maxWidth: 450,
                  height: 300,
                  border: 0,
                }}
                image={props.image_name}
              />
            </Box>
            {isMobile &&
              <Box
                width='100%'
                sx={{
                  maxWidth: 450,
                  minHeight: 100,
                  p: 2,
                  borderRadius: 2,
                  color: props.text_color,
                  backgroundColor: props.background_color,
                }}>
                <Typography variant="h1" paragraph={true}> {props.title}  </Typography>
                <Typography variant="body1"> {props.content} </Typography>
              </Box>
            }
            {!isMobile &&
              <Box
                width='40%'
                sx={{
                  maxWidth: 450,
                  minHeight: 300,
                  p: 2,
                  borderRadius: 2,
                  color: props.text_color,
                  backgroundColor: props.background_color,
                }}>
                <Typography variant="h1" paragraph={true}> {props.title}  </Typography>
                <Typography variant="body1"> {props.content} </Typography>
              </Box>
            }
          </Box>
        }
      </ThemeProvider>
    </div>
  );
}

export default FakeDoorService;

