import Axios from "axios";

export default async function LoadKiadoList(store: any) {
  const x = await _LoadKiadoList();
  store.dispatch({ type: 'KiadoList', payload: x });
}

async function _LoadKiadoList() {
  //console.log('LoadKiadoList started');
  //const dispatch = useDispatch();
  var response;
  try {
    response = await Axios.post(window.backendUrl + 'query_kiado_list');
    if (response.status === 200) {
      //console.log(response.data);
    }
    else {
      console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }
  }
  catch (err) {
    console.error(err);
  }
  finally {
    //console.log('LoadKiadoList ended');
  }
  return (response?.data);
};

// export {doIt};


