import React from 'react'

function Contact() {
  return (
    <div>
      <h1>This is the contact page</h1>

<br/>
    </div>
  )
}

export default Contact
