import { createTheme } from "@mui/material";

const yellowButton = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: false,
        disableElevation: false,
      },
      styleOverrides: {
        
        root: {
          display: 'flex',
          width: '150px',
          height: '40px',
          padding: '2px 2px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2px',
          borderRadius: '4px',
          background: '#FFD600',
          textTransform: 'none',
          color: 'black',
          borderColor: '#A09000',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(33, 31, 38, 0.10) 0%, rgba(33, 31, 38, 0.10) 100%), #FFD600',
            borderColor: 'black',                        
          },
        },
        contained: {
          backgroundColor: '#FFD600',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(33, 31, 38, 0.10) 0%, rgba(33, 31, 38, 0.10) 100%), #FFD600',
            borderColor: 'black',                        
          },
        },
        outlined: {
          background: 'white',
          borderColor: '#FFD600',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(33, 31, 38, 0.10) 0%, rgba(33, 31, 38, 0.10) 100%), #FFD600',
            borderColor: 'black',                
          }
        }
      },
    },
  }

});

const blueButton = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: false,
        disableElevation: false,
      },
      styleOverrides: {
        root: {
          display: 'flex',
          width: '150px',
          height: '40px',
          padding: '2px 2px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2px',
          borderRadius: '4px',
          background: '#252AA7',
          textTransform: 'none',
          color: 'white',
          borderColor: '#A09000',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #252AA7;',
            borderColor: 'black',                        
          },

        },
        contained: {
          backgroundColor: '#252AA7',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #252AA7;',
            borderColor: 'black',                        
          },
        },
        outlined: {
          background: '#252AA7',
          borderColor: '##252AA7',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #252AA7;',
            borderColor: 'black',                
          }
        }
      },
    },
  }

});


const fdTheme = createTheme({

  components: {

    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#ccc"
        },
        colorSecondary: {
          "&$checked": {
            // Controls checked color for the thumb
            color: "#f2ff00"
          }
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#fff",
          "$checked$checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#fff"
          }
        }
      }
    }


  },
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    h2: {
      fontSize: 20,
      fontWeight: 400
    },
    h3: {
      fontSize: 16,
      fontWeight: 800
    },
    h4: {
      fontSize: 16,
      fontWeight: 400
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 800
    },
    subtitle2: {
      fontSize: 20,
      fontWeight: 400
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 800
    },

    button: {
      fontSize: 12,
      fontWeight: 400,
    },
  },

});


const whiteStyle = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: false,
        disableElevation: false,
      },
      styleOverrides: {
        root: {
          display: 'flex',
          width: '150px',
          height: '40px',
          padding: '2px 2px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2px',
          borderRadius: '4px',
          background: '#252AA7',
          textTransform: 'none',
          color: 'white',
          borderColor: '#A09000',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #252AA7;',
            borderColor: 'black',                        
          },

        },
        contained: {
          backgroundColor: '#252AA7',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #252AA7;',
            borderColor: 'black',                        
          },
        },
        outlined: {
          background: '#252AA7',
          borderColor: '##252AA7',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #252AA7;',
            borderColor: 'black',                
          }
        }
      },
    },
  },
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    h2: {
      fontSize: 20,
      fontWeight: 400
    },
    h3: {
      fontSize: 16,
      fontWeight: 800
    },
    h4: {
      fontSize: 16,
      fontWeight: 400
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 800
    },
    subtitle2: {
      fontSize: 20,
      fontWeight: 400
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 800
    },

    button: {
      fontSize: 12,
      fontWeight: 400,
    },
  },


});





export {fdTheme, yellowButton, blueButton, whiteStyle};