import { useSelector, useDispatch } from 'react-redux';
import { DatabaseType, HajoType, KiadoType, FoglalasType, KikotoType } from '../types/DatabaseTypes'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { useIntl, FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CssBaseline, Divider, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import WifiIcon from '@mui/icons-material/Wifi';
import PercentIcon from '@mui/icons-material/Percent';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ReactCountryFlag from 'react-country-flag';
import DownloadIcon from '@mui/icons-material/Download';
import { displayReservationPeriod, displayDateHHMM, displayDateAfter, displayDateBefore } from '../tools/Tools';

//import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
//import yellowtail from 'typeface-yellowtail';


//import '../styles/figma.css';



const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3', // Change primary color
    },
    secondary: {
      main: '#FF5722', // Change secondary color
    },

  },

  typography: {
    // fontFamily: 'Sans-serif', // Change default font
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    h2: {
      fontSize: 12,
      fontWeight: 600
    },
    h3: {
      fontSize: 20,
      fontWeight: 800,
      color: '#6AB2BC',
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,
      color: '#6AB2BC',
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 500,
      backgroundColor: '#FFD600',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
      color: 'white',
      backgroundColor: 'black',
    },
    body1: {
      fontSize: 12,
      fontWeight: 300
    },
    body2: {
      fontSize: 16,
      fontWeight: 600
    },
  },
});

interface MyProps {
  hajo: HajoType,
  kikoto: KikotoType,
  kiado: KiadoType,
  foglalas: FoglalasType,
  dateFrom?: Date,
  dateTo?: Date,

}
function d2s(date: Date): string {
  var formattedDate = '';
  try {

    const year = date.getFullYear();
    const month = ('0' + String(date.getMonth() + 1)).slice(-2);
    const day = ('0' + String(date.getDate())).slice(-2);
    formattedDate = `${year}-${month}-${day}`;
  }
  catch (e) {

  }
  return (formattedDate);
}
const isMobile = window.innerWidth <= 768;



function ProfileReservationCardDetails(props: DatabaseType) {
  const intl = useIntl();

  //console.log('ProfileReservationCardDetails() called');
  //console.log(props);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const css = `

  .bovebben  {
    width: 100%;
    padding: 0px;
    margin: 0px;
    text-align: right;
    background-color: white;
    border-collapse: collapse;
    border: 0;
  },
  .bovebben button {
    background-color: white;

  }
  .tableClassWifi  {
    width: 100%;
    padding: 0px;
    margin: 0px;
    text-align: center;
    background-color: white;
    border-collapse: collapse;
    border: 0;
  },

  .customBlue {
    color: white;
    background:'#5382E9';
  }
  `;

  function d2sDot(date: Date): string {
    var formattedDate = '';
    try {
      const year = date.getFullYear();
      const month = ('0' + String(date.getMonth() + 1)).slice(-2);
      const day = ('0' + String(date.getDate())).slice(-2);
      formattedDate = `${year}.${month}.${day}`;
    }
    catch (e) {

    }
    return (formattedDate);
  }

  function displayReservationPeriod(arazasTipus: string, dateFrom: Date, dateTo: Date) {
    var x = dateFrom + ' ' + dateTo + ' (' + arazasTipus + ')';
    return (x);
  }


  //console.log('ProfileReservationCardSummary item:', props);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style>
        {css}
      </style>
      <div>
        {isMobile &&

          <Card sx={{ width: 343, height: 402 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={props?.hajo?.pic_guid ?? ''}
                alt="boat picture"
              />
              <CardContent>
                <div>
                  <Typography gutterBottom variant="h5" component="div">
                    {props.hajo.model}
                  </Typography>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        }
        {!isMobile &&


          <Box sx={{ display: 'flex', flexDirection: 'column', border: 0 }}>
            <Divider variant='fullWidth' />
            <Grid container spacing={0} sx={{ pt: 2, pb: 2 }}>
              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.arrival_date' />
                </Typography>
                <Typography variant="body2">
                  {displayDateHHMM(intl, props?.foglalas.tol)}
                </Typography>
              </Grid>
              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.departure_date' />
                </Typography>
                <Typography variant="body2">
                  {displayDateHHMM(intl, props?.foglalas.ig)}
                </Typography>
              </Grid>
              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.downpayment_payment_date' />
                </Typography>
                <Typography variant="body2">
                  {displayDateBefore(intl, props?.foglalas.tol)}
                </Typography>
              </Grid>
              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.full_payment_date' />
                </Typography>
                <Typography variant="body2">
                  {displayDateBefore(intl, props?.foglalas.tol)}
                </Typography>
              </Grid>

              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.charter' />
                </Typography>
                <Typography variant="body2">
                  {props.kiado.nev}
                </Typography>
              </Grid>
              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.charter_contact' />
                </Typography>
                <Typography variant="body2">
                  {props.kiado.email} | {props.kiado.telefon}
                </Typography>
              </Grid>

              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.free_cancellation' />
                </Typography>
                <Typography variant="body2">
                  {displayDateBefore(intl, props?.quote.day_0)}
                </Typography>
              </Grid>
              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.notfree_cancellation' />
                </Typography>
                <Typography variant="body2">
                  {displayDateBefore(intl, props?.quote.day_1)}

                </Typography>
              </Grid>

              <Grid xs={6} sx={{ pb: 2 }}>
                <Typography variant="body1">
                  <FormattedMessage id='reservation_card_details.no_cancellation' />
                </Typography>
                <Typography variant="body2">
                  {displayDateAfter(intl, props?.quote.day_2)}

                </Typography>
              </Grid>
            </Grid>

            <Divider variant='fullWidth' />

            <Typography variant="body2" sx={{ pt: 2 }}>
              <DownloadIcon fontSize="inherit" /> {' '}
              <FormattedMessage id='reservation_card_details.general_terms_and_conditions' />
            </Typography>
            <Typography variant="body2">
              <DownloadIcon fontSize="inherit" /> {' '}
              <FormattedMessage id='reservation_card_details.contract' />
            </Typography>
            <Typography variant="body2">
              <DownloadIcon fontSize="inherit" /> {' '}
              <FormattedMessage id='reservation_card_details.policy' />
            </Typography>

          </Box>




        }
      </div>
    </ThemeProvider>
  );
}

export default ProfileReservationCardDetails;
