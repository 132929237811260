import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import RootState from '../types/RootState';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    body1: {
      fontSize: 18,
      fontWeight: 400
    },
  }
});

function BoatDetailsAdvantages() {
  //console.log('BoatDetailsAdvantages() called');
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  return (
    <div>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5}
            sx={{
              background: 'lightgrey',
              p: 1,
              m: 1,
            }}
          >
            <Typography
              variant="h1"
              paragraph={false}
              sx={{ p: 1 }}>
              <FormattedMessage id='boat.Advantages.Title' />
            </Typography>
            <Typography
              variant="body1"
              paragraph={false}
              sx={{ p: 1 }}
            >
              <CheckIcon /><FormattedMessage id='boat.Advantages.Withskipper' /><br />
              <CheckIcon /><FormattedMessage id='boat.Advantages.Passangerinsurance' /><br />
              <CheckIcon /><FormattedMessage id='boat.Advantages.Freewifi' /><br />
              <CheckIcon /><FormattedMessage id='boat.Advantages.OnlyXdownpayment' /><br />
              <CheckIcon /><FormattedMessage id='boat.Advantages.Cancellableanytime' /><br />
            </Typography>
          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsAdvantages;
