import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import RootState from '../types/RootState';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 500
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 16,
      fontWeight: 800
    },
  }
});

function BoatDetailsLeiras() {
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  return (
    <div>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5}
            sx={{
              p: 1,
              m: 1
            }}
          >
            <Typography
              variant="h1"
              paragraph={false}
              sx={{ p: 1 }}>
              <FormattedMessage id='boat.Description' />
            </Typography>
            <Typography
              variant="body1"
              paragraph={false}
              sx={{ p: 1 }}
            >
              {selectedHajo.leiras ?? ''}
            </Typography>
          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsLeiras;
