//import { useSelector, useDispatch } from 'react-redux';

import Axios from "axios";

import BoatSearchBarFormType from '../types/BoatSearchBarFormType';
import { d2s } from '../tools/Tools';
import { QuoteType, emptyQuote } from "../types/DatabaseTypes";

export default async function LoadPricing(store: any, boatSearchBarForm: BoatSearchBarFormType) {
  const x = await _LoadPricing(boatSearchBarForm);
  console.log('after await, now comes store.dispatch..')
  store.dispatch({ type: 'Quote', payload: x });
}

async function _LoadPricing(boatSearchBarForm: BoatSearchBarFormType) {
  console.log('LoadPricing() started, Boat ID: ', boatSearchBarForm.boatId);
  var quote: QuoteType = emptyQuote;
  quote.calculation_valid = false;
  var response;
  try {
    response = await Axios.post(window.backendUrl + 'query_hajo', boatSearchBarForm);
    if (response.status === 200) {
      //console.log("boat query done");
      //console.log(response.data);
      //setDetailResultList(response.data[0]);
      //setDetailResult(response.data);
      //setProduct(response.data);
      //dispatch({ type: 'selectedHajo', payload: response.data.hajo });
      //dispatch({ type: 'CalcFizetesiUtemezes', payload: null });       
    }
    else {
      console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }
    const req = {
      boatSearchBarForm: {
        boatId: boatSearchBarForm.boatId,
        dateFrom: d2s(boatSearchBarForm.dateFrom),
        dateTo: d2s(boatSearchBarForm.dateTo),
        reservationDurationType: boatSearchBarForm.reservationDurationType,
        timeFrom: boatSearchBarForm.timeFrom,
        timeTo: boatSearchBarForm.timeTo,
        withSkipper: boatSearchBarForm.withSkipper,
      }
    }

    //dispatch({ type: 'SetHajoAr', payload: 0 });
    //dispatch({ type: 'SetKapitanyAr', payload: 0 });
    response = await Axios.post(window.backendUrl + 'get_price', req);
    if (response.status === 200) {
      try {
        let result = response.data.map((x: any) => Object.keys(x));
        let key = result.flat(1);
        let val = response.data[Object.keys(response.data)[0]];
        var rs = JSON.parse(val[key]);
        console.log('price result: ');
        console.log(rs);
        try { quote.calculation_valid = Boolean(JSON.parse(rs.CALCULATION_VALID)); } catch (e) { };
        if (quote.calculation_valid) {
          try { quote.total_price = JSON.parse(rs.TOTAL_PRICE); } catch (e) { };
          try { quote.skipper_price = JSON.parse(rs.SKIPPER_PRICE); } catch (e) { };
          try { quote.boat_price = JSON.parse(rs.BOAT_PRICE); } catch (e) { };
          try { quote.total_days = JSON.parse(rs.TOTAL_DAYS); } catch (e) { };
          try { quote.total_hours = JSON.parse(rs.TOTAL_HOURS); } catch (e) { };
          try { quote.downpayment100 = JSON.parse(rs.DOWNPAYMENT100); } catch (e) { console.error(e) };
          console.log('Hajó ár: ', quote.boat_price, ' kapitány ár: ', quote.skipper_price, ' teljes ár: ', quote.total_price);
          //dispatch({ type: 'SetHajoAr', payload: hajoAr });
          //dispatch({ type: 'SetKapitanyAr', payload: kapitanyAr });
          //dispatch({ type: 'SetPricing', payload: pricing });
          //dispatch({ type: 'CalcFizetesiUtemezes', payload: null });                
        }
      }
      catch (ex) {
        console.error(ex);
      }

    }
    else {
      console.error("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }

  }
  catch (err) {
    console.error(err);
  }
  finally {
    //console.log('LoadBoatData: ', boatId, 'ended')
  }
  console.log('LoadPricing() ended. Calculation valid: ', quote.calculation_valid);
  return quote;

};

