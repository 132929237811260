import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';


import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'react-intl'
//import SimpleDialogProps from '../types/SimpleDialogProps';
import { Navbar } from '../components/Navbar';
import DialogMixedLogin from './DialogMixedLogin';
import { DialogEmailLogin } from './DialogEmailLogin';
import { DialogRegister } from './DialogRegister';
import { DialogUpdateProfile } from './DialogUpdateProfile';
import { DialogUpdateAccount } from './DialogUpdateAccount';
import { DialogSendResetPasswordLink } from './DialogSendResetPasswordLink';
import { DialogUseResetPasswordLink } from './DialogUseResetPasswordLink';
import LoadAccountData from '../tools/LoadAccountData';

const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface DialogAllProps {
  open: boolean;
  displayNavbar: boolean;
  selectedValue: string;
  onClose: () => void;
  onOpen: () => void,
  onMixedLoginClicked: () => void;
  onEmailLoginClicked: () => void;
  onGoogleLoginClicked: () => void;
  onFacebookLoginClicked: () => void;
  onRegisterClicked: () => void;
  onUpdateAccount: () => void;
  onUpdateProfile: () => void;

}


export default function DialogAll(props: DialogAllProps) {
  const dispatch = useDispatch();
  const [openDialogMixedLogin, setOpenDialogMixedLogin] = React.useState(false);
  const [openDialogEmailLogin, setOpenDialogEmailLogin] = React.useState(false);
  const [openDialogRegister, setOpenDialogRegister] = React.useState(false);
  const [openDialogUpdateProfile, setOpenDialogUpdateProfile] = React.useState(false);
  const [openDialogUpdateAccount, setOpenDialogUpdateAccount] = React.useState(false);
  const [openDialogSendResetPasswordLink, setOpenDialogSendResetPasswordLink] = React.useState(false);
  const [openDialogUseResetPasswordLink, setOpenDialogUseResetPasswordLink] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const closeAllDialogs = () => {
    setOpenDialogMixedLogin(false);
    setOpenDialogEmailLogin(false);
    setOpenDialogRegister(false);
    setOpenDialogUpdateProfile(false);
    setOpenDialogUpdateAccount(false);
    setOpenDialogSendResetPasswordLink(false);
    setOpenDialogUseResetPasswordLink(false);
  }

  const handleDialogMixedLoginOpen = () => {
    closeAllDialogs();
    setOpenDialogMixedLogin(true);
    //console.log("*** open mixed login dialog ***");
  };

  const handleDialogMixedLoginClose = () => {
    setOpenDialogMixedLogin(false);
    //console.log("*** close mixed login dialog ***");
  };

  const handleDialogEmailLoginOpen = () => {
    closeAllDialogs();
    setOpenDialogEmailLogin(true);
    //console.log("*** open email login dialog ***");
  };

  const handleDialogEmailLoginClose = () => {
    setOpenDialogEmailLogin(false);
    //console.log("*** close email login dialog ***");
  };

  const handleDialogRegisterOpen = () => {
    closeAllDialogs();
    setOpenDialogRegister(true);
    //console.log("*** open register dialog ***");
  };

  const handleDialogRegisterClose = () => {
    setOpenDialogRegister(false);
    //console.log("*** close register dialog ***");
  };

  const handleDialogUpdateProfileOpen = () => {
    console.log("*** open update profile dialog ***");
    dispatch({ type: 'PutProfileDataIntoForm', null: null });
    closeAllDialogs();
    setOpenDialogUpdateProfile(true);
    //console.log("*** open update account dialog ***");
  };

  async function handleDialogUpdateAccountOpen() {
    console.log("*** open update account dialog ***");
    const berloAdatok = await LoadAccountData('lakosi@gmail.com');
    dispatch({ type: 'PutAccountDataIntoForm', berloAdatok: berloAdatok });
    //    dispatch({ type: 'PutAccountDataIntoForm', null: null });
    closeAllDialogs();
    setOpenDialogUpdateAccount(true);
    //console.log("*** open update account dialog ***");
  };

  const handleDialogUpdateProfileClose = () => {
    setOpenDialogUpdateProfile(false);
    //console.log("*** close update profile dialog ***");
  };
  const handleDialogUpdateAccountClose = () => {
    setOpenDialogUpdateAccount(false);
    //console.log("*** close update account dialog ***");
  };

  const handleDialogSendResetPasswordLinkOpen = () => {
    closeAllDialogs();
    setOpenDialogSendResetPasswordLink(true);
    //console.log("*** open reset password dialog ***");
  };

  const handleDialogSendResetPasswordLinkClose = () => {
    setOpenDialogSendResetPasswordLink(false);
    //console.log("*** close reset password dialog ***");
  };

  const handleDialogUseResetPasswordLinkOpen = () => {
    closeAllDialogs();
    setOpenDialogUseResetPasswordLink(true);
    //console.log("*** open reset password dialog ***");
  };

  const handleDialogUseResetPasswordLinkClose = () => {
    closeAllDialogs();
    setOpenDialogUseResetPasswordLink(false);
    console.log("*** handleDialogUseResetPasswordLinkClose ***");
  };

  const emailLoginClickHandler = () => {
    //console.log('emailLoginClickHandler');
    handleDialogMixedLoginClose();
    handleDialogEmailLoginOpen();
  }



  const googleLoginClickHandler = () => {
    console.log('google Login Click Handler');
    handleDialogMixedLoginClose();
    props.onGoogleLoginClicked();
  }

  const facebookLoginClickHandler = () => {
    console.log('facebook Login Click Handler');
    handleDialogMixedLoginClose();
    props.onFacebookLoginClicked();
  }



  const mixedLoginClickHandler = () => {
    //console.log('mixedLoginClickHandler');
    handleDialogMixedLoginOpen();
  }

  const registerClickHandler = () => {
    //console.log('registerClickHandler');
    handleDialogMixedLoginClose();
    handleDialogRegisterOpen();
  }
  const updateProfileClickHandler = () => {
    //console.log('updateAccountClickHandler');
    handleDialogMixedLoginClose();
    handleDialogUpdateProfileOpen();
  }
  const updateAccountClickHandler = () => {
    //console.log('updateAccountClickHandler');
    handleDialogMixedLoginClose();
    handleDialogUpdateAccountOpen();
  }
  const sendResetPasswordLinkClickHandler = () => {
    //console.log('sendResetPasswordLinkClickHandler');
    handleDialogMixedLoginClose();
    handleDialogSendResetPasswordLinkOpen();
  }
  const useResetPasswordLinkClickHandler = () => {
    //console.log('useResetPasswordLinkClickHandler');
    handleDialogMixedLoginClose();
    handleDialogSendResetPasswordLinkOpen();
  }

  return (
    <div>
      {false &&
        <><Button variant="outlined" onClick={handleDialogMixedLoginOpen}>
          Login Mixed
        </Button><Button variant="outlined" onClick={handleDialogEmailLoginOpen}>
            Login with email
          </Button><Button variant="outlined" onClick={handleDialogRegisterOpen}>
            Register
          </Button><Button variant="outlined" onClick={handleDialogUpdateAccountOpen}>
            Update account
          </Button><Button variant="outlined" onClick={handleDialogSendResetPasswordLinkOpen}>
            Send password reset link
          </Button><Button variant="outlined" onClick={handleDialogUseResetPasswordLinkOpen}>
            Use password reset link
          </Button></>
      }
      {props.displayNavbar &&
        <Navbar
          displayNavbar={props.displayNavbar}
          onMixedLoginClicked={handleDialogMixedLoginOpen}
          onRegisterClicked={handleDialogRegisterOpen}
          onGoogleLoginClicked={function (): void {
            throw new Error('Function not implemented.');
          }}

          onFacebookLoginClicked={function (): void {
            throw new Error('Function not implemented.');
          }}
          open={false}
          selectedValue={''}
          onClose={function (): void {
            throw new Error('Function not implemented.');
          }}
          onOpen={function (): void {
            throw new Error('Function not implemented.');
          }}
          onEmailLoginClicked={function (): void {
            throw new Error('Function not implemented.');
          }}
          onUpdateProfile={updateProfileClickHandler}
          onUpdateAccount={updateAccountClickHandler}

        />
      }
      <DialogMixedLogin
        onEmailLoginClicked={emailLoginClickHandler}
        onGoogleLoginClicked={googleLoginClickHandler}
        onFacebookLoginClicked={facebookLoginClickHandler}
        onRegisterClicked={registerClickHandler}
        selectedValue={selectedValue}
        open={openDialogMixedLogin}
        onClose={handleDialogMixedLoginClose}
      />
      <DialogEmailLogin
        onMixedLoginClicked={mixedLoginClickHandler}
        onResetPasswordClicked={sendResetPasswordLinkClickHandler}
        selectedValue={selectedValue}
        open={openDialogEmailLogin}
        onClose={handleDialogEmailLoginClose}
      />
      <DialogRegister
        onMixedLoginClicked={mixedLoginClickHandler}
        selectedValue={selectedValue}
        open={openDialogRegister}
        onClose={handleDialogRegisterClose}
      />
      <DialogUpdateProfile
        selectedValue={selectedValue}
        open={openDialogUpdateProfile}
        onClose={handleDialogUpdateProfileClose}
      />
      <DialogUpdateAccount
        selectedValue={selectedValue}
        open={openDialogUpdateAccount}
        onClose={handleDialogUpdateAccountClose}
      />
      <DialogSendResetPasswordLink
        onMixedLoginClicked={mixedLoginClickHandler}
        selectedValue={selectedValue}
        open={openDialogSendResetPasswordLink}
        onClose={handleDialogSendResetPasswordLinkClose}
      />
      <DialogUseResetPasswordLink
        selectedValue={selectedValue}
        open={openDialogUseResetPasswordLink}
        setOpen={handleDialogUseResetPasswordLinkOpen}
        onClose={handleDialogUseResetPasswordLinkClose}
        onMixedLoginClicked={function (): void {
          throw new Error('Function not implemented.');
        }} />
    </div>
  );


}