

import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { GoogleOAuthProvider, CredentialResponse } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {FormattedMessage} from 'react-intl'
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button'
import { useSelector, useDispatch} from 'react-redux';

async function dbLogin(googleUser){ 
  var ux_id;
  console.log('dbLogin() start');
  console.log('googleUser is: ***');
  console.log(googleUser);  
  console.log('*** end google user');
  try{
    const response=await Axios.post(window.backendUrl+'/login_with_google', googleUser);
    console.log("Axio post done login_with_google");
    console.log(response);
    if(response.status === 200){
      console.log(response.data);
      ux_id=response.data.ux_id;
      console.log('User id (ux_id): '+ux_id);
    }
    else{
      console.log("Error! Response of Axio post is not 200, but "+response.status+" "+response.statusText);    
    }
  }
  catch(error){
    console.log(error);
    //setError(error.message);    
  };
 return(ux_id);
}

function LoginPage() {
//https://github.com/MomenSherif/react-oauth#usegooglelogin-both-implicit--authorization-code-flow

// https://console.cloud.google.com/apis/credentials?project=vizre-hu

  const clientId= "921867755490-manbqh1v8tv8j8jd98rkunt3u1cs58jn.apps.googleusercontent.com";
  // client secret GOCSPX-ybrrz0ZIdlEjklsAno_aZC7gVNJ
  //921867755490-manbqh1v8tv8j8jd98rkunt3u1cs58jn.apps.googleusercontent.com

 
//  const selectedBoatTypeId = useSelector((state: BoatTypeState) => state.selectedBoatTypeId);  
  const dispatch = useDispatch();

  const loggedIn = useSelector(state => state.loggedIn);  

const handleLogout = () => {
  console.log('Logout() pressed'); 
  googleLogout();
  dispatch({type: 'logout'});     
};

const googleLogin = useGoogleLogin({
  onSuccess: async tokenResponse => {
    console.log(tokenResponse);
    // fetching userinfo can be done on the client or the server
    const googleUser = await Axios
      .get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      })
      .then(res => res.data);
      /*
      console.log("Name: "+googleUser.name);
      console.log("Email: "+googleUser.email);
      console.log("Email verified: "+googleUser.email_verified);
      console.log("Locale: "+googleUser.locale);
      console.log("Family name: "+googleUser.family_name);
      console.log("Given name: "+googleUser.given_name);
      console.log("Picture: "+googleUser.picture);
      console.log(googleUser);
      console.log(tokenResponse);
      */
      dispatch({type: 'LoginWithGoogle', googleUser});          
      dbLogin(googleUser).then(
        (result) => {
          console.log('dbLogin() ok');
          console.log('Result is:'+ result);      
          if(result !== undefined){
            var ux_id=result;
            dispatch({type: 'set_UX_ID', ux_id});         
          }
        },
        (reason) => {
          console.error(reason);
        }
      ); 


    },
  // flow: 'implicit', // implicit is the default
});


  return (
    
<Container fixed>
        <Box sx={{ 
          width: 'auto',
          borderStyle: 'solid',
             borderWidth: '2px',
             height: '100px', 
             borderRadius: 3,
             padding: 3,
             alignItems: 'center'
             }}>  

   {loggedIn 
    ?
    <Button variant="contained" onClick={handleLogout}>
    <FormattedMessage id='button.Logout'/>
    </Button>       
    :   
    <GoogleButton onClick={googleLogin}>
    <FormattedMessage id='button.Login'/>
    </GoogleButton>     
   }      
  </Box>
  </Container>

  )
}

export default LoginPage;
