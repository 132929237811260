
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl'
import { Button, Typography, Card, CardMedia, CardContent } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { ServiceType } from '../types/DatabaseTypes';


const css = `
.whiteButton  {
  color: black;
  background-color: white;
}
.whiteButton:hover {
  background-color: lightgrey;
}
`;

const handleClick = (url: any) => {
  //window.location.replace(url);
  window.location.href = url;
}

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
  },
});
const isMobile = window.innerWidth <= 768;

function Service(props: ServiceType) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id={props.id_hash??'unknown'}>
      <style>
        {css}
      </style>
      {props.id && !isMobile &&
        <Card sx={{
          display: 'flex',
          justifyContent: 'center',
          width: 640,
          height: 240,
          borderStyle: 'dotted',
          borderWidth: '1px',
          borderColor: 'lightgrey',
          borderRadius: 2,
          padding: 0,
          color: props.text_color,
          backgroundColor: props.background_color
        }}>
          <CardMedia
            component="img"
            alt="image"
            sx={{ alignSelf: 'center', minWidth: 260, maxWidth: 260, height: 194, border: 1 }}
            image={props.image_name}
          />
          <CardContent sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-around'
          }}>
            <Typography variant="h1"> {props.title}  </Typography>
            <Typography variant="body1"> {props.content} </Typography>
            <Button
              className='whiteButton'
              onClick={() => handleClick(props.landing_page)}
              variant='contained'
              sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
            >
              <FormattedMessage id='services.show' />
            </Button>
          </CardContent>
        </Card>
      }
      {props.id && isMobile &&
        <Card sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: 410,
          borderStyle: 'dotted',
          borderWidth: '1px',
          borderColor: 'lightgrey',
          borderRadius: 2,
          padding: 0,
          color: props.text_color,
          backgroundColor: props.background_color
        }}>
          <CardMedia
            component="img"
            alt="image"
            sx={{ alignSelf: 'center', minWidth: 260, maxWidth: 260, height: 194, border: 1 }}
            image={props.image_name}
          />
          <CardContent sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}>
            <Typography variant="h1"> {props.title}  </Typography>
            <Typography variant="body1"> {props.content} </Typography>
            <Button
              className='whiteButton'
              onClick={() => handleClick(props.landing_page)}
              variant='contained'
              sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
            >
              <FormattedMessage id='services.show' />
            </Button>
          </CardContent>
        </Card>
      }
    </div >
  );
}

export default Service;

