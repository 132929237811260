import * as React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl'

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    body1: {
      fontSize: 16,
      fontWeight: 800
    },
    body2: {
      fontSize: 16,
      fontWeight: 500
    },
  },
});

export default function FaqPage() {
  const faqList = useSelector(state => state.faqList);
  const language = useSelector(state => state.language);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Typography variant="h1" sx={{ p: 2 }}>
          <FormattedMessage id='faq.faq' />
        </Typography>
        {faqList && faqList.length &&
          <div>
            {
              faqList.map((item) => (
                item.lng === language && (<div>
                  <Accordion
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                    id={item.id}
                    sx={{ pb: 1, m: 2, boxShadow: 3 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ color: 'text.secondary' }}
                    >
                      <Typography variant="body1"
                        sx={{ width: '100%', flexShrink: 0 }}>
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2"
                        sx={{ color: 'text.secondary' }}>
                        {item.content}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>)
              )
              )}
          </div>
        }

      </div>
    </ThemeProvider>
  );
}