import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import RootState from '../types/RootState';
import BoatSearchBarFormType from '../types/BoatSearchBarFormType'
import Button from '@mui/material/Button';
import SingleReservationDate from "../components/SingleReservationDate";
import SingleCustomerDataInput from "../components/SingleCustomerDataInput";
import HajoType from "../types/HajoType";
import CustomerDataInputFormType from "../types/CustomerDataInputFormType";
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';

const BlycTheme=createTheme();

BlycTheme.typography.button = {
  "fontFamily": 'serif',
  "fontSize": '1.2rem',
}
BlycTheme.typography.body1 = {
  "fontFamily": 'serif',
  "fontSize": '1.2rem',
}
BlycTheme.typography.body2 = {
  "fontFamily": 'serif',
  "fontSize": '1.2rem',
}


function SinglePageCustomer() {
  const navigate = useNavigate();
  const testMode: boolean = useSelector((state: RootState) => state.testMode);
  const hajoAr: number = useSelector((state: RootState) => state.hajoAr);
  const kapitanyAr: number = useSelector((state: RootState) => state.kapitanyAr);
  const database: string = useSelector((state: RootState) => state.database);
  const hajo: HajoType = useSelector((state: RootState) => state.selectedHajo);
  const customerDataInputForm: CustomerDataInputFormType = useSelector((state: RootState) => state.customerDataInputForm);
  const boatSearchBarForm: BoatSearchBarFormType = useSelector((state: RootState) => state.boatSearchBarForm);
  var isDateSelected: boolean = true;
  if (boatSearchBarForm.dateFrom.getTime() == 0 && boatSearchBarForm.dateTo.getTime() == 0) {
    isDateSelected = false;
  }
  const handleCalendar = () => {
    navigate('/')
  }
  const handleConfirmation = () => {
    navigate('/spconfirmation')
  }

  return (
    <>
      <ThemeProvider theme={BlycTheme}>
        <CssBaseline />
        {database === 'DEV' &&
          <Alert
            variant='standard'
            severity='warning'
            style={{ width: '100%' }}
            sx={{ p: 0 }}
          >
            <Typography gutterBottom variant="body1">Warning! This is DEV database.
            </Typography>
          </Alert>
        }
        {(database === 'DEV' || testMode) &&
          <Alert
            variant='standard'
            severity='info'
            style={{ width: '100%' }}
            sx={{ p: 0 }}
          >
            <Typography gutterBottom variant="body1"> Hajó ár: {hajoAr} Kapitány ár: {kapitanyAr}
            </Typography>
          </Alert>
        }
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Button
            size='small'
            sx={{ textTransform: 'none' }}
            variant="contained"
            onClick={handleCalendar}>
            <FormattedMessage id='button.Calendar' />
          </Button>
          <SingleReservationDate
            key={'1'}
            dateFrom={boatSearchBarForm.dateFrom}
            dateTo={boatSearchBarForm.dateTo} />
          <Button
            size='small'
            sx={{ textTransform: 'none' }}
            variant="contained"
            onClick={handleConfirmation}
            disabled={!customerDataInputForm.isValid || !isDateSelected}>
            <FormattedMessage id='button.Finalize.Reservation' />
          </Button>
        </Stack>

        <SingleCustomerDataInput />

      </ThemeProvider>
    </>
  )
}

export default SinglePageCustomer;
