import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import Axios from "axios";
import RootState from '../types/RootState';
import BoatSearchBarFormType from '../types/BoatSearchBarFormType'
import Grid from "@mui/material/Grid";
import ReservationType from '../types/ReservationType'
import Calendar from 'react-calendar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import 'react-calendar/dist/Calendar.css';
import { addMonths, differenceInCalendarDays, isBefore, isAfter, startOfMonth, lastDayOfMonth, startOfDay } from 'date-fns';
import 'react-calendar/dist/Calendar.css';
import Button from '@mui/material/Button';
import SingleReservationDate from "../components/SingleReservationDate";
import QueryBoatByGuids from "../tools/QueryBoatByGuids";
import HajoType from "../types/HajoType";
import SoftError from '../components/SoftError';
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { OnArgs } from "react-calendar/dist/cjs/shared/types";
import { d2s } from '../tools/Tools';
import Typography from "@mui/material/Typography";
import { Box, CssBaseline, Divider, Stack, ThemeProvider, createMuiTheme, createTheme } from "@mui/material";
import CustomerDataInputFormType from "../types/CustomerDataInputFormType";

const BlycTheme = createTheme({
  typography: {
    "fontFamily": "'Libre Baskerville', serif",
    "fontSize": 14,
  },
})


const emptyReservation: ReservationType = {
  date: new Date(),
  reserved: -1,
}

interface Props {
  charterGuid: string,
  boatGuid: string,
}

function SinglePageCalendar(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const testMode: boolean = useSelector((state: RootState) => state.testMode);
  const database: string = useSelector((state: RootState) => state.database);
  const hajo: HajoType = useSelector((state: RootState) => state.selectedHajo);
  const boatSearchBarForm: BoatSearchBarFormType = useSelector((state: RootState) => state.boatSearchBarForm);
  const customerDataInputForm: CustomerDataInputFormType = useSelector((state: RootState) => state.customerDataInputForm);
  const [reservationList, setReservationList] = useState([]);
  const [monthList, setMonthList] = useState(new Array);
  const [minDateLimit, setMinDateLimit] = useState(new Date());
  const [maxDateLimit, setMaxDateLimit] = useState(new Date());
  const [calendarDefaultValue, setCalendarDefaultValue] = useState(new Date());
  const [isSubmitVisible, setIsSubmitVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [activeStartDate_2, setActiveStartDate_2] = useState(new Date());
  const [activeStartDate_3, setActiveStartDate_3] = useState(new Date());

  const [nrMonthsDisplayed, setNrMonthsDisplayed] = useState(1);
  const [showPrev_1, setShowPrev_1] = useState(false);
  const [showNext_1, setShowNext_1] = useState(true);
  const [showPrev_2, setShowPrev_2] = useState(false);
  const [showNext_2, setShowNext_2] = useState(false);
  const [showPrev_3, setShowPrev_3] = useState(false);
  const [showNext_3, setShowNext_3] = useState(true);

  function procReservationList(x: Array<ReservationType>) {
    var dMin: Date = new Date(9999, 0, 1);
    var dMax: Date = new Date(0, 0, 1);
    var startDates: Date[] = new Array(0);
    for (var i: number = 0; i < x.length; i++) {
      x[i].date = new Date(x[i].date); // ez azért kell, mert itt még string van a 'date' mezőben! :(
      var year: number = x[i].date.getUTCFullYear();
      var month: number = x[i].date.getUTCMonth();
      var startDate: Date = new Date(year, month, 1);
      var index = startDates.findIndex((x) => { return x.getTime() === startDate.getTime() });
      if (index < 0) startDates.push(startDate);
      if (x[i].date < dMin) { dMin = x[i].date; }
      if (x[i].date > dMax) { dMax = x[i].date; }
    }
    setMinDateLimit(dMin);
    setMaxDateLimit(dMax);
    return (startDates);
  }

  const fetchCalendar = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    setReservationList([]);
    setMonthList(new Array);
    if (!boatSearchBarForm.dateFrom) {
      dispatch({ type: 'calendarSelectDateFrom', dateFrom: new Date(0) });
    }
    if (!boatSearchBarForm.dateTo) {
      dispatch({ type: 'calendarSelectDateTo', dateTo: new Date(0) });
    }
    if (boatSearchBarForm.dateFrom.getTime() == 0 && boatSearchBarForm.dateTo.getTime() == 0) {
      setIsSubmitVisible(false);
    }
    else {
      setIsSubmitVisible(true);
    }

    try {
      const selectedHajok = await QueryBoatByGuids(props.charterGuid, props.boatGuid);
      const selectedHajo = selectedHajok[0];
      if (!selectedHajo) {
        var s = 'Boat not found by charterGuid: ' + props.charterGuid + ' boatGuid: ' + props.boatGuid;
        throw new Error(s);
      }
      const req = {
        boatId: selectedHajo.id
      }
      dispatch({ type: 'selectedHajo', payload: selectedHajo });
      const response = await Axios.post(window.backendUrl + 'query_calendar', req);
      if (response.status === 200) {
        setReservationList(response.data);
        var startDates: Date[] = procReservationList(response.data);
        setMonthList(startDates);
      }
      else {
        console.error("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
    }
    catch (error) {
      setIsError(true);
      console.error(error);

    };
    setIsLoading(false);
    handleResize();
    if (boatSearchBarForm.dateFrom.getTime() === 0) {
      updateActiveStartDates(new Date());
    }
    else {
      updateActiveStartDates(boatSearchBarForm.dateFrom);
    }

  }, []);

  useEffect(() => {
    fetchCalendar();
  }, [])

  const now = new Date();

  function isSameDay(a?: Date, b?: Date): boolean {
    var res: boolean = false;
    if (a === null || b === null) {
      res = false;
    }
    else {
      res = differenceInCalendarDays(a ?? new Date(), b ?? new Date()) === 0;
    }
    return res;
  }

  function isMyAfter(date?: Date, lowerLimit?: Date): boolean {
    var res: boolean = false;
    if (date === null || lowerLimit === null) {
      res = false;
    }
    else {
      res = isAfter(date ?? new Date(), lowerLimit ?? new Date());
    }
    return res;
  }

  function isMyBefore(date?: Date, upperLimit?: Date): boolean {
    var res: boolean = false;
    if (date === null || upperLimit === null) {
      res = false;
    }
    else {
      res = isBefore(date ?? new Date(), upperLimit ?? new Date());
    }
    return res;
  }

  function withinRange(date?: Date, lowerLimit?: Date, upperLimit?: Date): boolean {
    var res: boolean = false;
    if (date === null || lowerLimit === null || upperLimit === null) {
      res = false;
    }
    else if (isSameDay(date, lowerLimit) ||
      isSameDay(date, upperLimit) ||
      isMyAfter(date, lowerLimit) && isMyBefore(date, upperLimit)) {
      res = true;
    }
    return res;
  }

  const css = `

  .react-calendar {
    margin: 1px;
    width: 280px;
    max-width: 100%;
    background-color: white;
    border: 0;
    font-family: "'Libre Baskerville', serif";    
    line-height: 1.125em;
  }
  abbr[title] {
    text-decoration:  none;
  }
 
  .react-calendar__month-view__weekdays {
    text-decoration-line: unset;
    text-transform: none;    
  }
  .react-calendar__month-view__days__day--weekend {
    cursor: not-allowed;
    color: #c6c6c6;
    background-color: #eee; 
  }

  .react-calendar__tile--now {
    background: #ffff76;
  }

   .react-calendar .selected_day_notactive {
    color: #c6c6c6;
    background-color: #eee;    
    margin: 1px;
  }
    .react-calendar .selected_day_notactive:focus,
    .react-calendar .selected_day_notactive:hover {
      color: #c6c6c6;
      background-color: #eee;    
      margin: 1px;
  }
   .react-calendar .selected_day_notapplicable {
    cursor: not-allowed;
    color: #c6c6c6;
    background-color: #eee;    
    margin: 1px;
    }
 
   .react-calendar .selected_day_free {
      color: #777;
      background-color: #bde6cb;
      margin: 1px;
    }

    .react-calendar .selected_day_free:hover {
      color: #fff;
      background-color: #75a68d;
      margin: 1px;
    }

   .react-calendar .selected_day_selectedrange {
      color: #777;
      background-color: #75a68d;      
      margin: 1px;
    }
    .react-calendar .selected_day_selectedrange:hover, 
    .react-calendar .selected_day_selectedrange:focus {
      color: #fff;
      background-color: #75a684;      
      margin: 1px;
    }    

    .react-calendar .selected_day_reserved {
      color: #fff;
      background-color: #e47d8f;
      margin: 1px;
    }

    .react-calendar .selected_day_reserved:hover {
      color: #fff;
      background-color: #ff9dab;
      margin: 1px;
    }

   .react-calendar .selected_day_conflict {
      color: #fff;
      background-color: #e47d8f; 
      margin: 1px;      
    }

    .react-calendar .selected_day_conflict:hover {
      color: #fff;
      background-color: #ff9dab; 
      margin: 1px;      
    }

   .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%; 

    .react-calendar__tile {
      max-width: initial !important;
      font-family: "'Libre Baskerville', serif";          
      font-size: 14px;
    }
  }

  .react-calendar__navigation button {
    font-family: "'Libre Baskerville', serif";          
  }

  .react-calendar__navigation button:disabled {
    background-color: white;
    color: black;
  }

  .react-calendar__navigation button:enabled {
    color: #1976d2;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    color: #1976d2;
  }
  `;



  type CustomTileProps = {
    date: Date,
    view: String,
    activeStartDate: Date,
  }

  function tileClassName(props: CustomTileProps) {
    var date: Date = props.date;
    var found: ReservationType = reservationList.find(myFunction) ?? emptyReservation;
    function myFunction(value: ReservationType, index: any, array: any) {
      return isSameDay(value.date, date);
    };
    var res: string = "";
    if (isBefore(date, props.activeStartDate)) {
      res = 'selected_day_notactive';
    }
    else if (isAfter(date, lastDayOfMonth(props.activeStartDate))) {
      res = 'selected_day_notactive';
    }
    else if (isBefore(startOfDay(date), startOfDay(new Date()))) {
      res = 'selected_day_notactive';
    }
    else {
      if (found.reserved === -1) {
        res = 'selected_day_notapplicable'
      }
      else if (found.reserved === 0) {
        res = 'selected_day_free'
      }
      else if (found.reserved === 1) {
        res = 'selected_day_reserved'
      }
      if (withinRange(date, boatSearchBarForm.dateFrom, boatSearchBarForm.dateTo)) {
        res = 'selected_day_selectedrange';
      }
    }
    if (found.reserved === 1 && withinRange(date, boatSearchBarForm.dateFrom, boatSearchBarForm.dateTo)) {
      res = 'selected_day_conflict'
      setIsSubmitVisible(false);
    }
    return res;
  }

  function setNewRange(value: Date) {
    setIsSubmitVisible(true);
    if (boatSearchBarForm.dateFrom.getTime() === new Date(0).getTime() && boatSearchBarForm.dateTo.getTime() === new Date(0).getTime()) {
      dispatch({ type: 'calendarSelectDateFrom', dateFrom: value });
      dispatch({ type: 'calendarSelectDateTo', dateTo: value });
    }
    else if (boatSearchBarForm.dateFrom.getTime() !== boatSearchBarForm.dateTo.getTime()) {
      dispatch({ type: 'calendarSelectDateFrom', dateFrom: value });
      dispatch({ type: 'calendarSelectDateTo', dateTo: value });
    }
    else if (isBefore(value, boatSearchBarForm.dateFrom)) {
      dispatch({ type: 'calendarSelectDateFrom', dateFrom: value });
    }
    else if (isAfter(value, boatSearchBarForm.dateTo)) {
      dispatch({ type: 'calendarSelectDateTo', dateTo: value });
    }
    else {
      //console.log('Az egyetlen kijelöltre kattintottunk, nincs teendő');
    }
  }

  function onClickDay(value: any, event: any) {
    if (event.currentTarget.classList.value.includes('selected_day_reserved')) {
      //console.log('Day clicked: ',d2s(value), ' this is reserved!');
      //console.log('Event: ');      
    }
    else if (event.currentTarget.classList.value.includes('selected_day_notactive')) {
      //console.log('Day clicked: ',d2s(value), ' this is not active');
    }
    else {
      setNewRange(value);
    }
    /*
    console.log(event.currentTarget.classList);
    event.stopPropagation();    
    event.preventDefault();
    console.log(event.target);         
    return false;
    */
  }

  async function handleCustomer() {
    try {
      const req = {
        boatSearchBarForm: {
          boatId: boatSearchBarForm.boatId,
          dateFrom: d2s(boatSearchBarForm.dateFrom),
          dateTo: d2s(boatSearchBarForm.dateTo),
          reservationDurationType: boatSearchBarForm.reservationDurationType,
          timeFrom: boatSearchBarForm.timeFrom,
          timeTo: boatSearchBarForm.timeTo,
          withSkipper: boatSearchBarForm.withSkipper,
        }
      }
      //console.log('boatSearchBarForm:', boatSearchBarForm);
      dispatch({ type: 'SetHajoAr', payload: 0 });
      dispatch({ type: 'SetKapitanyAr', payload: 0 });
      const response = await Axios.post(window.backendUrl + 'get_price', req);
      if (response.status === 200) {
        //setReservationList(response.data);
        //var startDates: Date[] = procReservationList(response.data);
        //setMonthList(startDates);
        //console.log('response.data: ');
        //console.log(response.data);
        try {
          let result = response.data.map((x: {}) => Object.keys(x));
          let key = result.flat(1);
          let val = response.data[Object.keys(response.data)[0]];
          var rs = JSON.parse(val[key]);
          var total_price = JSON.parse(rs.TOTAL_PRICE);
          var kapitanyAr = JSON.parse(rs.SKIPPER_PRICE);
          var hajoAr = JSON.parse(rs.BOAT_PRICE);
          console.log('Hajó ár: ', hajoAr, ' kapitány ár: ', kapitanyAr, ' teljes ár: ', total_price);
          var total_days = JSON.parse(rs.TOTAL_DAYS);
          var total_hours = JSON.parse(rs.TOTAL_HOURS);
          dispatch({ type: 'SetHajoAr', payload: hajoAr });
          dispatch({ type: 'SetKapitanyAr', payload: kapitanyAr });
        }
        catch (ex) {
          console.error(ex);
        }

      }
      else {
        console.error("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
    }
    catch (error) {
      setIsError(true);
      console.error(error);

    };



    navigate('/spcustomer')
  }

  const renderPrevLabel = <div> <Button variant="contained" endIcon={<NavigateBeforeIcon />}>  </Button></div>;
  const renderNextLabel = <div> <Button variant='contained' startIcon={<NavigateNextIcon />}>  </Button></div>;

  function onActiveStartDateChange({ action, activeStartDate, value, view }: OnArgs) {
    //console.log('onActiveStartDateChange ', d2s(activeStartDate));
    var asd: Date; // active start date
    //console.log('action', action);
    //console.log('activeStartDate', activeStartDate);
    //console.log('value', value);
    //console.log('view', view);
    if (activeStartDate === null) {
      asd = new Date();
    }
    else {
      asd = activeStartDate;
    }
    if (action === 'next') {
      //asd = addMonths(activeStartDate ?? new Date(), 1);
    }
    else if (action === 'prev') {
      //asd = addMonths(activeStartDate ?? new Date(), -1);
    }
    if (isMyBefore(asd, new Date())) {
      asd = new Date();
    }
    updateActiveStartDates(asd);
  }

  function updateActiveStartDates(asd: Date) {
    //console.log('updateActiveStartDates: ASD:', d2s(asd));
    const asd_2: Date = startOfMonth(addMonths(asd, 1));
    const asd_3: Date = startOfMonth(addMonths(asd, 2));
    setActiveStartDate(asd);
    setActiveStartDate_2(asd_2);
    setActiveStartDate_3(asd_3);
  }

  const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleResize() {
    console.log('handleResize() ', 'innerWidth:', window.innerWidth, ' innerHeight:', window.innerHeight, ' outerWidth: ', window.outerWidth, ' outerHeight:', window.outerHeight);
    setViewportSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
    var m: number = 1; // number of months shown
    if (window.innerWidth > 900) {
      m = 3;
    }
    else if (window.innerWidth > 600) {
      m = 2;
    }
    else {
      m = 1;
    }
    setNrMonthsDisplayed(m);
    if (m === 1) {
      setShowPrev_1(true);
      setShowNext_1(true);
    }
    else if (m === 2) {
      setShowPrev_1(true);
      setShowNext_1(false);
      setShowPrev_2(false);
      setShowNext_2(true);
    }
    else if (m === 3) {
      setShowPrev_1(true);
      setShowNext_1(false);
      setShowPrev_2(false);
      setShowNext_2(false);
      setShowPrev_3(false);
      setShowNext_3(true);
    }
  }

  return (
    <div>
      <ThemeProvider theme={BlycTheme}>
        <CssBaseline />
        <style>
          {css}
        </style>
        {database === 'DEV' &&
          <Alert
            variant='standard'
            severity='warning'
            style={{ width: '100%' }}
            sx={{ p: 0 }}
          >
            <Typography gutterBottom variant="body1">Warning! This is DEV database.
            </Typography>
          </Alert>
        }

        {isError &&
          <SoftError />
        }
        {!isError && isLoading &&
          <Alert
            variant='standard'
            severity='info'
            style={{ width: '100%' }}
            sx={{ p: 0 }}
          >
            <FormattedMessage id='alert.data_fetch_pending' />
          </Alert>
        }
        {!isError && !isLoading &&
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Button
                sx={{ textTransform: 'none', visibility: 'hidden' }}
                variant="contained"
                onClick={handleCustomer}
                disabled={true}
              >
              </Button>
              <SingleReservationDate
                key={'1'}
                dateFrom={boatSearchBarForm.dateFrom}
                dateTo={boatSearchBarForm.dateTo} />
              <Button
                sx={{ textTransform: 'none' }}
                variant="contained"
                onClick={handleCustomer}
                disabled={!isSubmitVisible}>
                <FormattedMessage id='button.Reservation' />
              </Button>
            </Stack>
            <Card sx={{ border: "none", boxShadow: "none" }} >
              <CardContent sx={{ m: 0, p: 0, border: 'none', boxShadow: "none" }} >
                <Grid container justifyContent="space-around" alignItems="flex-start" id='my-grid-container'>
                  <Grid item>
                    <Calendar
                      tileClassName={tileClassName}
                      onClickDay={onClickDay}
                      onActiveStartDateChange={onActiveStartDateChange}
                      activeStartDate={activeStartDate}
                      defaultView="month"
                      defaultValue={calendarDefaultValue}
                      showNavigation={true}
                      prevLabel={showPrev_1 ? renderPrevLabel : null}
                      nextLabel={showNext_1 ? renderNextLabel : null}
                      prev2Label={null}
                      next2Label={null}
                      minDate={minDateLimit}
                      maxDate={maxDateLimit}
                      tileContent={null}
                      minDetail='month'
                      maxDetail='month'
                      locale="hu"
                    />
                  </Grid>
                  {nrMonthsDisplayed > 1 &&
                    <Grid item>
                      <Calendar
                        tileClassName={tileClassName}
                        onClickDay={onClickDay}
                        onActiveStartDateChange={onActiveStartDateChange}
                        activeStartDate={activeStartDate_2}
                        defaultView="month"
                        defaultValue={calendarDefaultValue}
                        showNavigation={true}
                        prevLabel={showPrev_2 ? renderPrevLabel : null}
                        nextLabel={showNext_2 ? renderNextLabel : null}
                        prev2Label={null}
                        next2Label={null}
                        minDate={minDateLimit}
                        maxDate={maxDateLimit}
                        tileContent={null}
                        minDetail='month'
                        maxDetail='month'
                        locale="hu"
                      />
                    </Grid>
                  }
                  {nrMonthsDisplayed > 2 &&
                    <Grid item>
                      <Calendar
                        tileClassName={tileClassName}
                        onClickDay={onClickDay}
                        onActiveStartDateChange={onActiveStartDateChange}
                        activeStartDate={activeStartDate_3}
                        defaultView="month"
                        defaultValue={calendarDefaultValue}
                        showNavigation={true}
                        prevLabel={showPrev_3 ? renderPrevLabel : null}
                        nextLabel={showNext_3 ? renderNextLabel : null}
                        prev2Label={null}
                        next2Label={null}
                        minDate={minDateLimit}
                        maxDate={maxDateLimit}
                        tileContent={null}
                        minDetail='month'
                        maxDetail='month'
                        locale="hu"
                      />
                    </Grid>
                  }
                </Grid>
              </CardContent>
            </Card>
          </>
        }
      </ThemeProvider>
    </div >
  )
}

export default SinglePageCalendar;

/*
  const css = `
  .react-calendar {
    background-color: white;
    margin: 1px;
   }
   .react-calendar .selected_day_notactive {
    background-color: lightgrey;
    color: lightgrey;
    margin: 1px;
  }
   .react-calendar .selected_day_notapplicable {
      background-color: lightgrey;
      margin: 1px;
    }
   .react-calendar .selected_day_reserved {
      background-color: salmon;
      margin: 1px;
    }
   .react-calendar .selected_day_free {
      background-color: lightgreen;
      margin: 1px;
    }
   .react-calendar .selected_day_selectedrange {
      background-color: #006edc;
      margin: 1px;
    }
   .react-calendar .selected_day_conflict {
      background-color: #006edc;
      outline: 3px solid red;
      margin: 1px;
    }

   .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%; 

    .react-calendar__tile {
      max-width: initial !important;
    }
  }

  .react-calendar__navigation button:disabled {
    background-color: white;
    color: black;
  }

  .react-calendar__navigation button:enabled {
    color: #1976d2;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    color: #1976d2;
  }
  `;
*/
