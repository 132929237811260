
import React, { useState, useEffect, useCallback } from "react";
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import Axios from "axios";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {FormattedMessage} from 'react-intl'


//const os = require('os');

function Info() {

  const navigate = useNavigate();  

  const handleInfo = (id) => {
    console.log('Info pressed'); 
    navigate('/info');
  };


  const searchInfo = useCallback( async() => {
    console.log('*************');
    console.log('hash: '+window.location.hash);
    console.log('host: '+window.location.host);
    console.log('hostname: '+window.location.hostname);
    console.log('href: '+window.location.href);
    console.log('origin: '+window.location.origin);
    console.log('pathname: '+window.location.pathname);
    console.log('port: '+window.location.port);
    console.log('protocol: '+window.location.protocol);
    console.log('search: '+window.location.search);
/*
    try{
      const response=await Axios.post(window.backendUrl+'/info');
      if(response.status === 200){
      }
      else{
        console.log("Error! Response of Axio post is not 200, but "+response.status+" "+response.statusText);    
      }
    }
    catch(error){
      console.log(error);
    };
    */
  },[]);


  return (
    <div>
      <h1><FormattedMessage id='home.title'/></h1>
      <React.Fragment>
      <CssBaseline />
      <Container fixed>
        <Box sx={{ 
          width: 'auto',
          borderStyle: 'solid',
             borderWidth: '2px',
             height: '100px', 
             borderRadius: 3,
             padding: 3,
             alignItems: 'center'
             }}>
<Grid container spacing={2}>
  <Grid item xs={2}  sx={{alignItems: 'center'}}  >
  <Button variant="contained" onClick={handleInfo}>
  <FormattedMessage id='button.INFO'/>
  </Button>
  <button onClick={() => {searchInfo()}}>(Get info, remove before flight)</button>  
  </Grid>
</Grid>
              </Box>
      </Container>
    </React.Fragment>
    </div>
  )
}

export default Info
