import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { useSelector, useDispatch, useStore } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AcUnit from '@mui/icons-material/AcUnit';
import Badge from '@mui/icons-material/Badge';
import BeachAccess from '@mui/icons-material/BeachAccess';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import CheckBox from '@mui/icons-material/CheckBox';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import Description from '@mui/icons-material/Description';
import DryCleaning from '@mui/icons-material/DryCleaning';
import ElectricMeter from '@mui/icons-material/ElectricMeter';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import Flatware from '@mui/icons-material/Flatware';
import GasMeter from '@mui/icons-material/GasMeter';
import Group from '@mui/icons-material/Group';
import Hotel from '@mui/icons-material/Hotel';
import Kitchen from '@mui/icons-material/Kitchen';
import KingBed from '@mui/icons-material/KingBed';
import LocalCafe from '@mui/icons-material/LocalCafe';
import LocalGasStation from '@mui/icons-material/LocalGasStation';
import LocalLaundryService from '@mui/icons-material/LocalLaundryService';
import LocalPolice from '@mui/icons-material/LocalPolice';
import Microwave from '@mui/icons-material/Microwave';
import OutdoorGrill from '@mui/icons-material/OutdoorGrill';
import Pets from '@mui/icons-material/Pets';
import Power from '@mui/icons-material/Power';
import Radio from '@mui/icons-material/Radio';
import Radar from '@mui/icons-material/Radar';
import Sailing from '@mui/icons-material/Sailing';
import Shower from '@mui/icons-material/Shower';
import Settings from '@mui/icons-material/Settings';
import SingleBed from '@mui/icons-material/SingleBed';
import SkipNext from '@mui/icons-material/SkipNext';
import SmartToy from '@mui/icons-material/SmartToy';
import Soap from '@mui/icons-material/Soap';
import SolarPower from '@mui/icons-material/SolarPower';
import Straighten from '@mui/icons-material/Straighten';
import Speaker from '@mui/icons-material/Speaker';
import Speed from '@mui/icons-material/Speed';
import Star from '@mui/icons-material/Star';
import WbSunny from '@mui/icons-material/WbSunny';
import Surfing from '@mui/icons-material/Surfing';
import Tv from '@mui/icons-material/Tv';
import Wash from '@mui/icons-material/Wash';
import Waves from '@mui/icons-material/Waves';
import Wc from '@mui/icons-material/Wc';

import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { FormattedMessage, useIntl } from 'react-intl'
//import DestinationSelect from '../DestinationSelect.js'
//import BoatTypeSelect from '../BoatTypeSelect.js'
import PictureCarousel from '../components/PictureCarousel.js';

//import BoatSearchBar from "../components/BoatSearchBar.js";
import ReservationDetails from "../components/ReservationDetails.tsx"
import PricingDetails from "../components/PricingDetails.tsx";
import { textAlign } from "@mui/system";
import { d2s } from '../tools/Tools.tsx';
import BoatDetails from "../components/BoatDetails.tsx";
import LoadBoatData from '../tools/LoadBoatData.tsx';
import LoadPricing from '../tools/LoadPricing';

function BoatDetailsPage(props) {
  document.title = 'BoatDetailsPage';
  console.log('BoatDetailsPage Props:', props);
  //  const [detailResultList, setDetailResultList] = useState([]);
  const [detailResult, setDetailResult] = useState({});
  const [product, setProduct] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const boatSearchBarForm = useSelector(state => state.boatSearchBarForm);

  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useStore();
  //console.log('This is store:', store);
  //console.log('Now displaying boat details, boat ID: ', boatSearchBarForm.boatId);

  const fetchBoat = useCallback(async () => {
    if (boatSearchBarForm.boatId) {
      //console.log('fetchBoat(',boatSearchBarForm.boatId,')');
      try {
        const selectedHajo = await LoadBoatData(boatSearchBarForm.boatId);
        dispatch({ type: 'selectedHajo', payload: selectedHajo });
        await LoadPricing(store, boatSearchBarForm);

//        const quote = await LoadPricing(store, boatSearchBarForm);
        //dispatch({ type: 'Quote', payload: quote });
      }
      catch (error) {
        console.error(error);

      };
    }
    else {
      console.log('fetchBoat(*semmi*)');
    }
  }, []);

  useEffect(() => {
    fetchBoat();
  }, [])





  return (
    <div>
      <BoatDetails />
      {false &&
        <>


          <ReservationDetails />
          <PricingDetails />
          <PictureCarousel />
        </>
      }
    </div >
  );
}

export default BoatDetailsPage
