import Axios from "axios";
import { useSelector } from "react-redux";
import RootState from "../types/RootState";
import { DocumentTemplateType, emptyDocumentTemplate } from "../types/DatabaseTypes";

async function load(store: any) {
  const x = await _load();
  console.log('Document Templates: ', x);
  store.dispatch({ type: 'DocumentTemplateList', payload: x });
}

async function _load() {
  var response;
  try {
    response = await Axios.post(window.backendUrl + 'query_document_templates');
    if (response.status === 200) {
    } else {
      console.log("Error! Response of Axios post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }
  } catch (err) {
    console.error(err);
  }
  return response?.data;
}

/*

function getTitle(lng: string, docType: string) {
  const documentTemplateList = useSelector((state: RootState) => state.documentTemplateList);
  console.log('getTitle', lng, docType);
  var item: DocumentTemplateType = emptyDocumentTemplate;
  if (documentTemplateList?.length > 0) {
    item = documentTemplateList.find(x => x.lng === lng && x.doc_type === docType) ?? emptyDocumentTemplate;
  }
  return item?.title;
}

function getSubtitle(lng: string, docType: string) {
  const documentTemplateList = useSelector((state: RootState) => state.documentTemplateList);
  console.log('getTitle', lng, docType);
  var item: DocumentTemplateType = emptyDocumentTemplate;
  if (documentTemplateList?.length > 0) {
    item = documentTemplateList.find(x => x.lng === lng && x.doc_type === docType) ?? emptyDocumentTemplate;
  }
  return item?.subtitle;
}

function getBody(lng: string, docType: string) {
  const documentTemplateList = useSelector((state: RootState) => state.documentTemplateList);
  var item: DocumentTemplateType = emptyDocumentTemplate;
  if (documentTemplateList?.length > 0) {
    item = documentTemplateList.find(x => x.lng === lng && x.doc_type === docType) ?? emptyDocumentTemplate;
  }
  return item?.body;
}
*/

//export { load, getTitle, getSubtitle, getBody }
export { load }
