
import React from "react";

function Error() {
  return (
    <div>
      <h3>Valami hiba történt. Kérem, ismételje meg később.</h3>
    </div>
  )
}

export default Error
