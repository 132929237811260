
import Alert from "@mui/material/Alert";
import { FormattedMessage } from "react-intl";

function SoftError() {
  return (
    <Alert variant='standard' severity='warning' style={{ width: '100%' }}>
      <FormattedMessage id='alert.error' />
    </Alert>
  )
}
export default SoftError
