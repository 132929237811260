import Axios from "axios";

export default async function QueryBoatByGuids(charterGuid: string, boatGuid: string) {
  var boat;
  try {
    const req = {
      charterGuid: charterGuid,
      boatGuid: boatGuid,
    }
    const response = await Axios.post(window.backendUrl + 'query_boat_by_guids', req);
    if (response.status === 200) {
      //console.log('response.data:');
      //console.log(response.data);
      boat = response.data
    }
    else {
      console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
      throw new Error('Something went wrong');
    }
  }
  catch (err) {
    console.error(err);
  }
  finally {
    //console.log('QueryBoatByGuids ended, boat is:');
    //console.log(boat);
  }
  return boat;
};




