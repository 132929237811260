//import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { Box, Button, Card, CardContent, CardMedia, Grid, List, ListItem, ListItemText, Stack, TextField, ThemeProvider, Typography, makeStyles } from "@mui/material";


import { FormattedMessage, useIntl } from 'react-intl'
import BoatSearchBar from '../components/BoatSearchBar';
import BoatCardList from '../components/BoatCardList';
import RootState from '../types/RootState';
import BoatSearchBarFormType from '../types/BoatSearchBarFormType'
import ServicesAll from '../components/ServicesAll';

import Group49 from '../components/Group49';


import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 42,
      fontWeight: 800
    },
    h2: {
      fontSize: 18,
      fontWeight: 500
    },
  }
});



const isMobile = window.innerWidth <= 768;

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forcedCharterGuid = useSelector((state) => state.forcedCharterGuid);
  const handleSearch = () => {
    //console.log('Search button pressed on home page');
    dispatch({ type: 'clearBoatId', null: null });
    navigate('/search')
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        {!isMobile &&
          <Card sx={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={'https://vizre.hu/media/noilabak.jpg'}
              />
              <div style={{ position: "absolute", color: "white", top: "30%", left: "15%" }}>
                <Typography variant="h1" >
                  Éld át velünk
                </Typography>
                <Typography variant="h1" >
                  a hajózás
                </Typography>
                <Typography variant="h1">
                  örömét!
                </Typography>
                <Typography variant="h2">
                  Barátokkal, családdal, kalandoroknak
                </Typography>
              </div>
              <div style={{ position: "absolute", color: "white", top: "70%", left: "5%" }}>
                <BoatSearchBar
                  direction={'row'}
                  withReservationButton={false}
                  onSearch={handleSearch}
                  forcedCharterGuid={forcedCharterGuid}
                />
              </div>
            </div>
          </Card>
        }
        {isMobile &&
          <Card sx={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={'https://vizre.hu/media/noilabak.jpg'}
              />
              <div style={{
                position: "absolute",
                color: "white",
                top: "30%",
                left: "15%"
              }}>
                <Typography variant="h1" >
                  Éld át velünk
                </Typography>
                <Typography variant="h1" >
                  a hajózás örömét!
                </Typography>
              </div>
                <BoatSearchBar
                  direction={'column'}
                  withReservationButton={false}
                  onSearch={handleSearch}
                  forcedCharterGuid={forcedCharterGuid}
                />
            </div>
          </Card>
        }
      </ThemeProvider>
      <ServicesAll />
      <Group49 />

      {false &&
        <BoatCardList
          charterGuid={forcedCharterGuid} />
      }
    </div>
  )
}

export default Home
