import { useSelector, useDispatch } from 'react-redux';
import { DatabaseType, HajoType, KiadoType, FoglalasType, KikotoType } from '../types/DatabaseTypes'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { useIntl , FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CssBaseline, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import WifiIcon from '@mui/icons-material/Wifi';
import PercentIcon from '@mui/icons-material/Percent';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ReactCountryFlag from 'react-country-flag';
//import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
//import yellowtail from 'typeface-yellowtail';
import {displayReservationPeriod} from '../tools/Tools';

//import '../styles/figma.css';



const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3', // Change primary color
    },
    secondary: {
      main: '#FF5722', // Change secondary color
    },

  },

  typography: {
    // fontFamily: 'Sans-serif', // Change default font
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 800
    },
    h2: {
      fontSize: 12,
      fontWeight: 600
    },
    h3: {
      fontSize: 20,
      fontWeight: 800,
      color: '#6AB2BC',
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,
      color: '#6AB2BC',
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 500,
      backgroundColor: '#FFD600',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
      color: 'white',
      backgroundColor: 'black',
    },
    body1: {
      fontSize: 12,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 700
    },
  },
});


const isMobile = window.innerWidth <= 768;

function ProfileReservationCardSummary(props: DatabaseType) {
  const intl = useIntl();
  //console.log('ProfileReservationCardSummary() called');
  //console.log(props);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
 

  //console.log('ProfileReservationCardSummary item:', props);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div>
          <Card style={{ border: "none", boxShadow: "none" }} sx={{ height: 148, width: 800, p: 0, display: 'flex', justifyContent: 'left', border: '0' }}>
            <CardMedia sx={{ height: 148, width: 124, display: 'flex' }}
              component="img"
              image={props?.hajo?.pic_guid}
              alt="boat picture"
            />

            <Box sx={{ height: 148, width: 600, display: 'flex', flexDirection: 'column', border: '0' }}>
              <CardContent sx={{ flex: '1 0 auto', border:0 }}>
                <Typography variant="h1" sx={{ pb: 2 }}>
                  {props.hajo?.model} | {props.hajo?.nev}
                </Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  {displayReservationPeriod(intl, props?.foglalas.arazas_tipus, props?.foglalas?.tol, props?.foglalas?.ig)}
                </Typography>
                <Typography variant="body2">
                  <ReactCountryFlag countryCode={props?.kikoto?.country} svg style={{ width: '1em', height: '1em' }} /> {props?.kikoto?.nev}
                </Typography>
              </CardContent>
            </Box>

          </Card>
      </div>
    </ThemeProvider>
  );
}

export default ProfileReservationCardSummary;
