import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Axios from "axios";

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { isValidName, isValidPhone } from '../tools/Tools'
import FormTypeBerloAdatok from '../types/FormTypeBerloAdatok';
import RootState from '../types/RootState';
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: () => void;
}

export function DialogUpdateAccount(props: SimpleDialogProps) {

  const dispatch = useDispatch();
  const intl = useIntl();
  const form: FormTypeBerloAdatok = useSelector((state: RootState) => state.formBerloAdatok);
  const [validation, setValidation] = useState<any>({});
  //const [validation, setValidation] = useState<FormTypeBerloAdatok>({});
  //const [errors, setErrors] = useState<FormErrorType>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);


  const loggedIn = useSelector((state: RootState) => state.currentUser.loggedIn);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'nev') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, nev: value } });
    }
    else if (name === 'telefonszam') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, telefonszam: value } });
    }
    else if (name === 'cim_irsz') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, cim_irsz: value } });
    }
    else if (name === 'cim_varos') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, cim_varos: value } });
    }
    else if (name === 'cim_utca') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, cim_utca: value } });
    }
    else if (name === 'cim_hsz') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, cim_hsz: value } });
    }
    else if (name === 'szamlazasi_irsz') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, szamlazasi_irsz: value } });
    }
    else if (name === 'szamlazasi_varos') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, szamlazasi_varos: value } });
    }
    else if (name === 'szamlazasi_utca') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, szamlazasi_utca: value } });
    }
    else if (name === 'szamlazasi_hsz') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, szamlazasi_hsz: value } });
    }
    else if (name === 'adoszam') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, adoszam: value } });
    }
    else if (name === 'szemely_ig') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, szemely_ig: value } });
    }
    else if (name === 'vezetoi_engedely') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vezetoi_engedely: value } });
    }
    else if (name === 'tipus') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, tipus: value } });
    }
    else if (name === 'cegnev') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, cegnev: value } });
    }
    else if (name === 'csoportos_adoszam') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, csoportos_adoszam: value } });
    }
    else if (name === 'kozossegi_adoszam') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, kozossegi_adoszam: value } });
    }
    else if (name === 'bank') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, bank: value } });
    }
    else if (name === 'iban_bankszamla') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, iban_bankszamla: value } });
    }
    else if (name === 'szamlazasi_levelezesi_egyezik') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, szamlazasi_levelezesi_egyezik: value } });
    }
    else if (name === 'vez_nev') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_nev: value } });
    }
    else if (name === 'vez_email') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_email: value } });
    }
    else if (name === 'vez_telefon') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_telefon: value } });
    }
    else if (name === 'vez_szemely_ig') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_szemely_ig: value } });
    }
    else if (name === 'vez_vezeto_engedely') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_vezeto_engedely: value } });
    }
    else if (name === 'vez_irsz') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_irsz: value } });
    }
    else if (name === 'vez_varos') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_varos: value } });
    }
    else if (name === 'vez_utca') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_utca: value } });
    }
    else if (name === 'vez_hsz') {
      dispatch({ type: 'FormValuesBerloAdatok', form: { ...form, vez_hsz: value } });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  }

  const checkValidation = () => {
    setSubmitVisible(true);
    let errors: FormTypeBerloAdatok = JSON.parse(JSON.stringify(validation));
    if (!form?.nev?.trim()) {
      errors.nev = intl.formatMessage({ id: 'input.validation.name_required' });
      setSubmitVisible(false);
    }
    else {
      errors.nev = ' ';
    }
    if (!form?.telefonszam?.trim()) {
      errors.telefonszam = intl.formatMessage({ id: 'input.validation.phone_number_required' })
      setSubmitVisible(false);
    }
    else if (!isValidPhone(form.telefonszam)) {
      errors.telefonszam = intl.formatMessage({ id: 'input.validation.phone_number_format_invalid' })
      setSubmitVisible(false);
    }
    else {
      errors.telefonszam = ' ';
    }
    setValidation(errors);
    //setErrors(errors);
  }

  useEffect(() => {
    checkValidation();
  }, [form]);

  useEffect(() => {
    checkValidation();
  }, [props.open]);


  const handleSave = () => {
    updateAccount();
  }

  const dispatchLogin = (data: string) => {
    dispatch({ type: 'LoginWithEmail', currentUser: data });
  };

  const resetDisplayFlags = () => {
    setFormSubmitted(false);
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  }

  const handleClose = () => {
    resetDisplayFlags();
    props.onClose();
  };

  async function updateAccount() {
    setSubmitVisible(false);
    try {
      const response = await Axios.post(window.backendUrl + 'update_account', form);
      if (response.status === 200) {
        console.log("Account update success");
        setFormSubmitted(true);
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
      }
      else {
        console.log('Account update unsuccessful');
        setFormSubmitted(true);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
      }
    }
    catch (error) {
      console.log(error);
      console.log('Account update unsuccessful');
      setFormSubmitted(true);
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
    };
  };


  return (

    <Dialog
      onClose={handleClose}
      open={props.open}
      fullWidth={true}
      maxWidth='xl'
    >
      <DialogTitle><FormattedMessage id='dialog.title.update_account' />
        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Divider sx={{ mt: 3 }} style={{ backgroundColor: "azure", width: '100%' }} ><FormattedMessage id='input.label.personal_contact_data' /> </Divider>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.nev}
              required
              label={intl.formatMessage({ id: 'input.label.nev' })}
              fullWidth
              name='nev'
              helperText={validation.nev}
              error={validation.nev?.trim() !== ''}
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.tipus}
              label={intl.formatMessage({ id: 'input.label.tipus' })}
              fullWidth
              name='tipus'
              onChange={handleTextFieldChange} />
          </Grid>

          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.telefonszam}
              required
              label={intl.formatMessage({ id: 'input.label.telefonszam' })}
              fullWidth
              name='telefonszam'
              helperText={validation.telefonszam}
              error={validation.telefonszam?.trim() !== ''}
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              InputProps={{
                readOnly: true,
              }}
              value={form?.email}
              label={intl.formatMessage({ id: 'input.label.email' })}
              fullWidth />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'

              value={form?.szemely_ig}
              label={intl.formatMessage({ id: 'input.label.szemely_ig' })}
              fullWidth
              name='szemely_ig'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'

              value={form?.vezetoi_engedely}
              label={intl.formatMessage({ id: 'input.label.vezetoi_engedely' })}
              fullWidth
              name='vezetoi_engedely'
              onChange={handleTextFieldChange} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Divider sx={{ mt: 3 }} style={{ backgroundColor: "azure", width: '100%' }} ><FormattedMessage id='input.label.company_data' /> </Divider>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.cegnev}
              label={intl.formatMessage({ id: 'input.label.cegnev' })}
              fullWidth
              name='cegnev'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.adoszam}
              label={intl.formatMessage({ id: 'input.label.adoszam' })}
              fullWidth
              name='adoszam'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.csoportos_adoszam}
              label={intl.formatMessage({ id: 'input.label.csoportos_adoszam' })}
              fullWidth
              name='csoportos_adoszam'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.kozossegi_adoszam}
              label={intl.formatMessage({ id: 'input.label.kozossegi_adoszam' })}
              fullWidth
              name='kozossegi_adoszam'
              onChange={handleTextFieldChange} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Divider sx={{ mt: 3 }} style={{ backgroundColor: "azure", width: '100%' }} ><FormattedMessage id='input.label.bank_data' /> </Divider>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.bank}
              label={intl.formatMessage({ id: 'input.label.bank' })}
              fullWidth
              name='bank'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.iban_bankszamla}
              label={intl.formatMessage({ id: 'input.label.iban_bankszamla' })}
              fullWidth
              name='iban_bankszamla'
              onChange={handleTextFieldChange} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Divider sx={{ mt: 3 }} style={{ backgroundColor: "azure", width: '100%' }} ><FormattedMessage id='input.label.postal_data' /> </Divider>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.cim_irsz}
              label={intl.formatMessage({ id: 'input.label.cim_irsz' })}
              fullWidth
              name='cim_irsz'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.cim_varos}
              label={intl.formatMessage({ id: 'input.label.cim_varos' })}
              fullWidth
              name='cim_varos'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.cim_utca}
              label={intl.formatMessage({ id: 'input.label.cim_utca' })}
              fullWidth
              name='cim_utca'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.cim_hsz}
              label={intl.formatMessage({ id: 'input.label.cim_hsz' })}
              fullWidth
              name='cim_hsz'
              onChange={handleTextFieldChange} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Divider sx={{ mt: 3 }} style={{ backgroundColor: "azure", width: '100%' }} ><FormattedMessage id='input.label.invoicing_data' /> </Divider>
          <Grid item>
            <FormControlLabel
              value={form?.szamlazasi_levelezesi_egyezik}
              control={<Checkbox
                onChange={handleTextFieldChange}
              />}
              label={intl.formatMessage({ id: 'input.label.szamlazasi_levelezesi_egyezik' })}
              labelPlacement="end"
            />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.szamlazasi_irsz}
              label={intl.formatMessage({ id: 'input.label.szamlazasi_irsz' })}
              fullWidth
              name='szamlazasi_irsz'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.szamlazasi_varos}
              label={intl.formatMessage({ id: 'input.label.szamlazasi_varos' })}
              fullWidth
              name='szamlazasi_varos'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.szamlazasi_utca}
              label={intl.formatMessage({ id: 'input.label.szamlazasi_utca' })}
              fullWidth
              name='szamlazasi_utca'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.szamlazasi_hsz}
              label={intl.formatMessage({ id: 'input.label.szamlazasi_hsz' })}
              fullWidth
              name='szamlazasi_hsz'
              onChange={handleTextFieldChange} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Divider sx={{ mt: 3 }} style={{ backgroundColor: "azure", width: '100%' }} ><FormattedMessage id='input.label.skipper_data' /> </Divider>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_nev}
              label={intl.formatMessage({ id: 'input.label.vez_nev' })}
              fullWidth
              name='vez_nev'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_email}
              label={intl.formatMessage({ id: 'input.label.vez_email' })}
              fullWidth
              name='vez_email'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_telefon}
              label={intl.formatMessage({ id: 'input.label.vez_telefon' })}
              fullWidth
              name='vez_telefon'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_szemely_ig}
              label={intl.formatMessage({ id: 'input.label.vez_szemely_ig' })}
              fullWidth
              name='vez_szemely_ig'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_vezeto_engedely}
              label={intl.formatMessage({ id: 'input.label.vez_vezeto_engedely' })}
              fullWidth
              name='vez_vezeto_engedely'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_irsz}
              label={intl.formatMessage({ id: 'input.label.vez_irsz' })}
              fullWidth
              name='vez_irsz'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_varos}
              label={intl.formatMessage({ id: 'input.label.vez_varos' })}
              fullWidth
              name='vez_varos'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_utca}
              label={intl.formatMessage({ id: 'input.label.vez_utca' })}
              fullWidth
              name='vez_utca'
              onChange={handleTextFieldChange} />
          </Grid>
          <Grid item>
            <TextField
              size='small' variant='filled'
              value={form?.vez_hsz}
              label={intl.formatMessage({ id: 'input.label.vez_hsz' })}
              fullWidth
              name='vez_hsz'
              onChange={handleTextFieldChange} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Divider sx={{ mt: 3 }} style={{ backgroundColor: "azure", width: '100%' }} ></Divider>

          {!formSubmitted &&
            <Grid item>
              <Button
                onClick={handleSave}
                variant='contained'
                fullWidth
                sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
                disabled={!submitVisible}
              >
                <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
                  <FormattedMessage id='submit.save' />
                </ListItemText>
              </Button>
            </Grid>
          }
          {formSubmitted && showSuccessAlert &&
            <Grid item>
              <Alert variant='standard' severity='success' style={{ width: '100%' }}>
                <FormattedMessage id='alert.save_successful' />
              </Alert>
            </Grid>
          }

          {formSubmitted && showErrorAlert &&
            <Grid item>
              <Alert variant='standard' severity='error' style={{ width: '100%' }}>
                <FormattedMessage id='alert.save_unsuccessful' />
              </Alert>
            </Grid>
          }


        </Grid>
      </DialogContent>

      <DialogActions>
        <DeveloperModeIcon onClick={handleSave} />

        <Button autoFocus onClick={handleClose}>
          <FormattedMessage id='button.close' />
        </Button>
      </DialogActions>

    </Dialog>
  );
}


