
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from "axios";


import TextField from '@mui/material/TextField';


import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


import { FormattedMessage } from 'react-intl'
import BoatOverviewCard from "./BoatOverviewCard";




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function Confirmation() {
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleContinue = (id) => {
    console.log('Continue pressed');
    navigate('/home');
  };



  const handleEmail = useCallback(async () => {
    console.log('Email pressed');
    try {
      const response = await Axios.post(window.backendUrl + 'send_reservation_confirmation', null);
      if (response.status === 200) {
        console.log('Axios post OK');
      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
    }
    catch (error) {
      console.log(error);
      setError(error.message);

    };
    navigate('/confirmation');
  }, []);




  return (
    <div>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', border: 1, justifyContent: 'flex-start' }}>
        <Typography variant="h5" color="text.secondary" sx={{ m: 1 }}>
          <br></br>
          Köszönjük, hogy minket választott.<br></br>
          A foglalásáról egy megerősítő emailt küldtünk az Ön címére.<br></br>

        </Typography>
      </Box>

      <Button variant="contained" onClick={() => { handleContinue() }}>
        OK
      </Button>
      <Button variant="contained" onClick={() => { handleEmail() }}>
        Email
      </Button>

    </div>
  )
}

export default Confirmation
