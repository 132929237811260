import React, { useState, useEffect } from "react";
import { useSelector, useDispatch} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl'
import Axios from "axios";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import RootState from '../types/RootState';
import {isValidEmailAddress} from '../tools/Tools';

interface FormValues {
  email?: string; //kérdőjel nélkül nem lehetne az useState-ben üres {}-t használni
}

export interface DialogSendResetPasswordLinkProps {
  open: boolean;
  selectedValue: string;
  onClose: () => void;
  onMixedLoginClicked: () => void;
}

export function DialogSendResetPasswordLink(props: DialogSendResetPasswordLinkProps) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const form = useSelector((state: RootState ) => state.form);
  const [validation,       setValidation]       = useState<FormValues>({});  
  const [formSubmitted,    setFormSubmitted]    = useState(false);   
  const [showErrorAlert,   setShowErrorAlert]   = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);  
  const [submitVisible,    setSubmitVisible]    = useState(false);

  const handleTextFieldChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    const { name, value } = event.target;
    //console.log('handleTextFieldChange');
    if(name === 'email'){
      const thisForm = {...form, email: value};    
      dispatch({type: 'FormValues', form: thisForm});          
    }
    else{
      console.log('ERROR, unknow field name: '+name);
    }
  }  

  const checkValidation = () => {
    //console.log('check validation of form:');
    //console.log(form);
    let errors = JSON.parse(JSON.stringify(validation));
    if(!form?.email?.trim()) {
      errors.email = intl.formatMessage({id: 'input.validation.email_address_required'})
      setSubmitVisible(false);      
    } 
    else if(!isValidEmailAddress(form?.email?.trim() )){
      errors.email = intl.formatMessage({id: 'input.validation.email_address_format_invalid'})
      setSubmitVisible(false);      
    }
    else{
      errors.email = '';
      setSubmitVisible(true);      
    }
    setValidation(errors);
  }
  
  useEffect(() => {
    //console.log('DialogSendResetPasswordLink.useEffect() called by FORM change');
    checkValidation();
  },[form]) ;

  useEffect(() => {
    //console.log('DialogSendResetPasswordLink.useEffect() called by props.open change');
    //console.log('form is:')
    //console.log(form);    
    //console.log('state.currentUser is:')
    checkValidation();
  },[props.open] ) ;


  const handleSendLink = () => {
    sendLink();    
  }

  const handleClose = () => {
    setFormSubmitted(false);    
    setShowSuccessAlert(false);        
    setShowErrorAlert(false);        
    props.onClose();
  };

  
  async function sendLink(){    
    setSubmitVisible(false);
    try{
      //console.log('formValues:');
      //console.log(formValues);      
      const response=await Axios.post(window.backendUrl+'send_reset_password_link', form);
      if(response.status === 200){
        //console.log('Reset password link sent successfully')
        setFormSubmitted(true);
        setShowSuccessAlert(true);        
        setShowErrorAlert(false);        
      }
      else{
        console.log('Reset password link sending error, status != 200');
        console.log('response.status='+response.status);
        setFormSubmitted(true);
        setShowSuccessAlert(false);        
        setShowErrorAlert(true);        
      }
    }
    catch(error){
      console.log('Reset password link sending error catch()');
      console.log(error)
      setFormSubmitted(true);
      setShowSuccessAlert(false);        
      setShowErrorAlert(true);        
    };
  };
  
  
  return (
    <Dialog 
    onClose={handleClose} 
    open={props.open} 
    fullWidth
    >
    <DialogTitle><FormattedMessage id='dialog.title.send_password_reset_link'/>
      <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8,  color: (theme) => theme.palette.grey[500], }} >
       <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent dividers>

      <List sx={{ pt: 0, m: 2 }}>
      <ListItem>
      <TextField
        value={form.email}
        required 
        label={intl.formatMessage({id: 'input.label.email'})}
        fullWidth 
        name='email' 
        helperText={validation.email}
        onChange={handleTextFieldChange}/>        
      </ListItem>

      {!formSubmitted && 
      <ListItem>
      <Button 
         onClick={handleSendLink}      
         variant='contained' 
         fullWidth 
         sx={{textAlign: 'left', textTransform: 'none', border: 0 }} 
         disabled={!submitVisible}
      >
      <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
        <FormattedMessage id='submit.send_password_reset_link'/>
      </ListItemText>
      </Button>
      </ListItem>
      }

      {formSubmitted && showSuccessAlert && 
      <ListItem>
      <Alert variant='standard' severity='success' style={{width:'100%'}}>
      <FormattedMessage id='alert.password_reset_link_sent_successful'/>
      </Alert>
      </ListItem>
      }

      {formSubmitted && showErrorAlert && 
      <ListItem>
        <Alert variant='standard' severity='error' style={{width:'100%'}}>
          <FormattedMessage id='alert.password_reset_link_sent_unsuccessful'/>
        </Alert>
      </ListItem>
      }

      {!formSubmitted && 
      <ListItem onClick={props.onMixedLoginClicked}>
        <Button variant="outlined" fullWidth sx={{textAlign: 'left', textTransform: 'none', border: 1 }} >
          <ListItemText sx={{ textAlign: 'center', color: 'text.primary' }}>
            <FormattedMessage id='button.further_login_options'/> 
          </ListItemText>
        </Button>
      </ListItem>
      }    
      </List>

      </DialogContent>

      <DialogActions>
        <DeveloperModeIcon onClick={handleSendLink}/>
        <Button autoFocus onClick={handleClose}>
          <FormattedMessage id='button.close'/> 
        </Button>
      </DialogActions>    

    </Dialog>
  );

}


