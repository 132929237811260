import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import RootState from '../types/RootState';
import { useState, useEffect } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
const theme = createTheme({
  typography: {
    fontFamily: "Mulish, Sans-Serif",
    h1: {
      fontSize: 24,
      fontWeight: 500
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 16,
      fontWeight: 800
    },
  }
});
const isMobile = window.innerWidth <= 768;

function BoatDetailsFelszereltseg() {
  const selectedHajo = useSelector((state: RootState) => state.selectedHajo);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var cabin: number = 0;
  if (selectedHajo) {
    cabin = Number(selectedHajo.kabin_1_db ?? 0) + Number(selectedHajo.kabin_2_db ?? 0) + Number(selectedHajo.emeletes_kabin_db ?? 0);
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [accordeonExpanded, setAccordeonExpanded] = React.useState('');
  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setAccordeonExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      {selectedHajo &&
        <ThemeProvider theme={theme}>
          <Paper elevation={5} sx={{ p: 1, m: 1 }}>
            <Typography
              variant="h1"
              paragraph={false}
              sx={{ p: 1 }}>
              <FormattedMessage id='boat.Equipment' />
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}>
                <Accordion
                  expanded={accordeonExpanded === 'accordion.navigation' || !isMobile}
                  onChange={handleChange('accordion.navigation')}
                  id={'accordion.navigation'}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ color: 'text.secondary' }}
                  >
                    <Typography variant="body2">
                      <FormattedMessage id='boat.Equipment.Navigationandsafety' />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!!selectedHajo.robotpilota &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Autopilot' />
                      </Typography>
                    }
                    {!!selectedHajo.generator &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Generator' />
                      </Typography>
                    }
                    {!!selectedHajo.elektromos_csorlo &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Electricwinch' />
                      </Typography>
                    }
                    {!!selectedHajo.radar &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Radar' />
                      </Typography>
                    }
                    {!!selectedHajo.inverter &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Inverter' />
                      </Typography>
                    }
                    {!!selectedHajo.bimini &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Bimini' />
                      </Typography>
                    }
                    {!!selectedHajo.hullamtores &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Wave' />
                      </Typography>
                    }
                    {!!selectedHajo.napelemek &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Solarpanel' />
                      </Typography>
                    }
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  justifyContent: 'flex-start'
                }}>
                <Accordion
                  expanded={accordeonExpanded === 'accordion.equipment' || !isMobile}
                  onChange={handleChange('accordion.equipment')}
                  id={'accordion.equipment'}
                  elevation={0}

                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ color: 'text.secondary' }}
                  >

                    <Typography variant="body2">
                      <FormattedMessage id='boat.Equipment.Interieur' />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    {!!selectedHajo.klima &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Ac' />
                      </Typography>
                    }
                    {!!selectedHajo.tuzhely &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Stove' />
                      </Typography>
                    }
                    {!!selectedHajo.mosogato &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Dishwasher' />
                      </Typography>
                    }
                    {!!selectedHajo.mosogep &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Washingmachine' />
                      </Typography>
                    }
                    {!!selectedHajo.huto &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Fridge' />
                      </Typography>
                    }
                    {!!selectedHajo.melyhuto &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Freezer' />
                      </Typography>
                    }
                    {!!selectedHajo.bbq &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Bbq' />
                      </Typography>
                    }
                    {!!selectedHajo.futes &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Heating' />
                      </Typography>
                    }
                    {!!selectedHajo.konyhai_eszkozok &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Kitchenware' />
                      </Typography>
                    }
                    {!!selectedHajo.mikro &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Microwave' />
                      </Typography>
                    }
                    {!!selectedHajo.suto &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Bakingoven' />
                      </Typography>
                    }
                    {!!selectedHajo.agynemu &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Bedding' />
                      </Typography>
                    }
                    {!!selectedHajo.zuhany &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Shower' />
                      </Typography>
                    }
                    {!!selectedHajo.torolkozo &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Towel' />
                      </Typography>
                    }
                    {!!selectedHajo.kabegep &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Coffeemaker' />
                      </Typography>
                    }
                  </AccordionDetails>

                </Accordion>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}>
                <Accordion
                  expanded={accordeonExpanded === 'accordion.entertrainment' || !isMobile}
                  onChange={handleChange('accordion.entertrainment')}
                  id={'accordion.entertrainment'}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ color: 'text.secondary' }}
                  >
                    <Typography variant="body2">
                      <FormattedMessage id='boat.Equipment.Entertrainment' />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    {!!selectedHajo.tv &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Tv' />
                      </Typography>
                    }
                    {!!selectedHajo.hangszoro &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Loudspeaker' />
                      </Typography>
                    }
                    {!!selectedHajo.radio &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Radio' />
                      </Typography>
                    }
                    {!!selectedHajo.dvd &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Dvd' />
                      </Typography>
                    }
                    {!!selectedHajo.vizisi &&
                      <Typography variant="body1">
                        <FormattedMessage id='boat.Waterski' />
                      </Typography>
                    }
                  </AccordionDetails>

                </Accordion>
              </Box>
            </Box>
          </Paper>
        </ThemeProvider>
      }
    </div >
  );
}

export default BoatDetailsFelszereltseg;
