import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Axios from "axios";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { isValidName, isValidEmailAddress, isValidPhone } from '../tools/Tools'
import RootState from '../types/RootState';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Link, Switch, ThemeProvider, Typography } from "@mui/material";
import { yellowButton } from "../themes/fdTheme";
import { DocumentTemplateType, ServiceType, emptyDocumentTemplate, emptyService } from "../types/DatabaseTypes";
import FakeDoorPrivacyPolicyDialog from './FakeDoorPrivacyPolicyDialog';

// Figyelem! Ez az attributum: [index: string]: any, 
// azért kell, hogy form[x] formában is lehessen az attributumokra hivatkozni
interface FormType {
  [index: string]: any,
  lastname: string,
  firstname: string,
  company: string,
  phone: string,
  email: string,
  remark: string,
  option_hotel: boolean,
  option_rental: boolean,
  option_owner: boolean,
  option_workshop: boolean,
  option_harbor: boolean,
  option_organizer: boolean,
  option_other: boolean,
  option_dp_accepted: boolean,
}

const emptyForm: FormType = {
  lastname: "",
  firstname: "",
  company: "",
  phone: "",
  email: "",
  remark: "",
  option_hotel: false,
  option_rental: false,
  option_owner: false,
  option_workshop: false,
  option_harbor: false,
  option_organizer: false,
  option_other: false,
  option_dp_accepted: false,
}

export default function FakeDoorInputDialog(props: any) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [form, setForm] = useState(emptyForm);
  const [validation, setValidation] = useState(emptyForm);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [interested, setInterested] = useState(false);
  const serviceList = useSelector((state: RootState) => state.fakeDoorServiceList);
  const language = useSelector((state: RootState) => state.language);
  const [openPrivacyPolicyDialog, setOpenPrivacyPolicyDialog] = useState(false);
  const documentTemplateList = useSelector((state: RootState) => state.documentTemplateList);
  const [inputMade, setInputMade] = useState(false);


  const handlePrivaryPolicy = () => {
    setOpenPrivacyPolicyDialog(true);
  };


  function getTitle(lng: string, docType: string) {
    var item: DocumentTemplateType = emptyDocumentTemplate;
    if (documentTemplateList?.length > 0) {
      item = documentTemplateList.find(x => x.lng === lng && x.doc_type === docType) ?? emptyDocumentTemplate;
    }
    return item?.title ?? lng;
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMade(true);
    const { name, value } = event.target;
    if (name === 'lastname') {
      setForm({ ...form, lastname: value });
    }
    else if (name === 'firstname') {
      setForm({ ...form, firstname: value });
    }
    else if (name === 'company') {
      setForm({ ...form, company: value });
    }
    else if (name === 'phone') {
      setForm({ ...form, phone: value });
    }
    else if (name === 'email') {
      setForm({ ...form, email: value });
    }
    else if (name === 'remark') {
      setForm({ ...form, remark: value });
    }
    else {
      console.log('ERROR, unknown field name: ' + name);
    }
  }

  const checkValidation = () => {
    setSubmitVisible(true);
    if (inputMade) {
      let errors: FormType = JSON.parse(JSON.stringify(validation));
      if (!form?.lastname?.trim()) {
        errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
        setSubmitVisible(false);
      }
      else if (!isValidName(form.lastname)) {
        errors.lastname = intl.formatMessage({ id: 'input.validation.lastname_required' });
        setSubmitVisible(false);
      }
      else {
        errors.lastname = '';
      }

      if (!form?.firstname?.trim()) {
        errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' })
        setSubmitVisible(false);
      }
      else if (!isValidName(form.firstname)) {
        errors.firstname = intl.formatMessage({ id: 'input.validation.firstname_required' });
        setSubmitVisible(false);
      }
      else {
        errors.firstname = '';
      }
      if (!form?.phone?.trim()) {
        errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_required' })
        setSubmitVisible(false);
      }
      else if (!isValidPhone(form.phone)) {
        errors.phone = intl.formatMessage({ id: 'input.validation.phone_number_format_invalid' })
        setSubmitVisible(false);
      }
      else {
        errors.phone = '';
      }

      if (!form?.email?.trim()) {
        errors.email = intl.formatMessage({ id: 'input.validation.email_address_required' })
        setSubmitVisible(false);
      }
      else if (!isValidEmailAddress(form.email)) {
        errors.email = intl.formatMessage({ id: 'input.validation.email_address_format_invalid' })
        setSubmitVisible(false);
      }
      else {
        errors.email = '';
      }

      setValidation(errors);
    }
    else {
      setSubmitVisible(false);
    }
  }

  useEffect(() => {
    checkValidation();
  }, [intl, form]);

  const handleSave = () => {
    resetDisplayFlags();
    createAccount();
    setFormSubmitted(true);
  }

  const resetDisplayFlags = () => {
    setFormSubmitted(false);
    setAccountCreated(false);
    setAccountExists(false);
    setShowErrorAlert(false);
  }

  async function createAccount() {
    setSubmitVisible(false);
    /*
    const req = {
      boatId: form.boatId
    }    
    */
    try {
      const response = await Axios.post(window.backendUrl + 'save_fake_door_reg_form', form);
      if (response.status === 200) {
        console.log('Registration successful');
        setFormSubmitted(true);
        setShowErrorAlert(false);
      }
      else {
        console.error('Registration unsuccessful');
        setFormSubmitted(true);
        setShowErrorAlert(true);
      }
    }
    catch (error) {
      console.log(error);
      console.error('Registration unsuccessful');
      setFormSubmitted(true);
      setShowErrorAlert(true);
    };
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log('handleCheckboxChange ', event.target);
    checkValidation();
    var { name, value } = event.target;
    //console.log(name, value);
    //var bvalue: boolean = !Boolean(value);
    if (name === 'option_hotel') {
      setForm({ ...form, option_hotel: !form.option_hotel });
    }
    else if (name === 'option_rental') {
      setForm({ ...form, option_rental: !form.option_rental });
    }
    else if (name === 'option_owner') {
      setForm({ ...form, option_owner: !form.option_owner });
    }
    else if (name === 'option_workshop') {
      setForm({ ...form, option_workshop: !form.option_workshop });
    }
    else if (name === 'option_harbor') {
      setForm({ ...form, option_harbor: !form.option_harbor });
    }
    else if (name === 'option_organizer') {
      setForm({ ...form, option_organizer: !form.option_organizer });
    }
    else if (name === 'option_other') {
      setForm({ ...form, option_other: !form.option_other });
    }
    else if (name === 'option_dp_accepted') {
      setForm({ ...form, option_dp_accepted: !form.option_dp_accepted });
    }
    else {
      console.error('Unknown option: ', name);
    }

  }

  const handleInterested = () => {
    setInterested(true);
  };

  const handleDialogClose = () => {
    props.setOpenDialog(false); // Use the prop.
  };

  function getButtonTitle(lng: string, buttonId: string) {
    var item: ServiceType = emptyService;
    if (serviceList?.length > 0) {
      item = serviceList.find(x => x.lng === lng && x.fake_door_id === buttonId) ?? emptyService;
    }
    return item?.title
  }

  const SelectableItem = ({ buttonId }: { buttonId: string }) => {
    var x = 'option_' + buttonId;
    return (
      <Typography gutterBottom={false} variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, pr: 2 }}>
        {getButtonTitle(language, buttonId)}
        <Switch size='small'
          checked={form[x]}
          name={`option_${buttonId}`}
          onChange={handleCheckboxChange} />
      </Typography>
    )
  }

  return (
    <>
      <Dialog
        fullWidth
        open={props.openDialog}
        onClose={handleDialogClose}
      >

        <FakeDoorPrivacyPolicyDialog
          openDialog={openPrivacyPolicyDialog}
          setOpenDialog={setOpenPrivacyPolicyDialog}
        />
        <DialogTitle>
          <Typography variant="subtitle1">
            <FormattedMessage id='fakedoor.input.title' />
          </Typography>
          <IconButton
            onClick={handleDialogClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>

          <Typography variant="body2"
            sx={{ display: 'flex', justifyContent: 'flex-start', pl: 2, pt: 0, pb: 1 }}>
            <FormattedMessage id='fakedoor.input.header' />
          </Typography>
          <Box>
            {serviceList?.length > 0 &&
              serviceList?.filter((item: ServiceType) => item.lng === language)?.map((x: ServiceType) =>
                <SelectableItem buttonId={x.fake_door_id} />
              )
            }
            <Typography gutterBottom={false} variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, pr: 2 }}>
              <FormattedMessage id='fakedoor.input.option_other' />
              <Switch size='small'
                checked={form.option_other}
                name={'option_other'}
                onChange={handleCheckboxChange} />
            </Typography>
          </Box>
          <br />
          <TextField
            sx={{ pb: 3 }}
            size='small'
            value={form?.lastname}
            required
            label={intl.formatMessage({ id: 'fakedoor.input.lastname' })}
            fullWidth
            name='lastname'
            helperText={validation.lastname}
            onChange={handleTextFieldChange} />
          <TextField
            sx={{ pb: 3 }}
            size='small'
            value={form?.firstname}
            required
            label={intl.formatMessage({ id: 'fakedoor.input.firstname' })}
            fullWidth
            name='firstname'
            helperText={validation.firstname}
            onChange={handleTextFieldChange} />
          <TextField
            sx={{ pb: 3 }}
            size='small'
            value={form?.company}
            label={intl.formatMessage({ id: 'fakedoor.input.company' })}
            fullWidth
            name='company'
            helperText={validation.company}
            onChange={handleTextFieldChange} />
          <TextField
            sx={{ pb: 3 }}
            size='small'
            value={form?.phone}
            required
            label={intl.formatMessage({ id: 'fakedoor.input.phone' })}
            fullWidth
            name='phone'
            helperText={validation.phone}
            onChange={handleTextFieldChange} />
          <TextField
            sx={{ pb: 3 }}
            size='small'
            value={form?.email}
            required
            label={intl.formatMessage({ id: 'fakedoor.input.email' })}
            fullWidth
            name='email'
            helperText={validation.email}
            onChange={handleTextFieldChange} />
          <TextField
            sx={{ pb: 1 }}
            multiline
            minRows={3}
            maxRows={10}
            value={form?.remark}
            label={intl.formatMessage({ id: 'fakedoor.input.remark' })}
            fullWidth
            name='remark'
            helperText={validation.remark}
            onChange={handleTextFieldChange} />

          {(formSubmitted && showErrorAlert) &&
            <Alert variant='standard' severity='error' style={{ width: '100%' }}>
              <FormattedMessage id='fakedoor.registration.failure' />
            </Alert>}
          {(formSubmitted && !showErrorAlert) &&
            <Alert variant='standard' severity='success' style={{ width: '100%' }}>
              <FormattedMessage id='fakedoor.registration.successful' />
            </Alert>}
          {!formSubmitted &&
            <>
              <Typography gutterBottom={false} variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', p: 0 }} paragraph={false}>
                <div>
                  <Switch size='small'
                    checked={form.option_dp_accepted}
                    name={'option_dp_accepted'}
                    onChange={handleCheckboxChange}
                  />
                  <FormattedMessage id='fakedoor.input.option_dp_accepted' />
                </div>
                <Link variant="body1" underline="hover" onClick={handlePrivaryPolicy} sx={{ cursor: 'pointer' }}>
                  {getTitle(language, 'privacy_policy')}
                </Link>
              </Typography>
              <ThemeProvider theme={yellowButton}>
                <Button
                  onClick={handleSave}
                  variant='contained'
                  sx={{ textAlign: 'left', textTransform: 'none', border: 0 }}
                  disabled={!submitVisible || !form.option_dp_accepted}
                >
                  <FormattedMessage id='fakedoor.button.submit' />
                </Button>
              </ThemeProvider>
            </>
          }
        </DialogContent>
        {false &&
          <DialogActions>
            <Button autoFocus onClick={handleDialogClose}>
              <FormattedMessage id='button.close' />
            </Button>
          </DialogActions>
        }
      </Dialog>
    </>
  );
}

