import React, { useState, useEffect, useCallback, isValidElement, Dispatch } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from "axios";
import { CredentialResponse, TokenResponse } from "@react-oauth/google";
import jwt_decode from 'jwt-decode';
import GoogleUserType from "../types/GoogleUserType";
import { Action } from "redux";

async function byAccessToken(accessToken: string ) {
  var response: any;  
  const userObject = await Axios.get(
    'https://www.googleapis.com/oauth2/v3/userinfo',
    { headers: { Authorization: `Bearer ${accessToken}` } },
  );

  const googleUser: GoogleUserType = {
    name: userObject.data.name,
    email: userObject.data.email,
    email_verified: userObject.data.email_verified,
    locale: userObject.data.locale,
    family_name: userObject.data.family_name,
    given_name: userObject.data.given_name,
    picture: userObject.data.picture,
    sub: userObject.data.sub
  }

  console.log("Name: " + googleUser.name);
  console.log("Email: " + googleUser.email);
  console.log("Email verified: " + googleUser.email_verified);
  console.log("Locale: " + googleUser.locale);
  console.log("Family name: " + googleUser.family_name);
  console.log("Given name: " + googleUser.given_name);
  console.log("Picture: " + googleUser.picture);
  console.log("Sub: " + googleUser.sub);
  //    console.log(googleUser);
  //      console.log(tokenResponse);
  console.log("Here comes axios");
  try{
    response=await Axios.post(window.backendUrl+'login_with_google', googleUser);
    console.log("Axio post done login_with_google");
    //console.log(response);
    if(response.status === 200){
      console.log('response.data[0]:');
      console.log(response.data[0]);
    }
    else{
      console.log("Error! Response of Axio post is not 200, but "+response.status+" "+response.statusText);    
    }
  }
  catch(error){
    console.log('error');
    console.log(error);
    //setError(error.message);    
  };
  return(response.data[0]);
}

export {byAccessToken}

