import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import Axios from "axios";
import RootState from '../types/RootState';
import BoatSearchBarFormType from '../types/BoatSearchBarFormType'
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import 'react-calendar/dist/Calendar.css';
import 'react-calendar/dist/Calendar.css';
import Button from '@mui/material/Button';
import SingleReservationDate from "../components/SingleReservationDate";
import HajoType from "../types/HajoType";
import CustomerDataInputFormType from "../types/CustomerDataInputFormType";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import SoftError from "../components/SoftError";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";

const BlycTheme = createTheme({
  typography: {
    "fontFamily": "'Libre Baskerville', serif",
    "fontSize": 14,
  },
})

function SinglePageConfirmation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testMode: boolean = useSelector((state: RootState) => state.testMode);
  const database: string = useSelector((state: RootState) => state.database);
  const hajo: HajoType = useSelector((state: RootState) => state.selectedHajo);
  const customerDataInputForm: CustomerDataInputFormType = useSelector((state: RootState) => state.customerDataInputForm);
  const boatSearchBarForm: BoatSearchBarFormType = useSelector((state: RootState) => state.boatSearchBarForm);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const postReservation = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    var reqBody = {
      customerDataInputForm: customerDataInputForm,
      boatSearchBarForm: boatSearchBarForm,
    };
    try {
      const response = await Axios.post(window.backendUrl + 'single_page_reservation', reqBody);
      console.log("reservation done");
      console.log("response.data is:");
      console.log(response.data);
      if (response.status === 200) {
        console.log('Quote saved successfully');
        dispatch({ type: 'UpdateCartGuid', newCartGuid: response.data });

        ///case 'UpdateCartGuid': {
        //  console.log('Reducer: UpdateCartGuid');
        //  console.log(action.newCartGuid);
        //  state.boatSearchBarForm = { ...state.boatSearchBarForm, quoteGuid: action.newCartGuid }

        //  break;
      }
      else {
        console.log("Error! Response of Axio post is not 200, but " + response.status + " " + response.statusText);
        throw new Error('Something went wrong');
      }
      //await FinalizeReservation();
    }
    catch (error) {
      setIsError(true);
      console.error(error);

    };
    setIsLoading(false);
  }, []);

  useEffect(() => {
    postReservation();
  }, [])

  const handleCalendar = () => {
    if (testMode) {
      navigate('/');
    }
  }
  const handleCustomer = () => {
    if (testMode) {
      navigate('/spcustomer');
    }
  }

  return (
    <div>
      <ThemeProvider theme={BlycTheme}>
        <CssBaseline />
        {database === 'DEV' &&
          <Alert
            variant='standard'
            severity='warning'
            style={{ width: '100%' }}
            sx={{ p: 0 }}
          >
            <Typography gutterBottom variant="body1">Warning! This is DEV database.
            </Typography>
          </Alert>
        }
        {isError &&
          <SoftError />
        }

        {!isError && isLoading &&
          <Alert
            variant='standard'
            severity='info'
            style={{ width: '100%' }}
            sx={{ p: 0 }}
          >
            <FormattedMessage id='alert.data_fetch_pending' />
          </Alert>
        }
        {!isError && !isLoading &&
          <Alert
            variant='standard'
            severity='success'
            style={{ width: '100%' }}
            sx={{ p: 0 }}
          >
            <FormattedMessage id='alert.boat_reservation_successful' />
          </Alert>
        }
        {!isLoading && hajo &&
          <SingleReservationDate
            key={'1'}
            dateFrom={boatSearchBarForm.dateFrom}
            dateTo={boatSearchBarForm.dateTo} />
        }
        {!isError && !isLoading &&
          <><Card sx={{ p: 0, border: 'none', boxShadow: "none" }}>
            <CardContent sx={{ p: 0 }}>
              <br></br>
              <Typography gutterBottom variant="body2">
                Sikeresen elküldte foglalási kérését!<br></br>
                Foglalási kérését emailben visszaigazoltuk. <br></br>
                Kollégánk hamarosan keresni fogja önt.
              </Typography>
              <Typography gutterBottom variant="body2">
                <FormattedMessage id='input.label.lastname' />
                : {customerDataInputForm.lastname}
                <br></br>
                <FormattedMessage id='input.label.firstname' />
                : {customerDataInputForm.firstname}
                <br></br>
                <FormattedMessage id='input.label.phone' />
                : {customerDataInputForm.phone}
                <br></br>
                <FormattedMessage id='input.label.email' />
                : {customerDataInputForm.email}
                {customerDataInputForm.comment &&
                  <><br></br><FormattedMessage id='customer.Comment' />
                    : {customerDataInputForm.comment}
                  </>}
              </Typography>
            </CardContent>
          </Card>
          </>
        }
        {!isLoading && testMode &&
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Button
              size='small'
              sx={{ textTransform: 'none' }}
              variant="contained"
              onClick={handleCalendar}>
              <FormattedMessage id='button.Calendar' />
            </Button>
            <Button
              size='small'
              sx={{ textTransform: 'none' }}
              variant="contained"
              onClick={handleCustomer} >
              <FormattedMessage id='button.Customer_data' />
            </Button>
            <Button
              size='small'
              sx={{ textTransform: 'none' }}
              variant="contained"
              onClick={postReservation} >
              <FormattedMessage id='button.Reservation' />
            </Button>
          </Grid >
        }
      </ThemeProvider>
    </div >
  )
}

export default SinglePageConfirmation;

