import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RootState from '../types/RootState';
import { Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider, Typography } from "@mui/material";
import { DocumentTemplateType, emptyDocumentTemplate } from "../types/DatabaseTypes";
import parse from 'html-react-parser';

//import { fdTheme, yellowButton } from "../themes/fdTheme";
import { documentTheme } from "../themes/DocumentTheme";

export default function FakeDoorPrivacyPolicyDialog(props: any) {

  const intl = useIntl();
  const documentTemplateList = useSelector((state: RootState) => state.documentTemplateList);
  const language = useSelector((state: RootState) => state.language);

  const handleDialogClose = () => {
    props.setOpenDialog(false); // Use the prop.
  };

  function getTitle(lng: string, docType: string) {
    var item: DocumentTemplateType = emptyDocumentTemplate;
    if (documentTemplateList?.length > 0) {
      item = documentTemplateList.find(x => x.lng === lng && x.doc_type === docType) ?? emptyDocumentTemplate;
    }
    return item?.title ?? lng;
  }

  function getSubtitle(lng: string, docType: string) {
    var item: DocumentTemplateType = emptyDocumentTemplate;
    if (documentTemplateList?.length > 0) {
      item = documentTemplateList.find(x => x.lng === lng && x.doc_type === docType) ?? emptyDocumentTemplate;
    }
    return item?.subtitle ?? lng;
  }

  function getBody(lng: string, docType: string) {
    var item: DocumentTemplateType = emptyDocumentTemplate;
    if (documentTemplateList?.length > 0) {
      item = documentTemplateList.find(x => x.lng === lng && x.doc_type === docType) ?? emptyDocumentTemplate;
    }
    return item?.body;
  }

  return (
    <>

      <ThemeProvider theme={documentTheme}>
        <Dialog
          fullWidth
          open={props.openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle>
            <Typography variant="h1">
              {getTitle(language, 'privacy_policy')}
            </Typography>
            <IconButton
              onClick={handleDialogClose}
              sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="h2">
              {getSubtitle(language, 'privacy_policy')}
            </Typography>
            <Typography variant="body1">
              {parse(getBody(language, 'privacy_policy'))}
            </Typography>
          </DialogContent>
          {true &&
            <DialogActions>
              <Button autoFocus onClick={handleDialogClose}>
                <FormattedMessage id='button.close' />
              </Button>
            </DialogActions>
          }
        </Dialog>
      </ThemeProvider>
    </>
  );
}

